export enum LineBeaconServiceType {
  TENMAX = 'tenmaxSupport',
  SELF_SERVE = 'advertiserSelfServe'
}

export enum LineMessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export type LineMessage = {
  type: LineMessageType.TEXT;
  message: string;
} | {
  type: LineMessageType.IMAGE;
  imageUrl: string;
} | {
  type: LineMessageType.VIDEO;
  videoUrl: string;
  previewImageUrl: string;
};

export type LineMessageFormData = {
  type: LineMessageType.TEXT;
  message: string;
} | {
  type: LineMessageType.IMAGE;
  image: FileFieldData;
} | {
  type: LineMessageType.VIDEO;
  video: FileFieldData;
  previewImage: FileFieldData;
};

type TenMaxLineBeaconConfig = {
  lineBeaconServiceType: LineBeaconServiceType.TENMAX;
  lineBeaconHardwareId: string;
  lineBotChannelSecret: string;
  lineBotChannelAccessToken: string;
};

type SelfServeLineBeaconConfig = OverrideAndNeverOthers<
  TenMaxLineBeaconConfig,
  {
    lineBeaconServiceType: LineBeaconServiceType.SELF_SERVE;
    lineBeaconHardwareId: string;
  }
>;

export type LineBeaconConfig =
  | TenMaxLineBeaconConfig
  | SelfServeLineBeaconConfig;
