import { L1Object, L1ObjectChannel, L1ObjectState } from 'core/l1Object/L1Object';

export class DefaultDraftSetupStateContentModel {
  canGoNext: boolean = false;

  l1ObjectOptionsCache = {};

  constructor (
    public orderOptions: SelectOptions[],
    private l1ObjectChannel: L1ObjectChannel
  ) {}

  getL1ObjectsOfChannel = (l1Objects: L1Object[]) => {
    return l1Objects.filter(l1Object => l1Object.channel === this.l1ObjectChannel && l1Object.state !== L1ObjectState.DELETE);
  }

  setCanGoNext = (canGoNext: boolean) => {
    this.canGoNext = canGoNext;
  }
}
