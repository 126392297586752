import { DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { FbAdAccountStatus } from 'core/fbAdAccount/FbAdAccount';
import { FbBillingEvent, FbPacingType } from 'core/fbAdSet/FbAdSet';
import { DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { FbObjective } from 'core/fbCampaign/FbCampaign';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { BidStrategy } from 'core/l2Object/L2Object';
import { Order } from 'core/order/Order';
import i18n from 'i18n';
import _, { defaultTo } from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import { L1ObjectFormChannelModel } from './L1ObjectFormChannelModel';

export abstract class L1ObjectCreateFormChannelModel implements L1ObjectFormChannelModel {

  constructor (
    protected order: Order,
    protected l1ObjectManager = new DefaultL1ObjectManager()
  ) {}

  abstract get minBudget ();

  abstract init (callback: (data) => void);

  abstract validate (value: Partial<L1Object>);

  abstract getCboChangeHint ();

  abstract initChannelData (valueUpdater: (updateFunc: (prevValue: any) => any) => void);

  abstract onCBOChange (enable: boolean, valueUpdater: (updateFunc: (prevValue: any) => any) => void);

  abstract showRemainBudget (values: Partial<L1Object>);

  abstract toChannelNativeData (l1Object: L1Object, extraData: any): any;

  getDefaultBidStrategy = () => BidStrategy.LOWEST_COST_WITHOUT_CAP;
}

export class DefaultL1ObjectCreateFormChannelModel extends L1ObjectCreateFormChannelModel {

  constructor (
    protected order: Order,
    private useViewableImpressInReport: boolean
  ) {
    super(order);
  }

  get minBudget () {
    return this.order.campaignConstraint.budgetMinimum;
  }

  init = async (callback: (data) => void) => {};

  initChannelData (valueUpdater: (updateFunc: (prevValue: any) => any) => void) {
    valueUpdater(prev => {
      const prevRtb = defaultTo(prev.rtb, {});
      return {
        ...prev,
        fb: undefined,
        objective: L1ObjectObjective.AWARENESS,
        rtb: {
          ...prevRtb,
          bid_strategy: undefined,
          viewable_impress_report: this.useViewableImpressInReport
        }
      };
    });
  }

  validate = (value: Partial<L1Object>) => {
    return {};
  }

  getCboChangeHint = () => '';

  onCBOChange (enable: boolean, valueUpdater: (updateFunc: (prevValue: any) => any) => void) {
    if (enable) {
      valueUpdater(prev => ({
        ...prev,
        budget: 0,
        rtb: {
          ...defaultTo(prev.rtb, {}),
          bid_strategy: this.getDefaultBidStrategy(),
          campaign_budgets: undefined
        }
      }));
    } else {
      valueUpdater(prev => ({
        ...prev,
        budget: 0,
        rtb: {
          ...defaultTo(prev.rtb, {}),
          bid_strategy: undefined,
          campaign_budgets: undefined
        }
      }));
    }
  }

  showRemainBudget (values: Partial<L1Object>) {
    return true;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const order = extraData.order;
    const pmax3Objective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(l1Object.channel, pmax3Objective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      adsOrderId: order.id
    };
  }

  getDefaultBidStrategy = () => BidStrategy.LOWEST_COST_WITHOUT_CAP;
}

export class FBCampaignCreateFormModel extends L1ObjectCreateFormChannelModel {

  fbAdAccountOptions?: SelectOptions[];

  constructor (
    order: Order,
    private currencyRate: number,
    private advertiserManager = new DefaultAdvertiserManager()
  ) {
    super(order);
  }

  get minBudget () {
    const fbAdSetManager = new DefaultFbAdSetManager();
    return fbAdSetManager.getMinBudgetOfFBObject(
      _.defaultTo(this.currencyRate, 1),
      1,
      BidStrategy.LOWEST_COST_WITHOUT_CAP,
      FbBillingEvent.IMPRESSIONS,
      1,
      this.order
    );
  }

  init = async (callback: (data) => void) => {
    if (this.fbAdAccountOptions) {
      return;
    }
    const fbAdAccounts = await this.advertiserManager.getAdvertiserFBAdAccounts(this.order.advertiserId);
    this.fbAdAccountOptions = fbAdAccounts.filter(account => account.status === FbAdAccountStatus.ACTIVE)
                                .map(account => ({
                                  label: account.name,
                                  value: account.fbAdAccountId,
                                  extra: {
                                    hasAdvertiserOptedInOdax: account.hasAdvertiserOptedInOdax
                                  }
                                }));
    callback(this.fbAdAccountOptions);
  }

  initChannelData (valueUpdater: (updateFunc: (prevValue: any) => any) => void) {
    valueUpdater(prev => {
      const prevFb = defaultTo(prev.fb, {});
      return {
        ...prev,
        rtb: undefined,
        budget: 0,
        autoOptimise: false,
        fb: {
          ...prevFb,
          account_id: '',
          bid_strategy: undefined,
          objective: L1ObjectObjective.TRAFFIC
        }
      };
    });
  }

  validate = (value: Partial<L1Object>) => {
    const fbAdAccountOptions = this.fbAdAccountOptions ? this.fbAdAccountOptions : [];
    return {
      fb: _.omitBy({
        account_id: fbAdAccountOptions.length > 0 ?
          validateEmpty(_.get(value, 'fb.account_id')) :
          i18n.t<string>('l1Object.errors.adAccountNotSet')
      }, _.isEmpty)
    };
  }

  getCboChangeHint = () => '';

  onCBOChange (enable: boolean, valueUpdater: (updateFunc: (prevValue: any) => any) => void) {
    if (enable) {
      valueUpdater(prev => ({
        ...prev,
        budget: 0,
        fb: {
          ...defaultTo(prev.fb, {}),
          bid_strategy: this.getDefaultBidStrategy(),
          adset_budgets: undefined
        }
      }));
    } else {
      valueUpdater(prev => ({
        ...prev,
        budget: 0,
        fb: {
          ...defaultTo(prev.fb, {}),
          bid_strategy: undefined
        }
      }));
    }
  }

  showRemainBudget (values: Partial<L1Object>) {
    const isCBO = _.get(values, 'autoOptimise', false);
    return isCBO;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const pmax3Objective = _.get(l1Object.fb, 'objective');
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.FB, pmax3Objective);
    const order = extraData.order;
    // const accountId = _.get(l1Object.fb, 'account_id', '').toString();
    // const fbAdAccountOption = extraData.fbAdAccountOptions.find(fbAdAccount => fbAdAccount.value.toString() === accountId);
    const fb = l1Object.fb ? {
      ...l1Object.fb,
      lifetime_budget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      configured_status : 'ACTIVE',
      effective_status : 'ACTIVE',
      is_skadnetwork_attribution : true,
      buying_type : 'AUCTION',
      special_ad_categories : ['NONE'],
      bid_strategy: l1Object.fb.bid_strategy ? l1Object.fb.bid_strategy : null,
      objective,
      pacing_type: !l1Object.autoOptimise ? [] : [FbPacingType.DAY_PARTING]
    } : undefined;
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      adsOrderId: order.id,
      fb
    };
  }

  turnToFbLegecyObjective = (objecive) => {
    switch (objecive) {
      case FbObjective.OUTCOME_AWARENESS:
        return FbObjective.REACH;
      case FbObjective.OUTCOME_TRAFFIC:
        return FbObjective.LINK_CLICKS;
      case FbObjective.OUTCOME_SALES:
        return FbObjective.CONVERSIONS;
    }
  }
}

export class MessageCampaignGroupCreateFormModel extends L1ObjectCreateFormChannelModel {
  constructor (
    order: Order,
    useViewableImpressInReport: boolean
  ) {
    super(order);
  }

  get minBudget () {
    return this.order.campaignConstraint.budgetMinimum;
  }

  init = async (callback: (data) => void) => {};

  initChannelData (valueUpdater: (updateFunc: (prevValue: any) => any) => void) {
    valueUpdater(prev => {
      const prevMessage = defaultTo(prev.message, {});
      return {
        ...prev,
        fb: undefined,
        objective: L1ObjectObjective.AWARENESS,
        message: {
          ...prevMessage
        }
      };
    });
  }

  validate = (value: Partial<L1Object>) => {
    return {};
  }

  getCboChangeHint = () => '';

  onCBOChange (enable: boolean, valueUpdater: (updateFunc: (prevValue: any) => any) => void) {
    // MESSAGE channel no cbo
  }

  showRemainBudget (values: Partial<L1Object>) {
    return true;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const order = extraData.order;
    const pmax3Objective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.MESSAGE, pmax3Objective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      adsOrderId: order.id
    };
  }
}
