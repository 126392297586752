import { ModalState, ModalStateFactory } from 'containers/Common/ModalStateFactory';
import { L1Object } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { DraftSetupState } from './DraftSetupState';
import { DraftSummaryState } from './DraftSummaryState';

export class DraftCreateModalStateFactory implements ModalStateFactory {

  constructor (
    private order: Order,
    private l1Object: L1Object,
    private l2ObjectsToCreateDraft: {
      id: number | string, isDraft: boolean
    }[],
    private orderManager: OrderManager = new DefaultOrderManager()
  ) {}

  async initStates (goModalStateFn: (state: ModalState) => void, closeModalFn: (dirty) => void) {
    const orderOptions = await this.orderManager.getOrderOptions(this.order.orderType);
    const draftSetupState: DraftSetupState =
      new DraftSetupState(orderOptions, this.l1Object, this.l2ObjectsToCreateDraft);
    const draftSummaryState: DraftSummaryState =
      new DraftSummaryState(this.l1Object, this.l2ObjectsToCreateDraft);
    draftSetupState.next = () => goModalStateFn(draftSummaryState);
    draftSetupState.close = () => closeModalFn(false);
    draftSummaryState.back = () => goModalStateFn(draftSetupState);
    draftSummaryState.close = closeModalFn;
    return draftSetupState;
  }
}
