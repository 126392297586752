import { validateMinMax } from 'utils/ValidateUtils';
import { BasicOutdoorFormModel, OutdoorFormProps } from './OutdoorFormModel';
import { OutdoorForm } from './OutdoorForm';
import i18n from 'i18n';
import { floor, get } from 'lodash';
import moment from 'moment';
import { formatBytes } from 'utils/StringUtil';
import { Creative } from 'core/creative/Creative';

const mimeDB = require('mime-db');

export class PICFormModel extends BasicOutdoorFormModel<PICFormModel> {

  constructor (
    advertiserId: number,
    public minDuration: number,
    public maxDuration: number,
    forPmp: boolean = false
  ) {
    super(advertiserId, forPmp);
    this.validateDuration = this.validateDuration.bind(this);
    this.validateDocument = this.validateDocument.bind(this);
  }

  get adFormat () {
    return 'PIC_OUTDOOR';
  }

  get useMediaDuration () {
    return true;
  }

  get needSelfCheckDocument () {
    return true;
  }

  getFormContent (): (props: OutdoorFormProps<PICFormModel>) => JSX.Element {
    return OutdoorForm<PICFormModel>;
  }

  getInitTypeProperties () {
    return {
      ...super.getInitTypeProperties(),
      orderName: '',
      orderStartDate: moment().startOf('day').format('YYYY-MM-DD'),
      orderEndDate: moment().startOf('day').add(1, 'day').format('YYYY-MM-DD'),
      duration: this.maxDuration
    };
  }

  getFormModelData (creative: Creative) {
    const formModelData = super.getFormModelData(creative);
    const creativeExtra = get(creative, 'basic.bannerExtra', {});
    const orderName = get(creativeExtra, 'orderName', '');
    const orderBudget = get(creativeExtra, 'orderBudget', '');
    const orderStartDate = get(creativeExtra, 'startDate', moment().format('YYYY-MM-DD'));
    const orderEndDate = get(creativeExtra, 'endDate', moment().add(1, 'day').format('YYYY-MM-DD'));
    const selfCheckList = get(creativeExtra, 'selfCheckList');
    return {
      ...formModelData,
      basic: {
        ...formModelData.basic,
        typeProperties: {
          ...formModelData.basic.typeProperties,
          orderName,
          orderBudget,
          orderStartDate,
          orderEndDate
        },
        medias: {
          ...formModelData.basic.medias,
          selfCheckDocument: selfCheckList ? { url: selfCheckList } : {}
        }
      }
    };
  }

  getDefaultDuration = () => {
    return this.maxDuration;
  }

  getVideoHints (width: number, height: number) {
    return [
      ...super.getVideoHints(width, height),
      i18n.t<string>('picFormModel.hints.videoDuration', { min: this.minDuration, max: this.maxDuration })
    ];
  }

  getAudioHints () {
    return [
      ...super.getAudioHints(),
      i18n.t<string>('picFormModel.hints.audioDuration', { min: this.minDuration, max: this.maxDuration })
    ];
  }

  override async getCreativeSizesByAdFormat () {
    const sizes = await super.getCreativeSizesByAdFormat();
    return sizes
      ? sizes.filter(size => size.width !== 1920 || size.height !== 1080)
      : undefined;
  }

  validateVideo (targetWidth: number, targetHeight: number, fileData: MediaFieldData) {
    const errors = super.validateVideo(targetWidth, targetHeight, fileData);
    if (typeof errors === 'string') {
      return errors;
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const error = this.validateDuration(floor(fileData.duration));
    if (error) {
      return i18n.t<string>('picFormModel.hints.videoDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return errors;
  }

  validateAudio (fileData: MediaFieldData) {
    const errors = super.validateAudio(fileData);
    if (typeof errors === 'string') {
      return errors;
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const error = this.validateDuration(floor(fileData.duration));
    if (error) {
      return i18n.t<string>('picFormModel.hints.audioDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return errors;
  }

  validateDuration (value: number): string | undefined {
    return validateMinMax(value, this.minDuration, this.maxDuration);
  }

  validateDocument (fileData: FileFieldData): string | JSX.Element | undefined {
    if (this.isFileFieldEmpty(fileData)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    let errors: string[] = [];
    const file = fileData.file;
    const validTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', {
        type: extensions[0]
      });
    }
    if (file.size > 2097152) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', {
          storage1: formatBytes(file.size),
          storage2: '2MB'
        })
      );
    }
  }
}
