import { ImagePreview } from 'components/common/Image/ImagePreview';
import { VideoPreview } from 'components/common/Video/VideoPreview';

export const renderImageMessage = (image: FileFieldData) => {
  return (
    <ImagePreview
      file={image.file}
      url={image.url}
      config={{
        showName: false,
        showSize: false
      }}
    />
  );
};

export const renderVideoMessage = (video: FileFieldData, previewImage: FileFieldData) => {
  return (
    <>
      <VideoPreview
        maxWidth={300}
        maxHeight={300}
        src={video.file || video.url}
      />
      <ImagePreview
        file={previewImage.file}
        url={previewImage.url}
        config={{
          showName: false,
          showSize: false
        }}
      />
    </>
  );
};
