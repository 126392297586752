import { FormConfig, FormField } from 'components/common/form/FormConfig';
import { useCallback, useMemo } from 'react';
import i18n from 'i18n';
import { FormikCustomRenderProps } from 'components/common/form/field/CustomField';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useFormikContext } from 'formik';
import { OutdoorType } from 'core/creative/Creative';
import styles from './outdoorFields.module.scss';
import { validateEmpty } from 'utils/ValidateUtils';
import { useOutdoorProperties } from './useOutdoorProperties';
import { OutdoorCreativeFields } from 'core/creative/OutdoorCreative';
import { OutdoorFormModel } from '../OutdoorFormModel';

export const useOutdoorBasicFields = <T extends OutdoorFormModel<T>>(
  model: T
): FormField[] => {

  const {
    forPmp,
    creativeSizes,
    getOutdoorTypeOptions,
    getDefaultDuration
  } = model;

  const { values, setValues } = useFormikContext<OutdoorCreativeFields>();
  const isEditing = !!values.creativeId;
  const {
    creativeSize,
    temperatureEnable
  } = useOutdoorProperties(values);

  const outdoorTypeOptions = useMemo(() => {
    return getOutdoorTypeOptions(creativeSize);
  }, [creativeSize, getOutdoorTypeOptions]);

  const onChangeOutdoorType = useCallback(
    (type: OutdoorType) => {
      setValues(prev => {
        const oldDuration = prev.typeProperties.duration;
        const newValues = {
          ...prev,
          typeProperties: {
            ...prev.typeProperties,
            duration: getDefaultDuration(type, oldDuration),
            outdoorType: type
          },
          medias: {}
        } as OutdoorCreativeFields;
        return newValues;
      });
    },
    [setValues, getDefaultDuration]
  );

  const onCreativeSizeChange = useCallback(
    (newCreativeSize: `${number} x ${number}`) => {
      if (newCreativeSize === creativeSize) {
        return;
      }
      const outdoorTypeOptions = getOutdoorTypeOptions(newCreativeSize);
      const newOutdoorType =
        outdoorTypeOptions.length > 0 ? outdoorTypeOptions[0].value as OutdoorType : OutdoorType.VIDEO;
      onChangeOutdoorType(newOutdoorType);
    },
    [creativeSize, onChangeOutdoorType, getOutdoorTypeOptions]
  );

  const renderTemperatureRange = useCallback(
    (props: FormikCustomRenderProps) => {
      const filedName = props.name!;
      const fieldProps = props.getFieldProps(filedName);
      const onChange = (values: number[]) => {
        props.setFieldValue(filedName, values);
        props.setFieldTouched(filedName);
      };
      const min = 0;
      const max = 40;
      const marks = { [min]: `${min}°C`, [max]: `${max}°C` };
      if (fieldProps.value) {
        fieldProps.value.forEach(temp => {
          marks[temp] = `${temp}°C`;
        });
      }
      return (
        <div style={{ width: 400, margin: 50 }}>
          <Range
            trackStyle={[{ backgroundColor: '#5845a5' }]}
            handleStyle={[
              { borderColor: '#5845a5' },
              { borderColor: '#5845a5' }
            ]}
            min={min}
            max={max}
            step={0.5}
            allowCross={false}
            defaultValue={fieldProps.value}
            marks={marks}
            onChange={onChange}
          />
        </div>
      );
    },
    []
  );

  const fields = useMemo(() => {
    const fieldBuilder = new FormConfig.FieldsBuilder();
    if (!forPmp) {
      fieldBuilder.addFormikSwitch({
        label: i18n.t<string>('creativeSetupFlow.labels.temperatureRange'),
        name: 'typeProperties.temperatureEnable',
        hoverHint: i18n.t<string>('outdoorForm.labels.temperatureHint'),
        className: styles.temperatureSwitch
      });
    }

    if (temperatureEnable) {
      fieldBuilder.addFormikCustom({
        label: '',
        name: 'typeProperties.temperatureRange',
        render: renderTemperatureRange
      });
    }

    if (isEditing) {
      if (creativeSizes) {
        fieldBuilder.addFormikLabel({
          label: i18n.t<string>('creativeSetupFlow.labels.creativeSize'),
          name: 'typeProperties.creativeSize'
        });
      }
      fieldBuilder
        .addFormikLabel({
          label: i18n.t<string>('creativeSetupFlow.labels.outdoorType'),
          name: 'typeProperties.outdoorType',
          formatter: value =>
            i18n.t<string>(`outdoorForm.labels.outdoorType${value}`)
        });
    } else {
      if (creativeSizes) {
        fieldBuilder
          .addFormikSelect({
            label: i18n.t<string>('creativeSetupFlow.labels.creativeSize'),
            name: 'typeProperties.creativeSize',
            simpleValue: true,
            options: creativeSizes,
            onChange: onCreativeSizeChange,
            validate: validateEmpty
          });
      }
      fieldBuilder.addFormikSelect({
        label: i18n.t<string>('creativeSetupFlow.labels.outdoorType'),
        name: 'typeProperties.outdoorType',
        simpleValue: true,
        options: outdoorTypeOptions,
        onChange: onChangeOutdoorType,
        validate: validateEmpty
      });
    }

    return fieldBuilder.build();
  }, [
    forPmp,
    creativeSizes,
    isEditing,
    outdoorTypeOptions,
    temperatureEnable,
    renderTemperatureRange,
    onChangeOutdoorType,
    onCreativeSizeChange
  ]);

  return fields;
};
