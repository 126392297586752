import { AdLogo } from 'core/adLogo/AdLogo';
import { LineMessageFormData } from 'core/advertiser/LineBeacon';
import { Creative, CreativeType } from 'core/creative/Creative';
import { LimitationData } from 'core/limitation/Limitation';
import { ElementType } from 'react';

export type CreativeFormBasicData = {
  creativeId?: number,
  tenmaxCategory: string,
  enableNativeBanner?: boolean,
  nativeBanner?: {[key: string]: any},
  creativeType: CreativeType,
  name?: string,
  landingPageUrl?: string,
  bannerUrl?: string,
  advertiserId?: number,
  typeProperties?: any,
  medias?: any,
  enableStartTime?: string,
  enableEndTime?: string,
  adLogo?: AdLogo,
  lineMessage?: LineMessageFormData[]
};

export type CreativeFormData = {
  basic: CreativeFormBasicData;
  limitations?: any;
};

export interface FormContentModel {
  readonly needBannerUrl: boolean;
  readonly defaultAdLogo?: AdLogo;
  init: () => Promise<void>;
  isAdLogoSupported: (creative: CreativeFormBasicData) => boolean;
  getFormContent: () => any;
  getInitTypeProperties: () => any;
  getInitLimitations: () => {
    include: any[],
    exclude: any[],
    nonPreferred: any[],
    other: any[],
    preferred: any[]
  };
  getFormModelData: (creative: Creative) => CreativeFormData;
  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void;
}

export abstract class AbstractFormContentModel implements FormContentModel {

  public needBannerUrl: boolean = true;

  abstract getFormContent (): ElementType;
  abstract getInitTypeProperties (): {[key: string]: any} | undefined;
  abstract getFormModelData (creative: Creative): CreativeFormData;
  abstract setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void;

  async init () {}

  public get defaultAdLogo (): AdLogo | undefined {
    return undefined;
  }

  isAdLogoSupported (_1): boolean {
    return !!this.defaultAdLogo;
  }

  getInitLimitations (): LimitationData {
    return {
      include: [],
      exclude: [],
      nonPreferred: [],
      other: [],
      preferred: []
    };
  }
}
