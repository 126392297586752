import { FormContent } from 'components/common/form/Form';
import { useBeaconConnectionFormConfig } from './BeaconConnectionFormModel';

export const BeaconConnectionFormContent = () => {

  const formConfig = useBeaconConnectionFormConfig();
  return (
    <FormContent formConfig={formConfig}/>
  );
};
