import { Page } from 'components/Page/Page';
import i18n from 'i18n';
import { Nav } from 'react-bootstrap';
import { ChannelGroupTabs } from '../ChannelGroupForm/ChannelGroupFormModel';
import { useChannelGroupDetailModel } from './ChannelGroupDetailModel';
import styles from './channelGroupDetail.module.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useCallback, useMemo } from 'react';
import { defaultTo, get } from 'lodash';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import { Currency } from 'core';
import { ChannelGroupBidPriceSettingInfo } from 'components/BidPrice/ChannelGroupBidPriceSettingInfo';
import { CustomField } from 'components/common/form/field/CustomField';
import { Link } from 'react-router-dom';
import { ChannelGroupMembers } from './ChannelGroupMembers';

export const ChannelGroupDetail: React.FC = () => {
  const {
    loading,
    tab,
    channelGroup,
    breadcrumbsRoutes,
    agencyOptions,
    onTabChange
  } = useChannelGroupDetailModel();

  const infoMarginTop = useMemo(() => ({ marginTop: '30px' }), []);

  const basicInfo = useMemo(
    () => [
      {
        label: i18n.t<string>('channelGroup.labels.name'),
        value: defaultTo(channelGroup?.name, '')
      },
      {
        label: i18n.t<string>('channelGroup.labels.publisherIds'),
        value: defaultTo(channelGroup?.publisherIds, []).join(', ')
      }
    ],
    [channelGroup]
  );

  const specialSettingsInfo = useMemo(
    () => [
      {
        label: i18n.t<string>('channelGroupForm.labels.outdoorAdImpresMultiply'),
        value: get(channelGroup, 'property.addonProps.outdoorAdImpresMultiply', 1)
      }
    ],
    [channelGroup]
  );

  const reportInfo = useMemo(() => {
    const settings = get(channelGroup, 'addonFeatures.report', {});
    return Object.keys(settings).filter(key => settings[key]).map(key => {
      return {
        label: i18n.t<string>(`channelGroupForm.labels.${key}`),
        value: undefined
      };
    });
  }, [channelGroup]);

  const derecognitionAgencyInfo = useMemo(() => {
    const agencyIds = get(channelGroup, 'property.addonProps.derecognitionAgencyIds', []);
    const agencyNames = agencyIds.map(id => {
      const option = agencyOptions.find(option => option.value === id);
      return option ? option.label : id;
    });
    return [
      {
        label: '',
        value: agencyNames.join('\n')
      }
    ];
  }, [channelGroup, agencyOptions]);

  const renderBidPriceSettings = useCallback(() => {
    const bidPriceSettings = get(channelGroup, 'property.addonProps.campaignBidPrice', []).map(bidPriceSetting => {
      return (
        <ChannelGroupBidPriceSettingInfo
          key={bidPriceSetting.type}
          label=''
          bidPriceSetting={bidPriceSetting}
          currency={Currency.NTD}
        />
      );
    });
    return (
      <CustomField label='' name='bidPriceSettings'>
        <div style={infoMarginTop}>{bidPriceSettings}</div>
      </CustomField>
    );
  }, [channelGroup, infoMarginTop]);

  if (!channelGroup) {
    return <LoadingIndicator />;
  }

  return (
    <div style={{ height: '100%' }}>
      {loading && <LoadingIndicator />}
      <Page
        title={i18n.t<string>('channelGroups.title')}
        breadcrumbsRoutes={breadcrumbsRoutes}
        topAreaEndWithShadow={false}
        spaceBetweenTopAreaAndContent={false}
      >
        <div className={styles.channelGroupDetail}>
          <div className={styles.topArea}>
            <div className={styles.channelGroupName}>
              {i18n.t<string>('channelGroupDetail.labels.name', { name: channelGroup.name })}
            </div>
            <div className={styles.buttonArea}>
              <Link to={`${channelGroup.id}/edit`}>{i18n.t<string>('channelGroups.titles.edit')}</Link>
            </div>
          </div>
          <Nav activeKey={tab} onSelect={onTabChange}>
            <Nav.Item>
              <Nav.Link eventKey={ChannelGroupTabs.BASIC}>
                {i18n.t<string>('channelGroups.titles.basicTab')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={ChannelGroupTabs.REPORT}>
                {i18n.t<string>('channelGroups.titles.reportTab')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={ChannelGroupTabs.BID_PRICE}>
                {i18n.t<string>('channelGroups.titles.bidPriceTab')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={ChannelGroupTabs.SPECIAL_SETTINGS}>
                {i18n.t<string>('channelGroups.titles.specialSettingsTab')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {tab === ChannelGroupTabs.BASIC && (
            <div style={infoMarginTop}>
              <InfoDisplaySection
                data={basicInfo}
                title={i18n.t<string>(
                  'channelGroups.titles.basicContent'
                )}
              />
            </div>
          )}
          {tab === ChannelGroupTabs.SPECIAL_SETTINGS && (
            <div style={infoMarginTop}>
              <InfoDisplaySection
                data={specialSettingsInfo}
                title={i18n.t<string>(
                  'channelGroups.titles.specialSettingsContent'
                )}
              />
            </div>
          )}
          {tab === ChannelGroupTabs.REPORT && (
            <div style={infoMarginTop}>
              <InfoDisplaySection
                data={reportInfo}
                title={i18n.t<string>(
                  'channelGroups.titles.reportContent'
                )}
              />
              <InfoDisplaySection
                data={derecognitionAgencyInfo}
                title={i18n.t<string>(
                  'channelGroups.titles.derecognitionAgencyContent'
                )}
              />
            </div>
          )}
          {tab === ChannelGroupTabs.BID_PRICE && renderBidPriceSettings()}
        </div>
        <div className={styles.memberList}>
          <ChannelGroupMembers channelGroupId={channelGroup.id} />
        </div>
      </Page>
    </div>
  );
};
