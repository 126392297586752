import { validateMinMax } from 'utils/ValidateUtils';
import { BasicOutdoorFormModel, OutdoorFormProps } from './OutdoorFormModel';
import { OutdoorForm } from './OutdoorForm';
import i18n from 'i18n';
import { floor } from 'lodash';
import moment from 'moment';

export class PoyaFormModel extends BasicOutdoorFormModel<PoyaFormModel> {

  constructor (
    advertiserId: number,
    public minDuration: number,
    public maxDuration: number
  ) {
    super(advertiserId, false);
    this.validateDuration = this.validateDuration.bind(this);
  }

  override get adFormat () {
    return 'POYA_OUTDOOR';
  }

  override get useMediaDuration () {
    return true;
  }

  override get needSelfCheckDocument () {
    return false;
  }

  override async getCreativeSizesByAdFormat () {
    return [{
      width: 1920,
      height: 1080
    }];
  }

  override getFormContent (): (props: OutdoorFormProps<PoyaFormModel>) => JSX.Element {
    return OutdoorForm<PoyaFormModel>;
  }

  override getInitTypeProperties () {
    return {
      ...super.getInitTypeProperties(),
      orderName: '',
      orderStartDate: moment().startOf('day').format('YYYY-MM-DD'),
      orderEndDate: moment().startOf('day').add(1, 'day').format('YYYY-MM-DD'),
      duration: this.maxDuration
    };
  }

  override getDefaultDuration = () => {
    return this.maxDuration;
  }

  override getVideoHints (width: number, height: number) {
    return [
      ...super.getVideoHints(width, height),
      i18n.t<string>('picFormModel.hints.videoDuration', { min: this.minDuration, max: this.maxDuration })
    ];
  }

  override getAudioHints () {
    return [
      ...super.getAudioHints(),
      i18n.t<string>('picFormModel.hints.audioDuration', { min: this.minDuration, max: this.maxDuration })
    ];
  }

  override validateVideo (targetWidth: number, targetHeight: number, fileData: MediaFieldData) {
    const errors = super.validateVideo(targetWidth, targetHeight, fileData);
    if (typeof errors === 'string') {
      return errors;
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const error = this.validateDuration(floor(fileData.duration));
    if (error) {
      return i18n.t<string>('picFormModel.hints.videoDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return errors;
  }

  override validateAudio (fileData: MediaFieldData) {
    const errors = super.validateAudio(fileData);
    if (typeof errors === 'string') {
      return errors;
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const error = this.validateDuration(floor(fileData.duration));
    if (error) {
      return i18n.t<string>('picFormModel.hints.audioDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return errors;
  }

  validateDuration (value: number): string | undefined {
    return validateMinMax(value, this.minDuration, this.maxDuration);
  }
}
