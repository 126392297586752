import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { CampaignState, AdType, DeliverType, RtbCampaignListBasic } from 'core/rtbCampaign/RtbCampaign';
import { ColumnDefinition } from 'components/TableColumn/TableColumn';
import { Order, State } from 'core/order/Order';
import { formatPrice, formatPriceAny, formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { toast } from 'react-toastify';
import {
  CampaignListColumns,
  performanceColumns,
  performanceColumnsWithViewable,
  videoPerformanceColumns,
  videoPerformanceColumnsWithViewable,
  basicColumns
} from './CampaignListColumnSetting';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { numberWithCommas } from 'utils/StringUtil';
import styles from './campaignList.module.scss';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { FilterMenuTabConfig } from 'components/common/FilterMenuTab/FilterMenuTab';
import { L1ObjectManager, DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { CampaignAuditLogModel } from 'containers/AuditLog/CampaignAuditLogModel';
import { Pmp, PmpStatus } from 'core/pmp/Pmp';
import { getCampaignProgressRate, getDailyBudgetCampaignProgressRate, isAdTypeSupportOutdoorDevice } from 'utils/CampaignUtil';

const timeFormat = 'YYYY-MM-DD HH:mm:ss';
export interface CampaignListModel extends SortableList {
  readonly order: Order;
  readonly l1Object: L1Object;
  readonly campaignList: RtbCampaignListBasic[];
  readonly state: CampaignListState;
  readonly event: UpdateEventListener<CampaignListModel>;
  readonly campaignTags: string[];
  readonly campaignStates: string[];
  readonly objectTypes: string[];
  readonly canCreateCampaign: boolean;
  readonly canSplitCampaign: boolean;
  readonly showSplitBtn: boolean;
  readonly searchString: string;
  readonly lang: string;
  readonly canNotCreateMessage: string;
  readonly newCampaignPath: string;
  readonly editCampaignPath: string;
  readonly copyCampaignPath: string;
  readonly filterMenuTabConfigs: Array<FilterMenuTabConfig>;
  isPmp (campaign): boolean;
  onCampaignChange: () => void;
  handleOnSelect (campaign): void;
  handleOnSelectAll (): void;
  handleRemoveSelect (): void;
  getColumnDefinition (columnName): ColumnDefinition;
  showTable (listType, e): void;
  handleOnSearch (campaignName): void;
  handleOnTagFilterApply (tags): void;
  handleOnStateFilterApply (stateDesList): void;
  handleOnObjectTypeFilterApply (objectTypeList): void;
  activeCampaign (event): Promise<void>;
  deactiveCampaign (event): Promise<void>;
  allSelectedCampaignSameType (): boolean;
  allSelectedCampaignSameRetail (): boolean;
  selectUnknowAdTypeCampaign (): boolean;
  delete (): Promise<void>;
  updateBidWeight (bidWeight: number): Promise<void>;
  canDeleteSelectedCampaigns (campaignIds: Array<number>): boolean;
  setCampaignsToDelete (campaignIds: number[]): void;
  setDraftsToDelete (draftIds: number[]): void;
  setCampaignToChangeBidWeight (campaignId?: number | string): void;
  getReportLink (campaign): string;
  updateModelData (order: Order, campaignList: RtbCampaignListBasic[]): void;
  updateViewModelData (): void;
  onSearchChange (searchString): void;
  getTabs (): SelectOptions[];
  setVisibilityOfDraftCreateModal (show: boolean): void;
  getCampaignStatusDesData (campaign: any): {
    des: string;
    color: string;
  };
  getCampaignEffectiveStatusDesData (campaign: any): {
    des: string;
    color: string;
    extraInfo?: any;
  };

  isCampaignBudgetRemain (campaign): boolean;
  getRequiredMinimumBudgetsHint (): string;
  getAuditLogModel (): CampaignAuditLogModel | undefined;
}

export type CampaignListProps = {
  readonly model: CampaignListModel;
};

export enum CampaignListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  VIDEO_PERFORMANCE = 'videoPerformance',
  AUDIT_LOG = 'auditLog'
}

export type CampaignListState = {
  readonly loading: boolean;
  readonly selectedCampaign: number[];
  readonly selectedDraft: number[];
  readonly columnsToShow: CampaignListColumns[];
  readonly seletedTab: string;
  readonly viewModelData: any[];
  readonly summaryData: any;
  readonly selectedTagFilter: string[];
  readonly selectedStateFilter: string[];
  readonly selectedEffectiveStatusFilter: string[];
  readonly selectedObjectTypeFilter: string[];
  readonly showDraftCreateModal: boolean;
  readonly deleteCampaignIds: number[];
  readonly deleteDraftIds: number[];
  readonly campaignToChangeBidWeight?: number | string;
  readonly needUpdateViewModelData: boolean;
};

export class DefaultCampaignListModel extends AbstractSortableList implements CampaignListModel {

  event: FireableUpdateEventListener<CampaignListModel> = new FireableUpdateEventListener<CampaignListModel>();
  selectedCampaign: number[] = [];
  selectedDraft: number[] = [];
  filteredViewModelData: any[] = [];
  columnsToShow: Array<CampaignListColumns>;
  seletedTab: string = CampaignListType.BASIC;
  campaignTags: string[] = [];
  campaignStates: string[] = [];
  campaignEffectiveStatus: string[] = [];
  objectTypes: string[] = [];
  searchString: string;
  selectedTagFilter: string[] = [];
  selectedStateFilter: string[] = [];
  selectedEffectiveStatusFilter: string[] = [];
  selectedObjectTypeFilter: string[] = [];
  loading: boolean = false;
  showDraftCreateModal: boolean = false;
  campaignToChangeBidWeight?: number | string;
  lang: string = i18n.language;
  modelDeleteCampaignIds: number[] = [];
  modelDeleteDraftIds: number[] = [];
  needUpdateViewModelData: boolean = false;
  summaryData: any = {};
  isChannelAllowed: boolean;
  auditLogModel?: CampaignAuditLogModel;

  constructor (
    public order: Order,
    public l1Object: L1Object,
    public campaignList: RtbCampaignListBasic[],
    protected addonFeatureManager: AddonFeatureManager,
    public onCampaignChange,
    public onSearchChange: (search: string) => void,
    defaultSearchString: string | null,
    private budgetBalance: number,
    private localeMeta?: LocaleMeta,
    public manager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    public rtbCampaignDraftManager: DraftManager = new RtbCampaignDraftManager(),
    l1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager()
  ) {
    super('id', 'desc');
    this.columnsToShow = [...basicColumns];
    if (this.hideBudget) {
      this.columnsToShow = this.columnsToShow.filter(column => column !== CampaignListColumns.PROGRESS);
    }
    if (this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA) {
      this.columnsToShow.splice(1, 0, CampaignListColumns.RETAIL);
    }
    this.searchString = defaultSearchString ? defaultSearchString : '';
    this.isChannelAllowed = l1Object ?
      l1ObjectManager.isChannelAllowed(l1Object.channel, addonFeatureManager) :
      true;
    this.updateViewModelData();
  }

  get canNotCreateMessage (): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    }

    if (!this.l1Object.autoOptimise && this.budgetBalance <= 0) {
      return i18n.t<string>('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
    }

    return '';
  }

  isPmp (campaign) {
    return _.get(campaign, 'additionalInfo.pmp') !== undefined;
  }

  getCanNotCopyMessage (campaign: any): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    }
    const campaignIsStart = moment().isAfter(campaign.startDate);
    const campaignIsEnd = moment().isAfter(campaign.endDate);
    let startDate = moment(campaign.startDate);
    if (campaignIsStart) {
      startDate = moment().startOf('day');
    }
    let endDate = moment(campaign.endDate);
    if (campaignIsEnd) {
      endDate = moment(this.order.endDate).endOf('day');
    }

    const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    const minBudgetOfNewCampaign = this.l1Object.autoOptimise ? minCampaignBudgetPerDay * scheduleDateCount : this.order.campaignConstraint.campaignBudgetMinimum;
    if (this.budgetBalance < minBudgetOfNewCampaign) {
      return i18n.t<string>('campaignList.labels.canCopyNeedMore', {
        parent: this.l1Object && !_.isNil(this.l1Object.budget) ? 'Campaign group' : 'Order',
        budget: formatPriceWithCurrency(this.order.currency, minBudgetOfNewCampaign - this.budgetBalance)
      });
    }

    return '';
  }

  getCanNotSplitMessage (campaign: any): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    }

    const targetBudget = campaign.budget;
    const expectSpent = campaign.spents * 1.1;
    const startDate = moment(campaign.startDate);
    const today = moment().startOf('day');
    const isStart = today.isAfter(startDate);
    let minBudgetFor2Campaign = 0;
    if (this.l1Object.autoOptimise) {
      const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
      const endDate = moment(campaign.endDate);
      const scheduleDateCount = endDate.diff(isStart ? today : startDate, 'days') + 1;
      const minBudgetFromNow = minCampaignBudgetPerDay * scheduleDateCount;
      const firstCampaignBudget = isStart ?
        Math.max(expectSpent, minCampaignBudgetPerDay * today.diff(startDate, 'days')) + minBudgetFromNow :
        minBudgetFromNow;
      const secondCampaignBudget = minBudgetFromNow;
      minBudgetFor2Campaign = firstCampaignBudget + secondCampaignBudget;
    } else {
      const minCampaignBudget = this.order.campaignConstraint.campaignBudgetMinimum;
      const firstCampaignBudget = isStart
        ? Math.max(expectSpent, minCampaignBudget)
        : minCampaignBudget;
      const secondCampaignBudget = minCampaignBudget;
      minBudgetFor2Campaign = firstCampaignBudget + secondCampaignBudget;
    }
    const isBelowMinBudget = targetBudget < minBudgetFor2Campaign;
    if (isBelowMinBudget) {
      return i18n.t<string>('campaignList.labels.canSplitMinimum', { budget: formatPriceWithCurrency(this.order.currency, minBudgetFor2Campaign) });
    }

    return '';
  }

  get deleteCampaignIds (): Array<number> {
    return this.modelDeleteCampaignIds;
  }

  set deleteCampaignIds (campaignIds) {
    this.modelDeleteCampaignIds = campaignIds;
  }

  get deleteDraftIds (): Array<number> {
    return this.modelDeleteDraftIds;
  }

  set deleteDraftIds (draftIds) {
    this.modelDeleteDraftIds = draftIds;
  }

  get state (): CampaignListState {
    return {
      selectedCampaign: this.selectedCampaign,
      selectedDraft: this.selectedDraft,
      columnsToShow: this.columnsToShow,
      seletedTab: this.seletedTab,
      needUpdateViewModelData: this.needUpdateViewModelData,
      viewModelData: this.filteredViewModelData,
      summaryData: this.summaryData,
      selectedTagFilter: this.selectedTagFilter,
      selectedStateFilter: this.selectedStateFilter,
      selectedEffectiveStatusFilter: this.selectedEffectiveStatusFilter,
      selectedObjectTypeFilter: this.selectedObjectTypeFilter,
      loading: this.loading,
      showDraftCreateModal: this.showDraftCreateModal,
      deleteCampaignIds: this.deleteCampaignIds,
      deleteDraftIds: this.deleteDraftIds,
      campaignToChangeBidWeight: this.campaignToChangeBidWeight
    };
  }

  get canCreateCampaign (): boolean {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    const noBudget = !this.l1Object.autoOptimise && this.budgetBalance <= 0;
    return !(canNotCreateState.includes(state) || isEnd || noBudget) && this.isChannelAllowed;
  }

  get canSplitCampaign (): boolean {
    const canNotSplitState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    return !(canNotSplitState.includes(state) || isEnd) && this.isChannelAllowed;
  }

  get showSplitBtn (): boolean {
    return this.l1Object && this.l1Object.autoOptimise ? false : true;
  }

  get newCampaignPath () {
    return 'new';
  }

  get editCampaignPath () {
    return 'edit';
  }

  get copyCampaignPath () {
    return 'copy';
  }

  get filterMenuTabConfigs (): FilterMenuTabConfig[] {
    return [
      {
        filterType: i18n.t<string>('l2ObjectList.labels.statusFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.statusFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.statusTag'),
        selectedValues: this.state.selectedStateFilter,
        options: this.campaignStates,
        applyMethod: this.handleOnStateFilterApply
      },
      {
        filterType: i18n.t<string>('l2ObjectList.labels.deliveryFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.deliveryFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.deliveryTag'),
        selectedValues: this.state.selectedEffectiveStatusFilter,
        options: this.campaignEffectiveStatus,
        applyMethod: this.handleOnEffectiveStatusFilterApply
      },
      {
        filterType: i18n.t<string>('campaignList.labels.tagFilter'),
        menuTitle: i18n.t<string>('campaignList.labels.tagFilterMenuTitle'),
        tag: i18n.t<string>('campaignList.labels.tagTag'),
        selectedValues: this.state.selectedTagFilter,
        options: this.campaignTags,
        applyMethod: this.handleOnTagFilterApply
      },
      {
        filterType: i18n.t<string>('l2ObjectList.labels.objectTypeFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.objectTypeFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.objectTypeTag'),
        selectedValues: this.state.selectedObjectTypeFilter,
        options: this.objectTypes,
        applyMethod: this.handleOnObjectTypeFilterApply
      }
    ];
  }

  get hideBudget () {
    return this.l1Object && this.l1Object.autoOptimise;
  }

  getTabs () {
    return Object.values(CampaignListType).map(tab => ({
      label: i18n.t<string>(`campaignList.tabs.${tab}`),
      value: tab
    }));
  }

  sortComparator = (field: string | undefined, dataA, dataB, order) => {
    const dataAToCompare = field ? dataA[field] : dataA;
    const dataBToCompare = field ? dataB[field] : dataB;
    if (order === 'desc') {
      return dataAToCompare < dataBToCompare ? 1 : -1;
    }

    return dataAToCompare > dataBToCompare ? 1 : -1;
  }

  getSortFunc = (dataField?: string) => {
    return _.partial(this.sortComparator, dataField);
  }

  get nameColumnAdditional () {
    return {
      events: {
        onClick: () => {
          // This is intentional
        }
      },
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const dataAIsDraft = _.get(dataA, 'listId', 0).toString().includes('draft');
        const dataBIsDraft = _.get(dataB, 'listId', 0).toString().includes('draft');
        const sameObjectType = dataAIsDraft === dataBIsDraft;
        const compareListId = _.get(dataA, 'listId', 0).toString().localeCompare(_.get(dataB, 'listId', 0).toString());
        if (!sameObjectType) {
          return dataAIsDraft ? -1 : 1;
        }
        if (compareListId === 0) {
          return 0;
        }
        if (order === 'desc') {
          return compareListId > 0 ? -1 : 1;
        } else {
          return compareListId < 0 ? -1 : 1;
        }
      }
    };
  }

  get stateColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.stoppingState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(dataA['des']);
        const dataBToCompare = stateDes.indexOf(dataB['des']);
        if (order === 'desc') {
          return dataAToCompare > dataBToCompare ? 1 : -1;
        } else {
          return dataAToCompare < dataBToCompare ? 1 : -1;
        }
      }
    };
  }

  get deliveryColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.budgetRemainState'),
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.stoppingState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.noCreativesState'),
          i18n.t<string>('campaignList.labels.notStartState'),
          i18n.t<string>('campaignList.labels.endState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(dataA['des']);
        const dataBToCompare = stateDes.indexOf(dataB['des']);
        if (order === 'desc') {
          return dataAToCompare > dataBToCompare ? 1 : -1;
        } else {
          return dataAToCompare < dataBToCompare ? 1 : -1;
        }
      }
    };
  }

  get scheduleColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        if (!dataA || !dataB) {
          return !dataA ? -1 : 1;
        }

        if (order === 'desc') {
          return moment(dataA.start).isBefore(moment(dataB.start)) ? 1 : -1;
        }

        return moment(dataB.start).isBefore(moment(dataA.start)) ? 1 : -1;
      }
    };
  }

  get resultColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: this.getSortFunc('value'),
      formatExtraData: {
        objective: _.get(this.l1Object, 'objective')
      }
    };
  }

  getCommonColumnAdditional (columnName?: string) {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: this.getSortFunc(columnName)
    };
  }

  getColumnDefinition (columnName): ColumnDefinition {
    let additional = {};

    let customLabel;
    switch (columnName) {
      case CampaignListColumns.NAME:
        additional = this.nameColumnAdditional;
        break;
      case CampaignListColumns.STATE:
        additional = this.stateColumnAdditional;
        break;
      case CampaignListColumns.DELIVERY:
        additional = this.deliveryColumnAdditional;
        break;
      case CampaignListColumns.CREATIVE:
        additional = this.getCommonColumnAdditional();
        break;
      case CampaignListColumns.SCHEDULE:
        additional = this.scheduleColumnAdditional;
        break;
      case CampaignListColumns.PROGRESS:
        additional = this.getCommonColumnAdditional('executeRate');
        break;
      case CampaignListColumns.BUDGET:
        customLabel = this.hideBudget ? i18n.t<string>('campaignList.headers.budgetDistributionColumn') : i18n.t<string>('campaignList.headers.budgetColumn');
        additional = this.getCommonColumnAdditional('budget');
        break;
      case CampaignListColumns.EDITBTNS:
        additional = {
          text: ''
        };
        break;
      case CampaignListColumns.RESULTS:
        additional = this.resultColumnAdditional;
        break;
      case CampaignListColumns.SPENT:
        customLabel = i18n.t<string>(`campaignList.headers.${CampaignListColumns.SPENT}`) + ` (${this.order.currency})`;
        additional = {
          sort: true,
          onSort: this.handleSort
        };
        break;
      case CampaignListColumns.CPC:
        customLabel = i18n.t<string>(`campaignList.headers.${CampaignListColumns.CPC}`) + ` (${this.order.currency})`;
        additional = this.getCommonColumnAdditional('value');
        break;
      case CampaignListColumns.CPA:
        customLabel = i18n.t<string>(`campaignList.headers.${CampaignListColumns.CPA}`) + ` (${this.order.currency})`;
        additional = this.getCommonColumnAdditional('value');
        break;
      case CampaignListColumns.RETAIL:
        additional = {
          sort: true,
          onSort: this.handleSort
        };
        break;
      case CampaignListColumns.IMPRES:
      case CampaignListColumns.VIEWABLE:
      case CampaignListColumns.CLICKS:
      case CampaignListColumns.CTR:
      case CampaignListColumns.VCTR:
      case CampaignListColumns.CONVERT:
      case CampaignListColumns.CVR:
      case CampaignListColumns.VIEW:
      case CampaignListColumns.VIEWRATE:
      case CampaignListColumns.UUCOUNT:
      case CampaignListColumns.VIEWABLE_VIEWRATE:
        additional = this.getCommonColumnAdditional('value');
        break;
      default:
        break;
    }

    return this.columnDefinition(columnName, customLabel, additional);
  }

  columnDefinition (columnName, customLabel, additional = {}): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: false,
      text: customLabel ? customLabel : `campaignList.headers.${columnName}`,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      ...additional
    };
  }

  getReportLink (campaign) {
    const from = encodeURIComponent(moment(campaign.startDate).startOf('day').format(timeFormat));
    const to = encodeURIComponent(moment(campaign.endDate).endOf('day').format(timeFormat));
    const dimension = 'l2ObjectId';
    return `/reports/performance?dimension=${dimension}&from=${from}&to=${to}&${dimension}=${campaign.l2ObjectId}`;
  }

  getCampaignStatusDesData = (campaign) => {
    let des = '';
    let color;
    switch (campaign.state) {
      case CampaignState.DEACTIVATE:
        des = i18n.t<string>('campaignList.labels.deactivateState');
        color = 'black';
        break;
      case CampaignState.DELETE:
        des = i18n.t<string>('campaignList.labels.deleteState');
        color = 'danger';
        break;
      case CampaignState.ACTIVATE:
        des = i18n.t<string>('campaignList.labels.activateState');
        break;
      default:
        des = i18n.t<string>('campaignList.labels.stoppingState');
        color = 'whiteTheme4';
        break;
    }

    return {
      des,
      color
    };
  }

  getCampaignEffectiveStatusDesData = (campaign) => {
    let des = _.startCase(_.lowerCase(campaign.effectiveStatus));
    let color = getEffectiveStatusDefaultColor(campaign.effectiveStatus);
    let extraInfo;
    const now = moment();
    const pmp: Pmp = _.get(campaign, 'additionalInfo.pmp');
    const pmpIsEnd = pmp && moment(pmp.endTime).isBefore(now);
    const pmpIsInactive = pmp && pmp.status === PmpStatus.INACTIVE;
    const pmpIsExpired = pmp && pmp.status === PmpStatus.EXPIRED;
    switch (campaign.effectiveStatus) {
      case 'PAUSED':
      case 'CAMPAIGN_GROUP_PAUSED':
        if (campaign.budget - campaign.spents > 0) {
          des = i18n.t<string>('campaignList.labels.budgetRemainState');
          color = 'danger';
        } else {
          des = i18n.t<string>('campaignList.labels.deactivateState');
          color = 'black';
        }
        if (campaign.effectiveStatus === 'CAMPAIGN_GROUP_PAUSED') {
          extraInfo = 'Campaign Group Paused';
        }
        if (pmpIsInactive) {
          des = i18n.t<string>('campaignList.labels.pmpInactiveState');
          color = 'black';
        } else if (pmpIsExpired) {
          des = i18n.t<string>('campaignList.labels.pmpExpiredState');
          color = 'danger';
        }
        break;
      case 'DELETED':
      case 'ARCHIVED':
        des = i18n.t<string>('campaignList.labels.deleteState');
        color = 'danger';
        break;
      case 'ACTIVE':
        if (moment(campaign.startDate).isAfter(now)) {
          des = i18n.t<string>('campaignList.labels.notStartState');
          color = 'light';
        } else if (moment(campaign.endDate).isBefore(now)) {
          des = i18n.t<string>('campaignList.labels.endState');
          color = 'light';
        } else if (campaign.additionalInfo && campaign.additionalInfo.creativeAmount.enableCount === 0) {
          des = i18n.t<string>('campaignList.labels.noCreativesState');
          color = 'black';
        } else {
          des = i18n.t<string>('campaignList.labels.activateState');
        }
        if (pmpIsEnd) {
          des = i18n.t<string>('campaignList.labels.pmpEndState');
          color = 'light';
        }
        break;
      default:
        break;
    }

    return {
      des,
      color,
      extraInfo
    };
  }

  isCampaignBudgetRemain = (campaign) => {
    return ['PAUSED', 'CAMPAIGN_GROUP_PAUSED'].includes(campaign.effectiveStatus) &&
      campaign.budget - campaign.spents > 0;
  }

  getRequiredMinimumBudgetsHint (): string {
    let hint = '';
    const selectedCampaigns: RtbCampaignListBasic[] = this.campaignList.filter(campaign => _.includes(this.state.selectedCampaign, campaign.id));
    const isCBO: boolean = this.l1Object.autoOptimise;
    if (!isCBO) {
      return hint;
    }
    const budget = !_.isNil(this.l1Object.budget)
     ? typeof this.l1Object.budget === 'string'
      ? parseFloat(this.l1Object.budget)
      : this.l1Object.budget
     : 0;
    const activeCampaigns = selectedCampaigns.filter(campaign => campaign.state === CampaignState.ACTIVATE);
    const inactiveCampaigns = selectedCampaigns.filter(campaign => campaign.state === CampaignState.DEACTIVATE);
    const minBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    const minBudgetRequired = activeCampaigns.reduce((sum, campaign) => {
      return sum + minBudgetPerDay * (moment(campaign.endDate).diff(moment(campaign.startDate), 'days') + 1);
    }, 0) + inactiveCampaigns.reduce((sum, campaign) => {
      return sum + minBudgetPerDay * (moment(campaign.endDate).diff(moment(), 'days') + 1) + _.get(campaign, 'spents', 0);
    }, 0);

    if (budget < minBudgetRequired) {
      hint = i18n.t<string>('campaignList.labels.minBudgetRequiredHint', {
        margin: minBudgetRequired - budget
      });
    }
    return hint;
  }

  getCampaignTypeDes (type) {
    if (type in AdType) {
      return i18n.t<string>(`campaignList.labels.adType${_.upperFirst(_.camelCase(type))}`);
    }

    return i18n.t<string>('campaignList.labels.adTypeEmpty');
  }

  getProgress (campaign) {
    const isDailyBudgetCampaign = campaign.dailyTargetBudget > 0;
    const campaignProgress = isDailyBudgetCampaign
      ? getDailyBudgetCampaignProgressRate(campaign)
      : getCampaignProgressRate(campaign);
    const executeRate = campaignProgress.executeRate;
    const predictRate = Math.min(campaignProgress.predictRate, 1);
    const warnignLine = campaign.budget * 1.1;
    const discrepancy = predictRate - executeRate;
    return {
      spents: `${this.order.currency} ${numberWithCommas(_.floor(campaign.spents, 2).toFixed(2))}`,
      budget: `${this.order.currency} ${numberWithCommas(_.floor(campaign.budget, 2).toFixed(2))}`,
      executeRate,
      predictRate,
      danger: campaign.spents > warnignLine,
      deepWarning: discrepancy > 0.03,
      warning: discrepancy > 0.01 && discrepancy <= 0.03
    };
  }

  getDistributeDes (dailyTargetBudget) {
    return dailyTargetBudget && dailyTargetBudget > 0 ?
      i18n.t<string>('campaignList.labels.budgetDailyDistribution', { currency: this.order.currency, budget: formatPrice(this.order.currency, dailyTargetBudget) }) :
      i18n.t<string>('campaignList.labels.budgetSchedule');
  }

  getPriceModelDes (priceModel) {
    return i18n.t<string>(`campaign.labels.${priceModel}`);
  }

  getLimitationsToShow (campaign) {
    let limitations = _.get(campaign, 'additionalInfo.limitations', {});
    const supportAgeGender = isAdTypeSupportOutdoorDevice(campaign.adType);
    return this.manager.getLimitationSummaryData(
      limitations,
      this.order.orderType,
      this.l1Object.channel === L1ObjectChannel.RETAIL_MEDIA,
      _.get(this.localeMeta, 'property.addonProps.agencySegmentLimitationName', ''),
      supportAgeGender ? ['adx', 'age_min', 'age_max'] : []
    );
  }

  getCpc (campaign) {
    let spents = campaign.spents;
    let clicks = campaign.clicks;
    let cpc = 0;
    if (clicks !== 0) {
      cpc = spents / clicks;
    }

    return cpc;
  }

  getCtr (campaign) {
    let impres = campaign.impres;
    let clicks = campaign.clicks;
    let vctr = 0;
    if (impres !== 0) {
      vctr = (clicks / impres) * 100;
    }
    return vctr;
  }

  getVctr (campaign) {
    let viewable = campaign.viewable;
    let clicks = campaign.clicks;
    let vctr = 0;
    if (viewable !== 0) {
      vctr = (clicks / viewable) * 100;
    }
    return vctr;
  }

  getCpa (campaign) {
    let spents = campaign.spents;
    let convs = campaign.convs;
    let cpa = 0;
    if (convs !== 0) {
      cpa = spents / convs;
    }
    return cpa;
  }

  getCvr (campaign) {
    let convs = campaign.convs;
    let clicks = campaign.clicks;
    let cvr = 0;
    if (clicks !== 0) {
      cvr = (convs / clicks) * 100;
    }
    return cvr;
  }

  getViewRate = (campaign) => {
    let adView = campaign.adView;
    let impres = campaign.impres;
    let viewRate = 0;
    if (impres !== 0) {
      viewRate = (adView / impres) * 100;
    }
    return viewRate;
  }

  getViewableViewRate = (campaign) => {
    let adView = campaign.adView;
    let viewable = campaign.viewable;
    let viewRate = 0;
    if (viewable !== 0) {
      viewRate = (adView / viewable) * 100;
    }
    return viewRate;
  }

  getDeliverTypeDesc (deliverType) {
    if (deliverType === DeliverType.STANDARD) {
      return i18n.t<string>('campaignList.labels.deliverStandard');
    }

    return i18n.t<string>('campaignList.labels.deliverAsap');
  }

  getObjectTypeDesc (campaign) {
    if (campaign.isDraft) {
      return i18n.t<string>('l2ObjectList.labels.draft');
    } else {
      return i18n.t<string>('l2ObjectList.labels.ordinaryCampaign_rtb');
    }
  }

  getViewModelData () {
    this.campaignTags = [];
    this.campaignStates = [];
    this.objectTypes = [];
    const showBudget = !_.get(this.l1Object, 'autoOptimise', false);
    const viewModelData: Array<any> = this.campaignList.map(campaign => {
      const viewTrackingSize = _.get(campaign, 'additionalInfo.viewTrackingSize', 0);
      const conversionTracking = _.get(campaign, 'additionalInfo.conversionTracking');
      const videoProgressTrackingOffset = _.get(campaign, 'additionalInfo.videoProgressTrackingOffset');
      this.campaignTags = _.uniq(_.concat(this.campaignTags, _.defaultTo(campaign.tags, [])));
      const stateData = this.getCampaignStatusDesData(campaign);
      const effectiveData = this.getCampaignEffectiveStatusDesData(campaign);
      this.campaignStates = _.uniq(_.concat(this.campaignStates, stateData.des));
      this.campaignEffectiveStatus = _.uniq(_.concat(this.campaignEffectiveStatus, effectiveData.des));
      const objectTypeDes = this.getObjectTypeDesc(campaign);
      this.objectTypes = _.uniq(_.concat(this.objectTypes, objectTypeDes));
      const results = _.get(campaign, 'report.results', 0);
      const listId = campaign.isDraft ? `${campaign.id}_draft_${campaign.draftId}` : campaign.id;
      const campaignData: any = {
        listId,
        id: campaign.id,
        draftId: campaign.draftId,
        spents: campaign.spents,
        budget: campaign.budget,
        dailyTargetBudget: campaign.dailyTargetBudget,
        priceModel: campaign.priceModel,
        optimize: campaign.optimize,
        bidPrice: campaign.bidPrice,
        impres: campaign.impres,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        state: campaign.state,
        effectiveStatus: campaign.effectiveStatus,
        stateColumn: stateData,
        deliveryColumn: effectiveData,
        additionalInfo: campaign.additionalInfo,
        magnificationRatio: campaign.magnificationRatio,
        retailType: campaign.retailType,
        l2ObjectId: campaign.l2ObjectId,
        resultsColumn: {
          value: results,
          desc: numberWithCommas(results)
        },
        isDraft: campaign.isDraft,
        nameColumn: {
          name: campaign.name,
          listId,
          typeDes: this.getCampaignTypeDes(campaign.adType)
        },
        creativeColumn: _.get(campaign.additionalInfo, 'creativeAmount.enableCount', 0),
        scheduleColumn: {
          start: moment(campaign.startDate).format(timeFormat),
          end: moment(campaign.endDate).format(timeFormat)
        },
        progressColumn: showBudget ? this.getProgress(campaign) : undefined,
        budgetColumn: {
          budget: campaign.budget,
          budgetDes: showBudget ? formatPriceWithCurrency(this.order.currency, campaign.budget) : undefined,
          distributeDes: this.getDistributeDes(campaign.dailyTargetBudget),
          deliverTypeDes: this.getDeliverTypeDesc(campaign.deliverType)
        },
        priceColumn: {
          priceModel: this.getPriceModelDes(campaign.priceModel),
          type: campaign.optimize,
          bidPriceDes: `${this.order.currency} ${numberWithCommas(campaign.bidPrice)}`
        },
        limitationColumn: this.getLimitationsToShow(campaign),
        trackingColumn: _.omitBy({
          conversionTracking: conversionTracking && i18n.t<string>('campaignList.labels.conversionTracking'),
          viewTrackingCodes: viewTrackingSize > 0 ? i18n.t<string>('campaignList.labels.viewTrackingCodes') : undefined,
          videoProgressTrackingCode: videoProgressTrackingOffset && i18n.t<string>('campaignList.labels.videoProgressTrackingCode')
        }, _.isUndefined),
        tagsColumn: campaign.tags,
        viewableColumn: {
          value: campaign.viewable,
          desc: numberWithCommas(campaign.viewable)
        },
        impresColumn: {
          value: campaign.impres,
          desc: numberWithCommas(campaign.impres)
        },
        clicksColumn: {
          value: campaign.clicks,
          desc: numberWithCommas(campaign.clicks)
        },
        convertColumn: {
          value: campaign.convs,
          desc: numberWithCommas(campaign.convs)
        },
        viewColumn: {
          value: campaign.adView,
          desc: numberWithCommas(campaign.adView)
        },
        cpcColumn: { value: this.getCpc(campaign) },
        ctrColumn: { value: this.getCtr(campaign) },
        vctrColumn: { value: this.getVctr(campaign) },
        cpaColumn: { value: this.getCpa(campaign) },
        cvrColumn: { value: this.getCvr(campaign) },
        viewRateColumn: { value: this.getViewRate(campaign) },
        viewableViewRateColumn: { value: this.getViewableViewRate(campaign) },
        uuCountColumn: { value: campaign.uuCount }
      };
      campaignData.canNotCopyMessage = this.getCanNotCopyMessage(campaignData);
      campaignData.canNotSlitMessage = this.getCanNotSplitMessage(campaignData);
      return campaignData;
    });

    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(this.campaignList)
    };

    return viewModelData;
  }

  getAuditLogModel (): CampaignAuditLogModel | undefined {
    if (!this.order || !this.campaignList) {
      return undefined;
    }

    const isAdmin = this.localeMeta ? _.isNil(this.localeMeta.agencyId) : false;

    if (
      this.auditLogModel &&
      this.campaignList === this.auditLogModel.campaignList
    ) {
      return this.auditLogModel;
    }

    this.auditLogModel = new CampaignAuditLogModel(
      isAdmin,
      this.campaignList
    );
    return this.auditLogModel;
  }

  showTable = (listType: CampaignListType, e) => {
    e && e.stopPropagation();
    this.seletedTab = listType;
    const viewableEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);
    switch (listType) {
      case CampaignListType.PERFORMANCE:
        if (viewableEnable) {
          this.columnsToShow = [...performanceColumnsWithViewable];
        } else {
          this.columnsToShow = [...performanceColumns];
        }
        break;
      case CampaignListType.VIDEO_PERFORMANCE:
        if (viewableEnable) {
          this.columnsToShow = videoPerformanceColumnsWithViewable;
        } else {
          this.columnsToShow = videoPerformanceColumns;
        }
        break;
      default:
        this.columnsToShow = [...basicColumns];
        break;
    }
    if (this.hideBudget) {
      this.columnsToShow = this.columnsToShow.filter(column => column !== CampaignListColumns.PROGRESS);
    }
    if (this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA) {
      this.columnsToShow.splice(1, 0, CampaignListColumns.RETAIL);
    }
    if (listType === CampaignListType.AUDIT_LOG) {
      this.selectedCampaign = [];
    }
    this.updateState();
  }

  handleOnSelect = (campaign) => {
    const isDraft: boolean = _.get(campaign, 'isDraft', false);
    const objectId = isDraft ? campaign.draftId : campaign.id;
    const selectedArray: number[] = isDraft ? this.selectedDraft : this.selectedCampaign;
    if (selectedArray.indexOf(objectId) > -1) {
      _.remove(selectedArray, id => id === objectId);
    } else {
      selectedArray.push(objectId);
    }

    this.updateState();
  }

  handleOnSelectAll = () => {
    const selectedArrays = [...this.selectedCampaign, ...this.selectedDraft];
    if (
      selectedArrays.length ===
      this.filteredViewModelData.filter(
        viewModelData => !this.isPmp(viewModelData)
      ).length
    ) {
      this.selectedCampaign = [];
      this.selectedDraft = [];
    } else {
      this.selectedCampaign = this.filteredViewModelData
        .filter(
          viewModelData => !viewModelData.isDraft && !this.isPmp(viewModelData)
        )
        .map(viewModelData => viewModelData.id);
      this.selectedDraft = this.filteredViewModelData
        .filter(viewModelData => viewModelData.isDraft)
        .map(viewModelData => viewModelData.draftId);
    }
    this.updateState();
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.onSearchChange(searchString);
    this.updateViewModelData();
  }

  handleOnTagFilterApply = (tags) => {
    this.selectedTagFilter = [...tags];
    this.updateViewModelData();
  }

  handleOnStateFilterApply = (stateDesList) => {
    this.selectedStateFilter = [...stateDesList];
    this.updateViewModelData();
  }

  handleOnEffectiveStatusFilterApply = (effectiveStatusDesList) => {
    this.selectedEffectiveStatusFilter = [...effectiveStatusDesList];
    this.updateViewModelData();
  }

  handleOnObjectTypeFilterApply = (objectTypeList) => {
    this.selectedObjectTypeFilter = [...objectTypeList];
    this.updateViewModelData();
  }

  updateViewModelData = () => {
    this.needUpdateViewModelData = false;
    const allViewModelData = this.getViewModelData();
    this.filteredViewModelData = _.filter(allViewModelData,
      viewModelData => {
        const campaign = this.campaignList.find(campaign => campaign.id === viewModelData.id || campaign.draftId === viewModelData.draftId);
        if (!campaign) {
          return false;
        }
        const initWithoutDelete = (this.selectedStateFilter.length === 0 && this.selectedEffectiveStatusFilter.length === 0) ? viewModelData.state !== CampaignState.DELETE : true;
        const stateIsMatch = this.selectedStateFilter.length === 0 || this.selectedStateFilter.includes(viewModelData.stateColumn.des);
        const effecitveStatuseIsMatch = this.selectedEffectiveStatusFilter.length === 0 || this.selectedEffectiveStatusFilter.includes(viewModelData.deliveryColumn.des);
        const tagsIsMatch = this.selectedTagFilter.length === 0 || _.intersection(viewModelData.tagsColumn, this.selectedTagFilter).length > 0;
        const objectTypeIsMatch = this.selectedObjectTypeFilter.length === 0 || this.selectedObjectTypeFilter.includes(this.getObjectTypeDesc(viewModelData));
        const nameIsMatch = viewModelData.nameColumn.name.toLowerCase().includes(this.searchString.toLowerCase());
        const listIdIsMatch = viewModelData.listId ? viewModelData.listId.toString().includes(this.searchString) : false;
        return (nameIsMatch || listIdIsMatch) && tagsIsMatch && objectTypeIsMatch && stateIsMatch && effecitveStatuseIsMatch && initWithoutDelete;
      }
    );
    const selectedObjects = _.intersectionWith(this.campaignList, this.filteredViewModelData,
      (campaign, viewModel) => viewModel.isDraft ? campaign.draftId === viewModel.draftId : campaign.id === viewModel.id);
    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(selectedObjects)
    };

    this.updateState();
  }

  getSummaryData = (campaignList: Array<any>) => {
    const viewableSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.viewable, 0);
    const clickSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.clicks, 0);
    const convsSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.convs, 0);
    const spentsSum = campaignList.reduce<number>((partial, campaign) => partial + getPriceValue(this.order.currency, campaign.spents), 0);
    const impresSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.impres, 0);
    const viewSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.adView, 0);
    return {
      nameColumn: i18n.t<string>('campaignList.labels.campaignCount', { count: campaignList.length }),
      budgetColumn: this.hideBudget ? '' : i18n.t<string>('campaignList.labels.campaignBudget', {
        number: formatPriceWithCurrency(
          this.order.currency,
          campaignList.reduce<number>((partial, campaign) => partial + campaign.budget, 0)
        )}
      ),
      viewableColumn: numberWithCommas(viewableSum),
      impresColumn: numberWithCommas(impresSum),
      clicksColumn: numberWithCommas(clickSum),
      convertColumn: numberWithCommas(convsSum),
      viewColumn: numberWithCommas(viewSum),
      spents: numberWithCommas(spentsSum),
      cpcColumn: formatPriceAny(clickSum === 0 ? 0.00 : _.floor(spentsSum / clickSum, 2)),
      ctrColumn: `${impresSum === 0 ? 0.00 : ((clickSum / impresSum) * 100).toFixed(2)}%`,
      vctrColumn: `${viewableSum === 0 ? 0.00 : ((clickSum / viewableSum) * 100).toFixed(2)}%`,
      cpaColumn: formatPriceAny(convsSum === 0 ? 0.00 : _.floor(spentsSum / convsSum, 2)),
      cvrColumn: `${clickSum === 0 ? 0.00 : ((convsSum / clickSum) * 100).toFixed(2)}%`,
      viewRateColumn: `${impresSum === 0 ? 0.00 : ((viewSum / impresSum) * 100).toFixed(2)}%`,
      viewableViewRateColumn: `${viewableSum === 0 ? 0.00 : ((viewSum / viewableSum) * 100).toFixed(2)}%`
    };
  }

  handleRemoveSelect = () => {
    this.selectedCampaign = [];
    this.selectedDraft = [];
    this.updateState();
  }

  activeCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('activate', i18n.t<string>('campaignList.labels.activeSuccess'));
  }

  deactiveCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('deactivate', i18n.t<string>('campaignList.labels.deactiveSuccess'));
  }

  generateUpdateStatePayload = (selectedObjects: number[], objectType: 'campaign' | 'draft') => {
    const key = objectType === 'campaign' ? 'id' : 'draftId';
    const payload = selectedObjects.map(objectId => {
      const object = this.campaignList.find(campaign => {
        const id = _.get(campaign, key, 0);
        return id && id.toString() === objectId.toString();
      });
      return {
        l2ChannelId: objectId,
        isDraft: _.get(object, 'isDraft', false)
      };
    });
    return payload;
  }

  excuteCampaignAction = async (action: 'activate' | 'deactivate', successMessage) => {
    this.updateLoading(true);
    const updateStatePayload = [
      ...this.generateUpdateStatePayload(this.selectedCampaign, 'campaign'),
      ...this.generateUpdateStatePayload(this.selectedDraft, 'draft')
    ];
    try {
      await this.manager.updateCampaignState(updateStatePayload, action);
      this.selectedCampaign = [];
      this.selectedDraft = [];
      this.onCampaignChange();
      toast.success(successMessage);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  delete = async (): Promise<void> => {
    this.updateLoading(true);
    try {
      await this.manager.deleteCampaigns(this.deleteCampaignIds);
      await this.rtbCampaignDraftManager.deleteDrafts(this.deleteDraftIds);
      this.selectedCampaign = [];
      this.deleteCampaignIds = [];
      this.selectedDraft = [];
      this.deleteDraftIds = [];
      this.onCampaignChange(true);
      toast.success(i18n.t<string>('campaignList.labels.deleteSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  updateBidWeight = async (bidWeight: number): Promise<void> => {
    if (!this.campaignToChangeBidWeight) {
      return;
    }
    this.updateLoading(true);
    try {
      await this.manager.updateCampaignBidWeight(this.campaignToChangeBidWeight, bidWeight);
      this.campaignToChangeBidWeight = undefined;
      this.onCampaignChange(true);
      toast.success(i18n.t<string>('campaignList.labels.updateBidWeightSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  allSelectedCampaignSameType () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return true;
    }

    const types = _.uniq(
      _.filter(this.campaignList, campaign =>
        (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1) ||
        (campaign.draftId !== undefined && this.selectedDraft.indexOf(campaign.draftId) > -1))
        .map(campaign => campaign.adType)
    );
    return types.length === 1;
  }

  allSelectedCampaignSameRetail () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return true;
    }

    const retails = _.uniq(
      _.filter(this.campaignList, campaign =>
        (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1) ||
        (campaign.draftId !== undefined && this.selectedDraft.indexOf(campaign.draftId) > -1))
        .map(campaign => campaign.retailType)
    );
    return retails.length === 1;
  }

  selectUnknowAdTypeCampaign () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return false;
    }

    const selectCampaignList = _.filter(
      this.campaignList, campaign =>
      (campaign.id !== undefined && this.selectedCampaign.indexOf(campaign.id) > -1) ||
      (campaign.draftId !== undefined && this.selectedDraft.indexOf(campaign.draftId) > -1)
    );
    return selectCampaignList.filter(campaign => !campaign.adType).length > 0;
  }

  canDeleteSelectedCampaigns (campaignIds: Array<number>) {
    const canDeleteCampaigns = _.filter(this.campaignList, campaign => {
      const selected = campaign.id !== undefined && campaignIds.indexOf(campaign.id) > -1;
      if (!selected) {
        return false;
      }
      if (campaign.isDraft) {
        return false;
      }
      return _.get(campaign, 'additionalInfo.creativeAmount.bindingCount', 0) === 0;
    });
    return canDeleteCampaigns.length === campaignIds.length;
  }

  setCampaignsToDelete (campaignIds: number[]) {
    this.deleteCampaignIds = campaignIds;
    this.updateState();
  }

  setDraftsToDelete = (draftIds: number[]) => {
    this.deleteDraftIds = draftIds;
    this.updateState();
  }

  setVisibilityOfDraftCreateModal = (show: boolean) => {
    this.showDraftCreateModal = show;
    this.updateState();
  }

  setCampaignToChangeBidWeight (campaignId?: number | string) {
    this.campaignToChangeBidWeight = campaignId;
    this.updateState();
  }

  updateModelData (order: Order, campaignList: RtbCampaignListBasic[]) {
    this.order = order;
    this.campaignList = campaignList;
    this.needUpdateViewModelData = true;
  }

  updateLoading (loading: boolean) {
    this.loading = loading;
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
