import { FieldArray, FieldArrayRenderProps } from 'formik';
import i18n from 'i18n';
import { get } from 'lodash';
import { useCallback } from 'react';
import { LineMessageType } from 'core/advertiser/LineBeacon';
import { OutdoorLineMessageField } from './OutdoorLineMessageField';
import { WithAddIcon } from 'components/common/WithAddIcon/WithAddIcon';
import styles from './outdoorFields.module.scss';
import { FormikField } from 'components/common/form/field/FormikField';

export const renderOutdoorLineMessageFields = () => {
  return (
    <OutdoorLineMesssageFields />
  );
};

export const OutdoorLineMesssageFields: React.FC<any> = () => {

  const renderOutdoorLineMessageFields = useCallback(
    ({ name, form, push, remove, replace }: FieldArrayRenderProps) => {
      const lineMessages = get(form.values, name);
      const onNewButtonClicked = () => {
        push({
          type: LineMessageType.TEXT
        });
      };

      const newButton = (
        <FormikField.Custom label=''>
          <WithAddIcon className={styles.filterAdder} onClick={onNewButtonClicked}>
            {i18n.t<string>('outdoorLineMessageFields.labels.addLineMessage')}
          </WithAddIcon>
        </FormikField.Custom>
      );

      if (!lineMessages) {
        return null;
      }

      const fields = lineMessages.map((lineMessage, index) => {
        const removeField = () => remove(index);
        const fieldName = `${name}.${index}`;
        const onTypeChange = (type: LineMessageType) => {
          replace(index, {
            type
          });
        };
        return (
          <OutdoorLineMessageField
            key={fieldName}
            fieldName={fieldName}
            lineMessage={lineMessage}
            onTypeChange={onTypeChange}
            removeField={removeField}
          />
        );
      });
      return (
        <>
          <div>{fields}</div>
          {lineMessages.length < 5 && newButton}
        </>
      );
    },
    []
  );

  return (
    <FieldArray name='lineMessage' render={renderOutdoorLineMessageFields} />
  );
};
