import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n';

export const OutdoorLineMessageOperates = ({ id, remove }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant='link' id={`operates-${id}`} className='toggle'>
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={remove} className='dropdownItem'>
          {i18n.t<string>('common.buttons.delete')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
