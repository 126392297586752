import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { CreateImageFormProps } from './ImageFormModel';
import styles from './imageFormMultiple.module.scss';
import _ from 'lodash';
import { ImageCreativeSizeHint } from './ImageCreativeSizeHint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';

export const ImageFormMutliple: React.FunctionComponent<CreateImageFormProps & { formik: FormikContextType<any> }> = (props) => {

  const {
    model,
    hintModalData,
    renderHintModal,
    setHintModalData,
    getInitFormConfig
  } = props;

  const [sizeInstructionModalData, setSizeInstructionModalData] = useState<any>(undefined);
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());

  const showSizeInstruction = useCallback(() => {
    const modalData = {
      component: ImageCreativeSizeHint,
      props: {
        widthHeightMap: model.validWidthHeightMap,
        closeHint: _.partial(setHintModalData, undefined)
      }
    };
    setSizeInstructionModalData(modalData);
    setHintModalData(modalData);
  }, [model.validWidthHeightMap, setHintModalData]);

  const closeSizeInstruction = useCallback(() => {
    setSizeInstructionModalData(undefined);
    setHintModalData(undefined);
  }, [setHintModalData]);

  useEffect(() => {
    if (hintModalData !== sizeInstructionModalData) {
      setSizeInstructionModalData(undefined);
    }
  }, [hintModalData, sizeInstructionModalData]);

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikMultiImageInput({
              label: i18n.t<string>('creativeSetupFlow.labels.images'),
              name: 'medias.images',
              maxWidth: 318,
              validTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.imageCreativeTypeHint'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              permanentHint: (
                <div
                  className={styles.sizeInstructionBtn}
                  onClick={sizeInstructionModalData ?
                    closeSizeInstruction : showSizeInstruction
                  }
                >
                  {i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}
                  <FontAwesomeIcon icon={faInfoCircle}/>
                </div>
              ),
              validate: props.model.validateImages
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .withHintModal(renderHintModal())
        .build()
      )
      .build()
    );
  }, [
    props.basicFields,
    props.model.validateImages,
    sizeInstructionModalData,
    renderHintModal,
    showSizeInstruction,
    closeSizeInstruction
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(ImageFormMutliple);
