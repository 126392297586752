import { BeaconConnection, BeaconConnectionFormData, BeaconType } from 'core/advertiser/BeaconConnection';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useMemo } from 'react';
import i18n from 'i18n';
import { useLineOASettingFields } from 'hooks/useLineOASettingFields';
import { FormConfig } from 'components/common/form/FormConfig';
import { useFormikContext } from 'formik';
import { validateEmpty } from 'utils/ValidateUtils';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { omit } from 'lodash';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';

const advertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

export type BeaconConnectionFormModel = {
  title: string;
  loading: boolean;
  initBeaconConnection: BeaconConnectionFormData;
  onSave: (beaconConnection: BeaconConnectionFormData) => void;
};

const beaconTypeOptions = createSelectOptionsFromEnum(BeaconType, 'beaconType.');

export const useBeaconConnectionFormConfig = () => {

  const lineOASettingFields = useLineOASettingFields();
  const { values } = useFormikContext<BeaconConnectionFormData>();

  const fields = useMemo(() => {
    const builder = new FormConfig.FieldsBuilder()
      .addFormikSelect({
        label: i18n.t<string>('beaconConnectionList.labels.beaconType'),
        name: 'beaconType',
        simpleValue: true,
        options: beaconTypeOptions
      });

    if (!values.beaconType) {
      return builder.build();
    }

    if (values.beaconType === BeaconType.LINE_SIMPLE_BEACON) {
      builder.addFormikInput({
        label: i18n.t<string>('beaconConnectionList.labels.lineOAName'),
        name: 'name',
        validate: validateEmpty
      });
      builder.addFields(
        lineOASettingFields.map(field => ({
          ...field,
          props: {
            ...field.props,
            validate: validateEmpty
          }
        }))
      );
    }

    return builder.build();
  }, [
    lineOASettingFields,
    values.beaconType
  ]);

  const formConfig = useMemo(() => {
    return new FormConfig.Builder()
    .addSection(
      new FormConfig.SectionBuilder(fields)
      .withShadow(false)
      .build()
    )
    .build();
  }, [fields]);

  return formConfig;
};

export const useCreateBeaconConnectionFormModel = (
  advertiserId: number,
  onSaved: () => void
): BeaconConnectionFormModel => {
  const { loading, callAPIs } = useCallAPI();

  const onSave = useCallback(
    (beaconConnection: BeaconConnectionFormData) => {
      callAPIs(
        [() => advertiserManager.createBeaconConnection(advertiserId, beaconConnection)],
        () => {
          onSaved();
        }
      );
    },
    [advertiserId, callAPIs, onSaved]
  );

  const initBeaconConnection = useMemo(() => {
    return {
      beaconType: BeaconType.LINE_SIMPLE_BEACON,
      name: '',
      hardwareId: '',
      webhookType: '' as const,
      channelAccessToken: '',
      channelSecret: ''
    };
  }, []);

  return {
    title: i18n.t<string>('beaconConnectionForm.titles.create'),
    loading,
    initBeaconConnection,
    onSave
  };
};

export const useEditBeaconConnectionFormModel = (
  advertiserId: number,
  beaconConnection: BeaconConnection,
  onSaved: () => void
): BeaconConnectionFormModel => {
  const { loading, callAPIs } = useCallAPI();

  const onSave = useCallback(
    (editedBeaconConnection: BeaconConnectionFormData) => {
      callAPIs(
        [
          () =>
            advertiserManager.updateBeaconConnection(
              advertiserId,
              beaconConnection.id,
              editedBeaconConnection
            )
        ],
        () => {
          onSaved();
        }
      );
    },
    [advertiserId, beaconConnection, callAPIs, onSaved]
  );

  return {
    title: i18n.t<string>('beaconConnectionForm.titles.edit'),
    loading,
    initBeaconConnection: omit(beaconConnection, 'id'),
    onSave
  };
};
