import { AdType } from 'core/rtbCampaign/RtbCampaign';
import moment from 'moment';

export const getDailyBudgetCampaignProgressRate = (
  { budget, spents, startDate, endDate }: {
    budget: number;
    spents: number;
    startDate: string;
    endDate: string;
  }
) => {
  const startTime = moment(startDate);
  const endTime = moment(endDate);
  const now = moment();
  const isStart = now.isSameOrAfter(startTime);
  const isEnd = now.isAfter(endTime);

  let predictRate: number;
  if (!isStart) {
    predictRate = 0;
  } else if (isEnd) {
    predictRate = 1;
  } else {
    const startDay = moment(startDate).startOf('day');
    const endDay = moment(endDate).startOf('day');
    const totalDays = endDay.diff(startDay, 'day') + 1;
    const todayIsStartDay = moment().startOf('day').isSame(startDay);
    const todayIsEndDay = moment().startOf('day').isSame(endDay);
    let pastDays = 0;
    let todayPastHours = 0;
    let todayTotalHours = 24;
    if (todayIsStartDay) {
      todayPastHours = now.diff(startTime, 'hour') + 1;
      const endOfStartDay = startDay.isSame(endDay)
        ? endTime
        : moment(startDate).endOf('day');
      todayTotalHours = endOfStartDay.diff(startTime, 'hour') + 1;
    } else if (todayIsEndDay) {
      pastDays = totalDays - 1;
      todayPastHours = now.diff(endDay, 'hour') + 1;
      todayTotalHours = endTime.diff(endDay, 'hour') + 1;
    } else {
      pastDays = moment().startOf('day').diff(startDay, 'day');
      todayPastHours = now.diff(moment().startOf('day'), 'hour') + 1;
    }
    // 每天當成投滿 24 小時
    const totalHours = totalDays * 24;
    // 過去的天數都當成投滿 24 小時
    const pastHoursBeforeToday = pastDays * 24;
    // 今天如果沒有投遞 24 小時，每小時進度必須等比例放大
    const todayOneHourScale = 24 / todayTotalHours;
    predictRate = (pastHoursBeforeToday + todayPastHours * todayOneHourScale) / totalHours;
  }

  const canCalRate = budget !== 0;
  if (canCalRate) {
    return { executeRate: spents / budget, predictRate };
  }
  return { executeRate: 0, predictRate: 0 };
};

export const getCampaignProgressRate = (
  { budget, spents, currencyRate, olapExpectSpent }: {
    budget: number;
    spents: number;
    currencyRate: number;
    olapExpectSpent: number;
  }
) => {
  const budgetUsd = budget / currencyRate;
  const canCalRate = budgetUsd !== 0;
  if (canCalRate) {
    return {
      executeRate: spents / budget,
      predictRate: olapExpectSpent / budgetUsd
    };
  }
  return {
    executeRate: 0,
    predictRate: 0
  };
};

export const isAdTypeSupportOutdoorDevice = (adType: AdType) => {
  return [
    AdType.PIC_SHORT,
    AdType.PIC_LONG,
    AdType.PIC_EXTENDED,
    AdType.POYA_SHORT,
    AdType.POYA_LONG,
    AdType.POYA_EXTENDED
  ].includes(adType);
};
