import { Fragment, useCallback } from 'react';
import styles from './labelValueColumn.module.scss';

export const LabelValueColumn: React.FC<{
  options?: SelectOptions[];
  valueClassName?: string;
}> = ({ options, valueClassName }) => {
  const renderContent = useCallback(
    () =>
      options
        ? options.map(option => (
            <Fragment key={option.label}>
              <div>{option.label}</div>
              <div style={{ whiteSpace: 'pre' }}>{` : `}</div>
              <div style={{ overflowWrap: 'anywhere' }} className={valueClassName}>
                {option.value}
              </div>
            </Fragment>
          ))
        : 'N/A',
    [options, valueClassName]
  );

  return <div className={styles.labelValueColumn}>{renderContent()}</div>;
};
