import _ from 'lodash';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { CreativeOfCampaign, CreativeType, creativeTypeOrder } from 'core/creative/Creative';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import moment from 'moment';
import { CreativeBindData } from 'core/rtbCampaign/CreativeBindData';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';

export interface BindCreativeStateContentModel {
  readonly state: BindCreativeStateContentState;
  readonly event: UpdateEventListener<BindCreativeStateContentModel>;
  readonly creatives: CreativeOfCampaign[];
  readonly creativeTypes: CreativeType[];
  readonly stateContext?: CreativeManagementStateContext;
  readonly selectedCreatives: number[];
  init (): Promise<void>;
  handleOnTypeFilter (type: CreativeType): void;
  handleOnSelectedFilter (): void;
  handleOnSearch (keyWord: string): void;
  handleOnSelect (creativeId: number): void;
  handleOnSelectAll (): void;
  handleOnSort (fromNewToOld: boolean): void;
  setStateContext (stateContext: CreativeManagementStateContext): void;
  reset (): void;
}

export type BindCreativeStateContentProps = {
  readonly model: BindCreativeStateContentModel;
};

export type BindCreativeStateContentState = {
  readonly loading: boolean;
  readonly filteredCreatives: CreativeOfCampaign[];
  readonly selectedCreatives: number[];
  readonly selectedFilteredCreatives: number[];
  readonly selectedType?: CreativeType;
  readonly sortFromNewToOld: boolean;
};

export class DefaultBindCreativeStateContentModel
  implements BindCreativeStateContentModel {
  event: FireableUpdateEventListener<BindCreativeStateContentModel>;
  loading: boolean;
  manager: CreativeManager;
  creatives: CreativeOfCampaign[];
  adType?: AdType;
  selectedCreatives: number[];
  searchString: string;
  filteredCreatives: CreativeOfCampaign[];
  selectedType?: CreativeType;
  creativeTypes: CreativeType[];
  advertiserId: number;
  sortFromNewToOld: boolean;
  forbidCreativeIdList: number[];
  selectedFilteredCreatives: number[];
  campaigns: CreativeBindData[];
  stateContext?: CreativeManagementStateContext;
  supportScheduledCreatives: boolean;

  constructor (
    campaigns: CreativeBindData[],
    advertiserId: number,
    forbidCreativeIdList: number[] = [],
    protected l1ObjectChannel: L1ObjectChannel,
    manager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.event =
      new FireableUpdateEventListener<BindCreativeStateContentModel>();
    this.loading = true;
    this.manager = manager;
    this.creatives = [];
    this.campaigns = campaigns;
    this.adType = campaigns[0].adType;
    this.selectedCreatives = [];
    this.selectedFilteredCreatives = [];
    this.searchString = '';
    this.filteredCreatives = this.creatives;
    this.creativeTypes = [];
    this.advertiserId = advertiserId;
    this.sortFromNewToOld = true;
    this.forbidCreativeIdList = forbidCreativeIdList;
    this.supportScheduledCreatives = this.campaigns.every(
      campaign => !campaign.isPmp
    );
  }

  reset () {
    this.searchString = '';
    this.selectedCreatives = [];
    this.selectedFilteredCreatives = [];
    this.sortFromNewToOld = true;
    this.selectedType = this.creativeTypes[0];
    this.handleOnSort(true);
  }

  get state (): BindCreativeStateContentState {
    return {
      loading: this.loading,
      selectedCreatives: this.selectedCreatives,
      filteredCreatives: this.filteredCreatives,
      selectedType: this.selectedType,
      sortFromNewToOld: this.sortFromNewToOld,
      selectedFilteredCreatives: this.selectedFilteredCreatives
    };
  }

  setStateContext = (stateContext: CreativeManagementStateContext) => {
    this.stateContext = stateContext;
  }

  getCreatives (): Promise<CreativeOfCampaign[]> {
    return this.manager.getWatingBindCreatives({
      channel: this.l1ObjectChannel,
      advertiserId: this.advertiserId,
      adType: this.adType,
      supportSchedule: this.supportScheduledCreatives
    });
  }

  async init (): Promise<void> {
    if (this.creatives.length > 0) {
      return;
    }

    this.updateState(true);
    try {
      const creatives = await this.getCreatives();
      this.creatives = creatives.filter(
        creative => this.forbidCreativeIdList.indexOf(creative.id) < 0
      );
      this.filteredCreatives = this.creatives;
      this.creatives.forEach(creative => {
        if (this.creativeTypes.indexOf(creative.creativeType) < 0) {
          this.creativeTypes.push(creative.creativeType);
        }
      });
      this.creativeTypes = this.creativeTypes.sort((type1, type2) =>
        creativeTypeOrder.indexOf(type1) > creativeTypeOrder.indexOf(type2)
          ? 1
          : -1
      );
      if (this.creativeTypes.length > 0) {
        this.selectedType = this.creativeTypes[0];
      }

      this.handleOnSort(true);
    } catch (e) {
      console.log(`failed to load creatives of adType: ${this.adType}`, e);
      this.creatives = [];
      this.filteredCreatives = [];
      this.creativeTypes = [];
      this.updateState(false);
    }
  }

  handleOnSearch = campaignName => {
    this.searchString = campaignName;
    this.updateFilteredCreative();
  }

  handleOnTypeFilter = type => {
    this.selectedType = type;
    this.updateFilteredCreative();
  }

  handleOnSelectedFilter = () => {
    this.selectedType = undefined;
    this.updateFilteredCreative();
  }

  handleOnSort = (fromNewToOld: boolean) => {
    this.sortFromNewToOld = fromNewToOld;
    this.creatives.sort(
      (creative1: CreativeOfCampaign, creative2: CreativeOfCampaign) => {
        if (
          moment(creative1.createTime).isAfter(moment(creative2.createTime))
        ) {
          return this.sortFromNewToOld ? -1 : 1;
        }
        return this.sortFromNewToOld ? 1 : -1;
      }
    );

    this.updateFilteredCreative();
  }

  updateFilteredCreative = () => {
    this.filteredCreatives = _.filter(this.creatives, creative => {
      const selectedStateIsMatch =
        this.selectedType === undefined
          ? this.selectedCreatives.indexOf(creative.id) >= 0
          : true;
      const searchIsMatch =
        creative.name.includes(this.searchString) ||
        creative.id.toString().includes(this.searchString);
      const typeIsMatch =
        this.selectedType === undefined ||
        creative.creativeType === this.selectedType;
      return selectedStateIsMatch && searchIsMatch && typeIsMatch;
    });

    this.selectedFilteredCreatives = _.filter(
      this.filteredCreatives,
      filteredCreative =>
        this.selectedCreatives.indexOf(filteredCreative.id) > -1
    ).map(creative => creative.id);

    this.updateState(false);
  }

  handleOnSelect = creativeId => {
    if (this.selectedCreatives.indexOf(creativeId) > -1) {
      _.remove(this.selectedCreatives, id => id === creativeId);
    } else {
      this.selectedCreatives.push(creativeId);
    }

    this.updateStateData();
    this.updateFilteredCreative();
  }

  handleOnSelectAll = () => {
    const currentCreatives = _.filter(this.creatives, creative => {
      const searchIsMatch =
        creative.name.includes(this.searchString) ||
        creative.id.toString().includes(this.searchString);
      const typeIsMatch =
        this.selectedType === undefined ||
        creative.creativeType === this.selectedType;
      return searchIsMatch && typeIsMatch;
    }).map(creative => creative.id);
    const selectedCreatives = _.intersection(
      this.selectedCreatives,
      currentCreatives
    );

    if (selectedCreatives.length !== currentCreatives.length) {
      this.selectedCreatives = _.uniq(
        _.concat(this.selectedCreatives, currentCreatives)
      );
    } else {
      _.remove(
        this.selectedCreatives,
        selectedCreativeId => selectedCreatives.indexOf(selectedCreativeId) > -1
      );
    }

    this.updateStateData();
    this.updateFilteredCreative();
  }

  updateStateData = () => {
    this.stateContext?.setData &&
      this.stateContext.setData({
        activate: undefined,
        selectedCreatives: this.selectedCreatives,
        campaignNewCreativesMap: this.campaigns.map(campaign => {
          return {
            id: campaign.id,
            draftId: campaign.draftId,
            name: campaign.name,
            creatives: _.filter(
              this.creatives,
              creative => this.selectedCreatives.indexOf(creative.id) >= 0
            )
          };
        })
      });
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class RetailBindCreativeStateContentModel extends DefaultBindCreativeStateContentModel {

  constructor (
    campaigns: CreativeBindData[],
    advertiserId: number,
    forbidCreativeIdList: number[] = [],
    private retailerId?: string,
    manager: CreativeManager = new DefaultCreativeManager()
  ) {
    super(campaigns, advertiserId, forbidCreativeIdList, L1ObjectChannel.RTB, manager);
  }

  async getCreatives (): Promise<CreativeOfCampaign[]> {
    return this.manager.getWatingBindCreatives({
      channel: L1ObjectChannel.RTB,
      advertiserId: this.advertiserId,
      adType: this.adType,
      retail: this.retailerId,
      supportSchedule: this.supportScheduledCreatives
    });
  }
}

export class FbBindCreativeStateContentModel extends DefaultBindCreativeStateContentModel {

  async getCreatives (): Promise<CreativeOfCampaign[]> {
    return this.manager.getWatingBindCreatives({
      channel: L1ObjectChannel.FB,
      advertiserId: this.advertiserId,
      billingEvent: this.campaigns[0].billingEvent
    });
  }
}
