import React, { RefObject } from 'react';
import ReactDOM from 'react-dom';
import { CreativeSetupFlowDataContext } from '../CreativeSetupFlowDataContext';
import { Tabs, Tab, Button } from 'react-bootstrap';
import i18n from 'i18next';
import { CreativeSetupStepProps, CreativeSetupStepState, CreativeSetupTab } from './CreativeSetupStepModel';
import styles from './creativeSetupStep.module.scss';
import { CreativeBasicForm } from './SubSteps/CreativeBasicForm';
import { Form, Formik } from 'formik';
import { ErrorHandler } from 'components/common/form/FormErrorHandler/FormErrorHandler';
import { EditLimitation } from 'containers/Limitations/EditLimitation';
import AdLogoForm from 'components/AdLogoForm/AdLogoForm';
import { LIMITATION_TYPE } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';
import { CreativeType } from 'core/creative/Creative';

export class CreativeSetUpStep extends React.Component<CreativeSetupStepProps, CreativeSetupStepState> {

  handler: any;
  mainDomRef: RefObject<any>;

  constructor (props: any) {
    super(props);
    this.mainDomRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    this.scrollToErrorField();
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  scrollToErrorField () {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const child = node.querySelector('.errorTip');
      child && child.scrollIntoView({
        block: 'center'
      });
    }
  }

  render () {
    const model = this.props.model;
    return (
      <CreativeSetupFlowDataContext.Consumer>
        {(creativeContext) => {
          const creativeData = creativeContext.creative;
          const limitationModel = model.getLimitationModel(creativeData.limitations, creativeData.basic.advertiserId);
          const handleSubmit = (values) => {
            model.goNext(() => {
              creativeContext.setCreative({
                basic: values,
                limitations: limitationModel.limitationValue
              });
            });
          };
          const onSelect = tab => {
            if (tab === null) {
              return;
            }
            model.goSubStep(+tab);
          };
          const basicFormModel = model.getBasicFormModel(
            creativeData.basic,
            creativeContext.tenmaxCategories,
            creativeContext.supportedCreativeType,
            creativeContext.forPmp,
            (creativeType: CreativeType) =>
              creativeContext.getFormContentModelOfType(
                creativeType,
                creativeData.basic.advertiserId
              ),
            (...args) => {
              limitationModel.addLimitation(...args);
            }
          );

          return (
            <div className={styles.setupStep} ref={this.mainDomRef}>
              <Formik
                initialValues={basicFormModel.initCreative}
                enableReinitialize
                onSubmit={handleSubmit}
                validateOnBlur={false}
              >
                {formikProps => {
                  const onSubmitButtonClick = () => model.validate();
                  basicFormModel.setFormikProps(formikProps);
                  return (
                    <Form>
                      <ErrorHandler
                        parentRef={this.mainDomRef}
                        isSubmitting={formikProps.isSubmitting}
                        submitCount={formikProps.submitCount}
                      />
                      <Tabs
                        activeKey={model.activeTab}
                        id='creativeFromTab'
                        onSelect={onSelect}
                      >
                        <Tab eventKey={CreativeSetupTab.BASIC} title={i18n.t<string>('stepSideBar.labels.creativeBasic')}>
                          <CreativeBasicForm
                            model={basicFormModel}
                          />
                        </Tab>
                        <Tab eventKey={CreativeSetupTab.LIMITATION} title={i18n.t<string>('stepSideBar.labels.creativeLimitation')}>
                          <EditLimitation model={limitationModel} limitationType={LIMITATION_TYPE.CREATIVE} />
                        </Tab>
                        {creativeContext.enableAdLogo &&
                          <Tab eventKey={CreativeSetupTab.ADLOGO} title={i18n.t<string>('stepSideBar.labels.adLogo')}>
                            <AdLogoForm model={model.getAdLogoFormModel()} />
                          </Tab>
                        }
                      </Tabs>
                      <div className={styles.buttonArea}>
                        <Button
                          variant='primary'
                          size='sm'
                          type='submit'
                          onClick={onSubmitButtonClick}
                        >
                          {i18n.t<string>('campaign.buttons.completeAndCheck')}
                        </Button>
                        {model.goLast &&
                          <Button variant='secondary' size='sm' onClick={model.goLast}>
                            {i18n.t<string>('campaign.buttons.back')}
                          </Button>
                        }
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          );
        }}
      </CreativeSetupFlowDataContext.Consumer>
    );
  }
}
