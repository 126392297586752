import { FormikField } from 'components/common/form/field/FormikField';
import { LineMessageType } from 'core/advertiser/LineBeacon';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import styles from './outdoorFields.module.scss';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18n';
import { useCallback } from 'react';
import { renderErrors } from '../CreativeFormHintRenderFunction';
import { OutdoorLineMessageOperates } from './OutdoorLineMessageOperates';

const pushMessageTypeOptions = createSelectOptionsFromEnum(LineMessageType, 'outdoorLineMessageField.labels.messageTypes.');
const imageValidTypes = ['image/jpeg', 'image/jpg', 'image/png'];
const videoValidTypes = ['video/mp4'];
export const OutdoorLineMessageField = ({ fieldName, lineMessage, onTypeChange, removeField }) => {

  const lineMessageType = lineMessage.type;

  const validateImage = useCallback((fileData: ImageFieldData, ratioInfo?: { width: number, height: number }) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }
    const validTypes = imageValidTypes;
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      return i18n.t<string>('outdoorLineMessageField.hints.imageType');
    }

    const errors: string[] = [];
    if (file.size > 1048576) {
      errors.push(i18n.t<string>('outdoorLineMessageField.hints.storage', { storage: '1MB' }));
    }

    if (ratioInfo) {
      const width = fileData.width;
      const height = fileData.height;
      const proportion = (width / height).toFixed(2);
      const targetProportionStr = (ratioInfo.width / ratioInfo.height).toFixed(2);
      if (proportion !== targetProportionStr) {
        errors.push(i18n.t<string>('outdoorLineMessageField.hints.imageRatio', { ratio: `${ratioInfo.width}:${ratioInfo.height}` }));
      }
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }, []);

  const validateVideo = useCallback((fileData: MediaFieldData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }
    const validTypes = videoValidTypes;
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      return i18n.t<string>('outdoorLineMessageField.hints.videoType');
    }

    const errors: string[] = [];
    if (file.size > 10485760) {
      errors.push(i18n.t<string>('outdoorLineMessageField.hints.storage', { storage: '10MB' }));
    }

    if (fileData.duration > 60) {
      errors.push(i18n.t<string>('outdoorLineMessageField.hints.videoDuration', { second: '60' }));
    }

    const width = fileData.width;
    const height = fileData.height;
    const proportion = (width / height).toFixed(2);
    const targetProportion = (16 / 9).toFixed(2);
    if (proportion !== targetProportion) {
      errors.push(i18n.t<string>('outdoorLineMessageField.hints.videoRatio', { ratio: '16:9' }));
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }, []);

  const validateVideoPreview = useCallback((fileData: ImageFieldData) => {
    return validateImage(fileData, { width: 1, height: 1 });
  }
  , [validateImage]);

  const index = +fieldName.split('.')[1];

  return (
    <>
      <FormikField.Select
        label={i18n.t<string>('outdoorLineMessageField.labels.messageType')}
        name={`${fieldName}.type`}
        simpleValue={true}
        options={pushMessageTypeOptions}
        onChange={onTypeChange}
        postComponent={index > 0 ? <OutdoorLineMessageOperates id={lineMessage.id} remove={removeField} /> : undefined}
        postComponentStyle={styles.lineMessageOperates}
      />
      {lineMessageType === LineMessageType.TEXT && (
        <FormikField.TextArea
          label={i18n.t<string>('outdoorLineMessageField.labels.textMessage')}
          name={`${fieldName}.message`}
          className={styles.linePushMessage}
          validate={validateEmpty}
        />
      )}
      {lineMessageType === LineMessageType.IMAGE && (
        <FormikField.FileInput
          label={i18n.t<string>('outdoorLineMessageField.labels.imageMessage')}
          type='image'
          name={`${fieldName}.image`}
          className={styles.linePushMessage}
          hints={[
            i18n.t<string>('outdoorLineMessageField.hints.imageType'),
            i18n.t<string>('outdoorLineMessageField.hints.storage', { storage: '1MB' })
          ]}
          validTypes={['image/jpeg', 'image/png']}
          validate={validateImage}
        />
      )}
      {lineMessageType === LineMessageType.VIDEO && (
        <>
          <FormikField.FileInput
            label={i18n.t<string>(
              'outdoorLineMessageField.labels.videoMessage'
            )}
            type='video'
            name={`${fieldName}.video`}
            className={styles.linePushMessage}
            hints={[
              i18n.t<string>('outdoorLineMessageField.hints.videoType'),
              i18n.t<string>('outdoorLineMessageField.hints.storage', { storage: '10MB' }),
              i18n.t<string>('outdoorLineMessageField.hints.videoDuration', { second: '60' }),
              i18n.t<string>('outdoorLineMessageField.hints.videoRatio', { ratio: '16:9' })
            ]}
            validTypes={videoValidTypes}
            validate={validateVideo}
          />
          <FormikField.FileInput
            label={i18n.t<string>(
              'outdoorLineMessageField.labels.videoMessagePreview'
            )}
            type='image'
            name={`${fieldName}.previewImage`}
            className={styles.linePushMessage}
            hints={[
              i18n.t<string>('outdoorLineMessageField.hints.imageType'),
              i18n.t<string>('outdoorLineMessageField.hints.storage', { storage: '1MB' }),
              i18n.t<string>('outdoorLineMessageField.hints.imageRatio', { ratio: '1:1' })
            ]}
            validTypes={imageValidTypes}
            validate={validateVideoPreview}
          />
        </>
      )}
    </>
  );
};
