import { LineBeaconServiceType } from './LineBeacon';

export enum BeaconType {
  LINE_SIMPLE_BEACON = 'LINE_SIMPLE_BEACON'
}

export type TenMaxLineBeaconConnection = {
  id: number;
  name: string;
  beaconType: BeaconType.LINE_SIMPLE_BEACON;
  webhookType: LineBeaconServiceType.TENMAX;
  hardwareId: string;
  channelSecret: string;
  channelAccessToken: string;
};

export type SelfServeLineBeaconConnection = OverrideAndNeverOthers<
  TenMaxLineBeaconConnection,
  {
    id: number;
    name: string;
    beaconType: BeaconType.LINE_SIMPLE_BEACON;
    webhookType: LineBeaconServiceType.SELF_SERVE;
    hardwareId: string;
  }
>;

export type BeaconConnection = TenMaxLineBeaconConnection | SelfServeLineBeaconConnection;

export type BeaconConnectionFormData = {
  name: string;
  beaconType: BeaconType;
  webhookType: LineBeaconServiceType | '';
  hardwareId: string;
  channelSecret?: string;
  channelAccessToken?: string;
};
