import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { DefaultGoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { LimitationData, SavedTargeting, Limitation } from 'core/limitation/Limitation';
import { getPmax2RtbAgeGroupsByAgeRange } from 'core/limitation/l2ObjectTAOptions';
import { DefaultMessageSegmentManager } from 'core/messageSegment/MessageSegmentManager';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { OPERATE, OPERATES } from 'enum/Operate';
import i18n from 'i18next';
import { chain, compact, defaultTo, get, includes, isEmpty, keyBy, merge, remove, set } from 'lodash';
import {
  FireableUpdateEventListener,
  UpdateEventListener
} from 'utils/UpdateEventListener';
import { LimitationInventorySettings } from './LimitationSetting/limitationConfig/limitationSettingsType';
import { unicornkeywordName } from './LimitationSetting/limitationConfig/defaultLimitationInventorySettings';

export interface EditLimitationModel {
  readonly state: EditLimitationState;
  readonly event: UpdateEventListener<EditLimitationModel>;
  readonly limitationSetting: LimitationInventorySettings[];
  readonly limitationValue: LimitationData;
  readonly operationSet: {
    need: OPERATES[],
    notNeed: OPERATES[],
    other?: []
  };
  readonly addonFeature: string[];
  readonly estimateData: EstimatedAudience;
  readonly errors: {[key: string]: string};
  readonly taOptionsCache: {[key: string]: SelectOptions[]};
  readonly showGeneralFields: boolean;
  readonly supportAudienceEstimation: boolean;
  readonly channelData?: {
    channel: L1ObjectChannel,
    audienceLowestThreshold: number,
    accountId?: string,
    channelTargetingGetter?: (limitationValue: any) => any,
    estimatedRequiredFields?: string[],
    campaignDetailDataGetter?: () => any
  };
  showTAManagement?: () => void;
  validate: () => any;
  validateEstimatedData: () => any;
  onLimitationChange: () => void;
  setLimitationSetting: (limitationSetting: LimitationInventorySettings[]) => void;
  updateLimitationValue: (limitationValue: LimitationData) => void;
  setLimitationValue: (limitationValue: LimitationData) => void;
  getSummaryData?: (savedTA: SavedTargeting) => any;
  addLimitation: (operate: string, limitationType: string, label: string, value: string) => void;
  removeLimitation: (operate: string, limitationType: string, value: string) => void;
  init: () => Promise<void>;
  getRtbAudienceEstimatedData: () => Promise<void>;
  cleanTaOptionsCache: (name: string) => void;
  showInventory: (inventory?: string, operate?: string) => void;
  setSupportAudienceEstimation (supportAudienceEstimation: boolean): void;
  setOperationSet (operationSet: {
    need: OPERATES[],
    notNeed: OPERATES[],
    other?: []
  }): void;
  hideRequireIncludeLimitationInfo: () => void;
  validateRequireIncludeLimitation: (needUpdateState?: boolean) => void;
}

export type EditLimitationProps = {
  readonly model: EditLimitationModel;
};

export type EditLimitationState = {
  readonly errors: {[key: string]: string};
  readonly loading: boolean;
  readonly show?: string;
  readonly inventory: string;
  readonly fetchingEstimateData: boolean;
  readonly showRequireIncludeLimitationInfo?: boolean;
};

export class DefaultEditLimitationModel implements EditLimitationModel {
  event: FireableUpdateEventListener<EditLimitationModel>;
  errors: {[key: string]: string} = {};
  limitationValue: LimitationData = {
    include: [],
    exclude: [],
    preferred: [],
    nonPreferred: [],
    other: []
  };
  operationSet: {need: OPERATES[], notNeed: OPERATES[], other?: []};
  addonFeature: string[];
  fetchingEstimateData: boolean = false;
  showGeneralFields: boolean;
  channel: L1ObjectChannel;
  estimateData: EstimatedAudience = {
    estimateReady: false,
    upperBound: 0,
    lowerBound: 0
  };
  estimatedHint: string | undefined;
  taOptionsCache: {[key: string]: SelectOptions[]} = {};
  loading: boolean = false;
  show?: string;
  inventory: string = 'default';
  showRequireIncludeLimitationInfo?: boolean = false;

  constructor (
    public limitationSetting,
    limitationValue,
    operationSet,
    addonFeature: Array<string>,
    public showTAManagement?: () => void,
    public channelData?: {
      channel: L1ObjectChannel,
      audienceLowestThreshold: number,
      accountId?: string,
      channelTargetingGetter?: (limitationValue: any) => any,
      estimatedRequiredFields?: string[],
      campaignDetailDataGetter?: () => any
    },
    public supportAudienceEstimation: boolean = true,
    public requireIncludeLimitation: boolean = false,
    private goSegmentManager = new DefaultGoSegmentManager(),
    private rtbCampaignManager = new DefaultRtbCampaignManager(),
    private messageSegmentManager = new DefaultMessageSegmentManager()
  ) {
    this.event = new FireableUpdateEventListener<EditLimitationModel>();
    this.operationSet = operationSet;
    this.addonFeature = addonFeature;
    this.setLimitationValue(limitationValue);
    this.channel = get(channelData, 'channel', L1ObjectChannel.RTB);
    this.showGeneralFields = this.channel !== L1ObjectChannel.MESSAGE;
    this.estimatedHint = this.channel === L1ObjectChannel.MESSAGE
      ? i18n.t<string>('audienceInfo.labels.audienceEstimatedHint')
      : undefined;
    this.estimateData.estimatedHint = this.estimatedHint;
  }

  get state (): EditLimitationState {
    return {
      errors: this.errors,
      loading: this.loading,
      show: this.show,
      inventory: this.inventory,
      fetchingEstimateData: this.fetchingEstimateData,
      showRequireIncludeLimitationInfo: this.showRequireIncludeLimitationInfo
    };
  }

  showInventory = (inventory: string = 'default', operate?: string) => {
    this.inventory = inventory;
    if (includes(unicornkeywordName, inventory)) {
      this.inventory = 'unicornkeywords';
    }
    this.show = operate;
    this.updateState();
  }

  setLimitationSetting (limitationSetting: LimitationInventorySettings[]) {
    this.limitationSetting = limitationSetting;
    this.limitationSetting.forEach(setting => {
      delete this.taOptionsCache[setting.name];
    });
    this.setLimitationValue(this.limitationValue);
  }

  updateLimitationValue (limitationValue: LimitationData) {
    this.setLimitationValue(limitationValue);
    this.onLimitationChange();
  }

  initLimitationExtra = async (limitation) => {
    const setting = this.limitationSetting.find(setting => setting.name === limitation.type);
    if (!setting || !setting.needInitExtra) {
      return;
    }

    const hasGroup = limitation.value.some(value => value.isGroup);
    if (!hasGroup) {
      return;
    }
    const taOptions = this.taOptionsCache[setting.name] ? this.taOptionsCache[setting.name] : await setting.cb();
    this.taOptionsCache[setting.name] = taOptions;
    limitation.value.forEach(value => {
      if (!value.isGroup) {
        return;
      }
      const taOption = taOptions.find(option => option.value === value.value);
      if (taOption) {
        value.extra = taOption.extra;
      }
    });
  }

  initLimitationGroupExtra = async (limitationValue) => {
    this.updateState(true);
    const operates = Object.keys(limitationValue);
    for (const operate of operates) {
      const limitaionsOfOperate = defaultTo(this.limitationValue[operate], []);
      for (const limitation of limitaionsOfOperate) {
        await this.initLimitationExtra(limitation);
      }
    }
    this.updateState();
  }

  setOperationSet (operationSet) {
    this.operationSet = operationSet;
  }

  setSupportAudienceEstimation (supportAudienceEstimation: boolean) {
    this.supportAudienceEstimation = supportAudienceEstimation;
  }

  setLimitationValue (limitationValue: LimitationData) {
    if (!limitationValue) {
      return;
    }
    const result: LimitationData = {
      include: [],
      exclude: [],
      preferred: [],
      nonPreferred: [],
      other: []
    };
    const operates = Object.keys(limitationValue);
    const validOperates: string[] = chain(this.operationSet['need'] as string[])
      .concat(this.operationSet['notNeed'] as string[], this.operationSet['other'] ? 'other' : [] as string[])
      .flatten()
      .value();
    operates.forEach(operate => {
      if (!validOperates.includes(operate)) {
        return;
      }
      if (operate === 'other') {
        // dealId...
        result[operate] = [...limitationValue[operate]];
        return;
      }
      let validateTypes: string[] = this.limitationSetting
        .filter(setting => {
          if (setting.hideLimitation) {
            return true;
          }
          const addonEnabled =
            setting.ignoreAddonFeature ||
            this.addonFeature.includes(setting.addonFeature);

          const operateSupport =
            !!setting.supportOperates &&
            setting.supportOperates.includes(operate);
          return addonEnabled && operateSupport;
        })
        .map(setting => setting.name)
        .concat([
          'age_min',
          'age_max',
          'genders',
          'gender',
          ...unicornkeywordName
        ]);
      const limitationsToUpdate = limitationValue[operate]
        .filter(limitation => validateTypes.includes(limitation.type));
      const validLimitations = limitationsToUpdate;
      if (validLimitations.length > 0) {
        result[operate] = validLimitations;
      }
    });
    this.limitationValue = result;
  }

  getOp = (operate) => {
    switch (operate) {
      case 'include':
        return 'inc';
      case 'exclude':
        return 'exc';
      case 'preferred':
        return 'Preferred';
      case 'nonPreferred':
        return 'NonPreferred';
      default:
        return 'exc';
    }
  }

  addLimitation = (operate: string, limitationType: string, label: string, value: string) => {
    const newLimitation = {
      op: this.getOp(operate),
      type: limitationType,
      value: [{
        label: label,
        value: value
      }]
    };
    if (!this.limitationValue[operate]) {
      this.limitationValue[operate] = [newLimitation];
    } else {
      const limitation = this.limitationValue[operate].find(limitation => limitation.type === limitationType);
      if (limitation) {
        if (Array.isArray(limitation.value)) {
          const target = limitation.value.find(limit => {
            return limit.value === value;
          });

          if (!target) {
            limitation.value.push({
              label: label,
              value: value
            });
          }
        } else {
          limitation.value = value;
        }
      } else {
        this.limitationValue[operate].push(newLimitation);
      }
    }
    this.onLimitationChange();
  }

  removeLimitation = (operate: string, limitationType: string, value: string) => {
    if (!this.limitationValue[operate]) {
      return;
    }
    const limitation = this.limitationValue[operate].find(limitation => limitation.type === limitationType);
    if (!limitation) {
      return;
    }
    if (Array.isArray(limitation.value)) {
      remove(limitation.value, (limitationOption: SelectOptions) => limitationOption.value === value);
      if (limitation.value.length === 0) {
        remove(this.limitationValue[operate], (limitation: Limitation) => limitation.type === limitationType);
      }
    } else {
      remove(this.limitationValue[operate], (limitation: Limitation) => limitation.type === limitationType);
    }
    this.onLimitationChange();
  }

  cleanTaOptionsCache = (name: string) => {
    delete this.taOptionsCache[name];
    this.updateState();
  }

  findRepeated (type: string, operation: OPERATES, limitationValue: SelectOptions[] | number | string, allLimitation: LimitationData) {
    const ignoreTypes = [
      'unicornlanguage'
    ];
    if (ignoreTypes.includes(type)) {
      return [];
    }
    let repeatedValue: any[] = [];
    const includeLimitation = defaultTo(allLimitation.include, []);
    const preferredLimitation = defaultTo(allLimitation.preferred, []);
    const includeAgeMin = includeLimitation.find(limitation => limitation.type === 'age_min');
    const ageMinLimitation = includeAgeMin || preferredLimitation.find(limitation => limitation.type === 'age_min');
    const ageMin = get(ageMinLimitation, 'value');
    const includeAgeMax = includeLimitation.find(limitation => limitation.type === 'age_max');
    const ageMaxLimitation = includeAgeMax || preferredLimitation.find(limitation => limitation.type === 'age_max');
    const ageMax = get(ageMaxLimitation, 'value');
    const pmax2AgeValues = getPmax2RtbAgeGroupsByAgeRange(ageMin, ageMax);
    const ageOptValue = includeAgeMin ? 'include' : 'preferred';
    const values = Array.isArray(limitationValue) ? limitationValue.map(value => value.value) : [limitationValue];
    Object.keys(allLimitation).forEach(key => {
      if (key === operation) {
        return [];
      }
      const limitationsOfOperation: SelectOptions[] | number | string = get(keyBy(allLimitation[key], 'type')[type], 'value', []);
      const otherOperateValues = Array.isArray(limitationsOfOperation) ? limitationsOfOperation.map(limitation => limitation.value) : [limitationsOfOperation];
      repeatedValue.push(
        otherOperateValues.find(otherOperateValue => values.includes(otherOperateValue))
      );
      if (type === 'age' && operation !== ageOptValue) {
        repeatedValue.push(
          pmax2AgeValues.find(pmax2AgeValue => values.includes(pmax2AgeValue.value))
        );
      }
    });
    return compact(repeatedValue);
  }

  onLimitationChange = async () => {
    const errors = await this.validate();
    if (isEmpty(errors)) {
      this.getAudienceEstimatedData();
      this.updateState();
    }
  }

  validateLimitationValue = async (setting, limitation) => {
    if (setting.ignoreValidateOption) {
      return undefined;
    }
    try {
      const taOptions = this.taOptionsCache[setting.name] ? this.taOptionsCache[setting.name] : await setting.cb();
      this.taOptionsCache[setting.name] = taOptions;
      const validValue = chain(taOptions)
        .concat(taOptions.map(value => value.options ? value.options : []))
        .flatten()
        .map(value => value.value.toString())
        .value();
      const invalidValue = limitation.value.filter(value => !validValue.includes(value.value.toString()));
      return invalidValue.length > 0 ? i18n.t<string>('editLimitation.errors.someSelectedOptionInvalid', { name: i18n.t<string>(setting.title).toLowerCase() }) : undefined;
    } catch (e) {
      return undefined;
    }
  }

  validateRequiredLimitation = (operate, limitaionsOfOperate) => {
    const requiredSettings = this.limitationSetting.filter(setting => {
      if (setting.requiredOperate && setting.requiredOperate.includes(operate)) {
        return true;
      }
      if (setting.showWithLimitation) {
        return limitaionsOfOperate.find(limitation => setting.showWithLimitation.includes(limitation.type));
      }
      return false;
    });

    let errors = {};
    if (requiredSettings.length > 0) {
      requiredSettings.forEach(setting => {
        const limitation = limitaionsOfOperate.find(limitation => setting.name === limitation.type);
        if (!limitation || limitation.value.length === 0) {
          set(errors, `${operate}.${setting.name}`, i18n.t<string>('formValidate.labels.emptyError'));
        }
      });
    }
    return errors;
  }

  validateLimitation = async (operate: OPERATES, limitation: Limitation) => {
    const errors = {};
    const setting = this.limitationSetting.find(setting => setting.name === limitation.type);
    if (setting) {
      const {
        validator,
        cb
      } = setting;
      if (validator) {
        const finalValidator = typeof validator === 'function' ? setting.validator : setting.validator[operate];
        const error = finalValidator ? finalValidator(operate, limitation.value, this.limitationValue) : undefined;
        error && set(errors, `${operate}.${limitation.type}`, error);
      }
      if (cb) {
        const error = await this.validateLimitationValue(setting, limitation);
        error && set(errors, `${operate}.${limitation.type}`, error);
      }
    }
    const repeatValue = this.findRepeated(limitation.type, operate, limitation.value, this.limitationValue);
    repeatValue.length > 0 && set(errors, `${operate}.${limitation.type}`, i18n.t<string>('editLimitation.errors.repeat'));
    return errors;
  }

  validateEstimatedData = async () => {
    this.updateState(true);
    const errors = {};
    if (this.channel === L1ObjectChannel.MESSAGE) {
      await this.getRtbAudienceEstimatedData();
      const threshold = get(this.channelData, 'audienceLowestThreshold', 0);
      const error = this.estimateData.lowerBound < threshold ? i18n.t<string>('editLimitation.errors.audienceLowestThreshold') : undefined;
      error && set(errors, 'audienceEstimatedError', error);
    }
    merge(this.errors, errors);
    this.updateState();
    return errors;
  }

  validateRequireIncludeLimitation = (needUpdateState?: boolean) => {
    if (this.operationSet.need.includes(OPERATE.INCLUDE) && this.requireIncludeLimitation) {
      const includeLimitation = defaultTo(this.limitationValue[OPERATE.INCLUDE], []);
      const hasIncludeLimitation = this.limitationSetting.some(setting => {
        return includeLimitation.find(limitation => !setting.hideLimitation && limitation.type === setting.name) !== undefined;
      });
      this.showRequireIncludeLimitationInfo = !hasIncludeLimitation;
    }
    needUpdateState && this.updateState();
  }

  validate = async () => {
    this.updateState(true);
    const errors = {};
    if (!this.limitationValue) {
      return errors;
    }
    const operates = Object.values(OPERATE);
    for (const operate of operates) {
      const limitaionsOfOperate = defaultTo(this.limitationValue[operate], []);
      for (const limitation of limitaionsOfOperate) {
        const limitationErrors = await this.validateLimitation(operate, limitation);
        merge(errors, limitationErrors);
      }
      const requiredErrors = this.validateRequiredLimitation(operate, limitaionsOfOperate);
      merge(errors, requiredErrors);
    }
    this.errors = errors;
    this.validateRequireIncludeLimitation();
    this.updateState();
    return errors;
  }

  getRtbAudienceEstimatedData = async () => {
    if (!this.channelData) {
      return;
    }

    const { campaignDetailDataGetter, channelTargetingGetter } = this.channelData;

    this.estimateData = {
      estimateReady: false,
      upperBound: -1,
      lowerBound: 0,
      estimatedHint: this.estimatedHint
    };

    const channelTargeting = channelTargetingGetter ?
      channelTargetingGetter(this.limitationValue) :
      this.limitationValue;
    try {
      if (this.channel === L1ObjectChannel.MESSAGE) {
        this.fetchingEstimateData = true;
        this.updateState();
        const estimateData = await this.messageSegmentManager.getSegmentCount(channelTargeting);
        if (estimateData) {
          this.estimateData = {
            estimateReady: true,
            upperBound: -1,
            lowerBound: estimateData.num_phones,
            estimatedHint: this.estimatedHint
          };
        } else {
          this.estimateData.warning = i18n.t<string>('campaignSummary.labels.limitationNotEstimateDesc');
          this.estimateData.estimatedHint = this.estimatedHint;
        }
      } else {
        if (!campaignDetailDataGetter) {
          return;
        }

        this.fetchingEstimateData = true;
        this.updateState();
        const { campaign, order } = campaignDetailDataGetter();
        const estimateData = await this.rtbCampaignManager.getLegacyEstimateData(campaign,channelTargeting, order.campaignBidPrice);
        if (estimateData) {
          this.estimateData = {
            estimateReady: true,
            upperBound: -1,
            lowerBound: estimateData.uniqueUser
          };
        } else {
          this.estimateData.warning = i18n.t<string>('campaignSummary.labels.limitationNotEstimateDesc');
        }
      }
    } catch (e) {}
    this.fetchingEstimateData = false;
    this.updateState();
  }

  init = async () => {
    await this.getAudienceEstimatedData();
    await this.initLimitationGroupExtra(this.limitationValue);
  }

  getAudienceEstimatedData = async () => {
    if (!this.channelData) {
      return;
    }
    const { channel, channelTargetingGetter, accountId, estimatedRequiredFields } = this.channelData;
    const channelTargeting = channelTargetingGetter ?
      channelTargetingGetter(this.limitationValue) :
      this.limitationValue;
    const currentTargetingNames = Object.keys(channelTargeting);
    if (estimatedRequiredFields && estimatedRequiredFields.some(required => !currentTargetingNames.includes(required))) {
      return;
    }

    const isTenMaxChannel = Object.values(L1ObjectChannel)
      .filter(channel => ![L1ObjectChannel.FB].includes(channel));
    if (isTenMaxChannel) {
      return;
    }

    this.fetchingEstimateData = true;
    try {
      this.estimateData = await this.goSegmentManager.getGoSegmentEstimate(
        channel,
        channelTargeting,
        accountId
      );
    } catch (e) {
      this.estimateData = {
        estimateReady: false,
        upperBound: 0,
        lowerBound: 0
      };
    }
    this.fetchingEstimateData = false;
    this.updateState();
  }

  setAudienceEstimatedData (estimateData) {
    if (!estimateData) {
      return;
    }
    this.estimateData = estimateData;
    this.updateState();
  }

  hideRequireIncludeLimitationInfo = () => {
    this.showRequireIncludeLimitationInfo = false;
    this.updateState();
  }

  updateState (loading = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
