import React, { useEffect, useState } from 'react';
import 'rc-slider/assets/index.css';
import { FormConfig, FormField } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';
import { useVideoOutdoorFields } from './hooks/useVideoOutdoorFields';
import { useImageAudioOutdoorFields } from './hooks/useImageAudioOutdoorFields';
import { useVideoImageOutdoorFields } from './hooks/useVideoImageOutdoorFields';
import { useHtmlOutdoorFields } from './hooks/useHtmlOutdoorFields';
import { useOutdoorBasicFields } from './hooks/useOutdoorBasicFields';
import { OutdoorFormModel, OutdoorFormProps } from './OutdoorFormModel';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { FormikFileInputField } from 'components/common/form/field/FileInputField';
import styles from './outdoorForm.module.scss';
import config from 'config';
import { validateEmpty } from 'utils/ValidateUtils';
import { Currency } from 'core';
import { useOutdoorLineFields } from './hooks/useOutdoorLineFields';

export const OutdoorForm = <T extends OutdoorFormModel<T>>(props: OutdoorFormProps<T>) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(
    props.getInitFormConfig()
  );

  const outdoorBasicFields = useOutdoorBasicFields(props.model);
  const videoFormFields = useVideoOutdoorFields(props.model);
  const imageAudioFormFields = useImageAudioOutdoorFields(props.model);
  const videoImageFormFields = useVideoImageOutdoorFields(props.model);
  const htmlFormFields = useHtmlOutdoorFields(props.model);
  const beaconFormFields = useOutdoorLineFields(props.model.beaconConnectionOptions);

  useEffect(() => {
    const orderInfoFields = new FormConfig.FieldsBuilder()
      .addFormikInput({
        name: 'typeProperties.orderName',
        label: i18n.t<string>('outdoorForm.labels.orderName'),
        hint: i18n.t<string>('outdoorForm.hints.orderName'),
        validate: validateEmpty
      })
      .addFormikInputGroup({
        name: 'typeProperties.orderBudget',
        label: i18n.t<string>('outdoorForm.labels.orderBudget'),
        hint: i18n.t<string>('outdoorForm.hints.orderBudget'),
        type: 'number',
        prefix: Currency.NTD,
        validate: validateEmpty
      })
      .addFormikDateRangePicker({
        label: i18n.t<string>('outdoorForm.labels.orderSchedule'),
        name: 'orderSchedule',
        startDateFormikName: 'typeProperties.orderStartDate',
        endDateFormikName: 'typeProperties.orderEndDate',
        format: 'YYYY-MM-DD',
        showTimePicker: false,
        hint: i18n.t<string>('outdoorForm.hints.orderSchedule'),
        validate: (startDate: string, endDate: string) => {
          return validateEmpty(startDate) || validateEmpty(endDate);
        }
      })
      .build();

    const selfCheckDocumentField: FormField = {
      component: FormikFileInputField,
      props: {
        type: 'other',
        label: i18n.t<string>('outdoorForm.labels.selfCheckDocument'),
        name: 'medias.selfCheckDocument',
        validTypes: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        hints: [
          i18n.t<string>('outdoorForm.hints.selfCheckFileType'),
          i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
        ],
        permanentHint: (
          <span className={styles.hint}>
            <Trans i18nKey='outdoorForm.hints.selfCheckFileExample'>
              ...<a href={`https://storage.googleapis.com/${config.cloudStorageConfig.bucket}/adSelfCheck.docx`} download>Click here</a>...
            </Trans>
          </span>
        ),
        validate: props.model.validateDocument
      },
      hide: false
    };

    setFormConfig(
      new FormConfig.Builder()
        .addSection(
          new FormConfig.SectionBuilder([
            ...props.basicFields,
            ...(props.model.needSelfCheckDocument ? orderInfoFields : []),
            ...outdoorBasicFields,
            ...videoFormFields,
            ...imageAudioFormFields,
            ...videoImageFormFields,
            ...htmlFormFields,
            ...(props.model.needSelfCheckDocument ? [selfCheckDocumentField] : []),
            ...beaconFormFields
          ])
          .withTitle(
            i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo')
          )
          .build()
        )
        .build()
    );
  }, [
    props.basicFields,
    outdoorBasicFields,
    videoFormFields,
    imageAudioFormFields,
    videoImageFormFields,
    htmlFormFields,
    beaconFormFields,
    props.model.needSelfCheckDocument,
    props.model.validateDocument
  ]);

  return (
    <FormContent formConfig={formConfig} />
  );
};
