import { CompanyMember } from 'core/companyMember/CompanyMember';
import { ChannelGroupDTO, ChannelGroupFormData, ChannelGroupListDTO } from './ChannelGroup';
import { ChannelGroupWebService, RestfulChannelGroupWebService } from 'ws/ChannelGroupWebService';

export interface ChannelGroupManager {
  getChannelGroups (): Promise<ChannelGroupListDTO[]>;
  getChannelGroup (id: number): Promise<ChannelGroupDTO>;
  editChannelGroup (id: number, formData: ChannelGroupFormData): Promise<void>;
  addChannelGroupMember (id: number, email: string, roleName: string): Promise<void>;
  deleteChannelGroupMember (id: number, email: string, roleName: string): Promise<void>;
  getChannelGroupMembers (id: number): Promise<CompanyMember[]>;
}

export class DefaultChannelGroupManager implements ChannelGroupManager {

  constructor (
    private webService: ChannelGroupWebService = new RestfulChannelGroupWebService()
  ) {}

  async getChannelGroups () {
    return this.webService.getChannelGroups();
  }

  async getChannelGroup (id: number) {
    return this.webService.getChannelGroup(id);
  }

  async editChannelGroup (id: number, formData: ChannelGroupFormData) {
    await this.webService.editChannelGroup(id, formData);
  }

  async addChannelGroupMember (id: number, email: string, roleName: string): Promise<void> {
    await this.webService.addChannelGroupMember(id, email, roleName);
  }

  async deleteChannelGroupMember (id: number, email: string, roleName: string): Promise<void> {
    await this.webService.deleteChannelGroupMember(id, email, roleName);
  }

  async getChannelGroupMembers (id: number) {
    return this.webService.getChannelGroupMembers(id);
  }
}
