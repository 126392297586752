import { Page } from 'components/Page/Page';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import {
  ChannelGroupTabs,
  useChannelGroupFormModel
} from './ChannelGroupFormModel';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './channelGroupForm.module.scss';
import { useCallback } from 'react';
import { Form, FormContent } from 'components/common/form/Form';
import { Formik, FormikProps } from 'formik';
import { ChannelGroupFormData, ChannelGroupListDTO } from 'core/channelGroup/ChannelGroup';

type ChannelGroupDetailProps = {
  channelGroups: ChannelGroupListDTO[];
};

export const ChannelGroupForm: React.FC<ChannelGroupDetailProps> = ({
  channelGroups
}) => {

  const {
    loading,
    title,
    tab,
    redirectPath,
    initChannelGroup,
    breadcrumbsRoutes,
    basicFormConfig,
    specialSettingsFormConfig,
    reportFormConfig,
    bidPriceFormConfig,
    onTabChange,
    submit,
    cancel
  } = useChannelGroupFormModel(channelGroups);

  const renderBtns = useCallback(
    () => (
      <>
        <Button variant='primary' size='sm' type='submit'>
          {i18n.t<string>('common.buttons.submit')}
        </Button>
        <Button variant='secondary' size='sm' onClick={cancel}>
          {i18n.t<string>('common.buttons.cancel')}
        </Button>
      </>
    ),
    [cancel]
  );

  const renderForm = useCallback(
    (formikProps: FormikProps<ChannelGroupFormData>) => {
      return (
        <Form
          formikProps={formikProps}
          renderFormBtns={renderBtns}
          withNavigationPrompt
        >
          <Tabs
            id='reportDownloadPanelTab'
            defaultActiveKey={ChannelGroupTabs.BASIC}
            activeKey={tab}
            onSelect={onTabChange}
          >
            <Tab
              eventKey={ChannelGroupTabs.BASIC}
              title={i18n.t<string>('channelGroups.titles.basicTab')}
            >
              <FormContent formConfig={basicFormConfig} />
            </Tab>
            <Tab
              eventKey={ChannelGroupTabs.REPORT}
              title={i18n.t<string>('channelGroups.titles.reportTab')}
            >
              <FormContent formConfig={reportFormConfig} />
            </Tab>
            <Tab
              eventKey={ChannelGroupTabs.BID_PRICE}
              title={i18n.t<string>('channelGroups.titles.bidPriceTab')}
            >
              <FormContent formConfig={bidPriceFormConfig} />
            </Tab>
            <Tab
              eventKey={ChannelGroupTabs.SPECIAL_SETTINGS}
              title={i18n.t<string>('channelGroups.titles.specialSettingsTab')}
            >
              <FormContent formConfig={specialSettingsFormConfig} />
            </Tab>
          </Tabs>
        </Form>
      );
    },
    [tab, basicFormConfig, specialSettingsFormConfig, reportFormConfig, bidPriceFormConfig, onTabChange, renderBtns]
  );

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (!initChannelGroup) {
    return <LoadingIndicator />;
  }

  return (
    <div style={{ height: '100%' }}>
      {loading && <LoadingIndicator />}
      <Page
        title={title}
        breadcrumbsRoutes={breadcrumbsRoutes}
        spaceBetweenTopAreaAndContent={false}
        topAreaEndWithShadow
      >
        <div className={styles.channelGroupForm}>
          <Formik
            initialValues={initChannelGroup}
            onSubmit={submit}
            validateOnBlur={false}
          >
            {renderForm}
          </Formik>
        </div>
      </Page>
    </div>
  );
};
