import { FormConfig } from 'components/common/form/FormConfig';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import config from 'config';
import { LineBeaconServiceType } from 'core/advertiser/LineBeacon';

export const useLineOASettingFields = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const webhookType = get(
    values,
    'webhookType'
  );
  const lineBeaconHardwareId = get(values, 'hardwareId');
  const lineBotChannelSecret = get(values, 'channelSecret');
  const lineBotChannelAccessToken = get(
    values,
    'channelAccessToken'
  );

  const generateWebhook = useCallback(() => {
    const tenmaxLineDomain = config.tenmaxLineDomain;
    const url = `${tenmaxLineDomain}/webhook?hwid=${lineBeaconHardwareId}`;
    navigator.clipboard.writeText(url);
    toast.success(i18n.t<string>('lineFields.hints.generateWebhookSuccess'));
  }, [lineBeaconHardwareId]);

  const generateBeaconAPIUrl = useCallback(() => {
    const tenmaxLineDomain = config.tenmaxLineDomain;
    const url = `${tenmaxLineDomain}/api/beacon?hwid=${lineBeaconHardwareId}`;
    navigator.clipboard.writeText(url);
    toast.success(i18n.t<string>('lineFields.hints.generateBeaconUrlSuccess'));
  }, [lineBeaconHardwareId]);

  const fields = useMemo(() => {
    const builder = new FormConfig.FieldsBuilder();
    builder
      .addFormikSelect({
        label: i18n.t<string>('lineFields.labels.webhookType'),
        name: 'webhookType',
        simpleValue: true,
        isClearable: true,
        options: [
          { label: i18n.t<string>('lineFields.labels.tenmaxSupport'), value: LineBeaconServiceType.TENMAX },
          { label: i18n.t<string>('lineFields.labels.advertiserSelfServe'), value: LineBeaconServiceType.SELF_SERVE }
        ],
        onChange: (type: LineBeaconServiceType) => {
          const defaultLineBeaconHardwareId = !type ? undefined : lineBeaconHardwareId;
          const defaultLineBotChannelSecret = type === LineBeaconServiceType.TENMAX ? '' : undefined;
          const defaultLineBotChannelAccessToken = type === LineBeaconServiceType.TENMAX ? '' : undefined;
          setFieldValue('hardwareId', defaultLineBeaconHardwareId);
          setFieldValue('channelSecret', defaultLineBotChannelSecret);
          setFieldValue('channelAccessToken', defaultLineBotChannelAccessToken);
          setFieldTouched('hardwareId', false);
          setFieldTouched('channelSecret', false);
          setFieldTouched('channelAccessToken', false);
        }
      });

    if (!webhookType) {
      return builder.build();
    }

    builder.addFormikInput({
      label: i18n.t<string>('lineFields.labels.hardwareId'),
      name: 'hardwareId',
      placeholder: 'Enter Line Beacon Hardware ID'
    });

    const isTenMaxSupport = webhookType === LineBeaconServiceType.TENMAX;
    const canGenerateWebhook = isTenMaxSupport
      ? !isEmpty(lineBeaconHardwareId) &&
        !isEmpty(lineBotChannelSecret) &&
        !isEmpty(lineBotChannelAccessToken)
      : !isEmpty(lineBeaconHardwareId) &&
        !isEmpty(webhookType);
    if (isTenMaxSupport) {
      builder
        .addFormikInput({
          label: i18n.t<string>('lineFields.labels.channelSecret'),
          name: 'channelSecret',
          placeholder: 'Enter Line Bot Channel Secret'
        })
        .addFormikInput({
          label: i18n.t<string>('lineFields.labels.channelAccessToken'),
          name: 'channelAccessToken',
          placeholder: 'Enter Line Bot Channel Access Token'
        })
        .addCustom({
          name: 'generateUrlBtn',
          label: i18n.t<string>('lineFields.labels.generateWebhook'),
          render: () => (
            <Button
              variant='secondary'
              disabled={!canGenerateWebhook}
              onClick={generateWebhook}
              style={{ height: '35px' }}
            >
              {i18n.t<string>('lineFields.buttons.generateWebhook')}
            </Button>
          )
        });
    } else {
      builder.addCustom({
        name: 'generateUrlBtn',
        label: i18n.t<string>('lineFields.labels.generateBeaconAPIUrl'),
        render: () => (
          <Button
            variant='secondary'
            disabled={!canGenerateWebhook}
            onClick={generateBeaconAPIUrl}
            style={{ height: '35px' }}
          >
            {i18n.t<string>('lineFields.buttons.generateBeaconAPIUrl')}
          </Button>
        )
      });
    }

    return builder.build();
  }, [
    lineBeaconHardwareId,
    webhookType,
    lineBotChannelSecret,
    lineBotChannelAccessToken,
    generateWebhook,
    generateBeaconAPIUrl,
    setFieldValue,
    setFieldTouched
  ]);

  return fields;
};
