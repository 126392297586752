import { FBCampaign, FbObjective } from 'core/fbCampaign/FbCampaign';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { RtbObjective } from 'core/rtbCampaignGroup/RtbCampaignGroup';

export type L1Object = {
  l1ObjectId: number;
  name: string;
  adsOrderId: number;
  state: L1ObjectState;
  budget?: number | string | null;
  dailyBudget?: number | string | null;
  channel: L1ObjectChannel;
  autoOptimise: boolean;
  budgetBalance: number;
  currencyRate: number;
  objective: L1ObjectObjective;
  fb?: FBCampaign;
  rtb?: any;
  isSelfServe: boolean;
  l2ObjectMinStartDate?: string;
  l2ObjectMaxEndDate?: string;
  l2ObjectRunningStatus: {
    [key: string]: number | string | boolean;
  }
  actionable: boolean;
};

export enum L2ObjectRunningStatus {
  AHEAD = 'ahead',
  BEHIND = 'behind',
  BUDGET_NOT_ALLOCATED = 'budgetNotAllocated',
  BUDGET_REMAIN = 'budgetRemain',
  END = 'end',
  INACTIVE = 'inactive',
  NO_CREATIVES = 'noCreatives',
  NORMAL = 'normal',
  NOT_START = 'notStart',
  SEVERELY_BEHIND = 'severelyBehind',
  ACTUAL_PROGRESS = 'actualProgress',
  EXPECTED_PROGRESS = 'expectedProgress',
  BUDGET = 'budget',
  SPENT = 'spent',
  DRAFT = 'draft', // draft in message campaign
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
  ABANDON = 'abandon',
  ABNORMAL = 'abnormal',
  FINISHED = 'finished'
}

export enum L1ObjectChannel {
  RTB = 'RTB',
  FB = 'FB',
  RETAIL_MEDIA = 'RETAIL_MEDIA',
  EDIMAX = 'EDIMAX',
  PIC = 'PIC',
  MESSAGE = 'MESSAGE',
  HAMI_VIDEO = 'HAMI_VIDEO',
  POYA = 'POYA'
}

export enum L1ObjectState {
  ACTIVE = 1,
  DEACTIVATE = 2,
  DELETE = 99
}

// enum AwarenessObjective {
//   REACH = 'REACH'
//   // BRAND_AWARENESS = 'BRAND_AWARENESS'
// }

// enum ConsiderationObjective {
//   LINK_CLICKS = 'LINK_CLICKS'
//   // Engagement
//   // POST_ENGAGEMENT = 'POST_ENGAGEMENT', // Post engagement
//   // PAGE_LIKES = 'PAGE_LIKES', // Page likes
//   // EVENT_RESPONSES = 'EVENT_RESPONSES', // Event responses
//   // //
//   // APP_INSTALLS = 'APP_INSTALLS', // App installs
//   // VIDEO_VIEWS = 'VIDEO_VIEWS', // Video views
//   // LEAD_GENERATION = 'LEAD_GENERATION', // Lead generation
//   // MESSAGES = 'MESSAGES' // Messages
// }

// enum ConversionObjective {
//   CONVERSIONS = 'CONVERSIONS'
//   // PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES', // Catalog sales
//   // STORE_VISITS = 'STORE_VISITS' // Store traffic
// }

// export const L1ObjectObjective = {
//   AwarenessObjective,
//   ConsiderationObjective,
//   ConversionObjective
//   // LOCAL_AWARENESS = 'LOCAL_AWARENESS',
//   // OFFER_CLAIMS = 'OFFER_CLAIMS',
// };

export enum L1ObjectObjective {
  AWARENESS = 'AWARENESS',
  TRAFFIC = 'TRAFFIC',
  SALES = 'SALES',
  UNSPECIFIED = 'UNSPECIFIED'
}

export const CHANNEL_OBJECTIVE_MAP = {
  [L1ObjectChannel.FB]: {
    [FbObjective.REACH]: L1ObjectObjective.AWARENESS,
    [FbObjective.OUTCOME_AWARENESS]: L1ObjectObjective.AWARENESS,
    [FbObjective.LINK_CLICKS]: L1ObjectObjective.TRAFFIC,
    [FbObjective.OUTCOME_TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [FbObjective.CONVERSIONS]: L1ObjectObjective.SALES,
    [FbObjective.OUTCOME_SALES]: L1ObjectObjective.SALES
  },
  [L1ObjectChannel.RTB]: {
    [RtbObjective.REACH]: L1ObjectObjective.AWARENESS,
    [RtbObjective.LINK_CLICKS]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS,
    [RtbObjective.TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.SALES]: L1ObjectObjective.SALES
  },
  [L1ObjectChannel.RETAIL_MEDIA]: {
    [RtbObjective.REACH]: L1ObjectObjective.AWARENESS,
    [RtbObjective.LINK_CLICKS]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS,
    [RtbObjective.TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.SALES]: L1ObjectObjective.SALES
  },
  [L1ObjectChannel.EDIMAX]: {
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS
  },
  [L1ObjectChannel.PIC]: {
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS
  },
  [L1ObjectChannel.POYA]: {
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS
  },
  [L1ObjectChannel.MESSAGE]: {
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS
  },
  [L1ObjectChannel.HAMI_VIDEO]: {
    [RtbObjective.REACH]: L1ObjectObjective.AWARENESS,
    [RtbObjective.LINK_CLICKS]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS,
    [RtbObjective.TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.SALES]: L1ObjectObjective.SALES
  }
} as const;

export const GROUP_CHANNEL_CAMPAIGN_TYPE_MAP = {
  [L1ObjectChannel.RTB]: [
    AdType.DISPLAY,
    AdType.VIDEO,
    AdType.COMBO,
    AdType.THIRD_PARTY,
    AdType.THIRD_PARTY_BOTTOM,
    AdType.THIRD_PARTY_RECTANGLE,
    AdType.UNKNOW,
    AdType.ONE_FOR_ALL_DISPLAY,
    AdType.PILOT_TV,
    AdType.CTV,
    AdType.SANDBOX_OUTDOOR
  ],
  [L1ObjectChannel.RETAIL_MEDIA]: [AdType.RETAIL],
  [L1ObjectChannel.EDIMAX]: [AdType.EDIMAX],
  [L1ObjectChannel.PIC]: [
    AdType.PIC_SHORT,
    AdType.PIC_LONG,
    AdType.PIC_EXTENDED
  ],
  [L1ObjectChannel.HAMI_VIDEO]: [
    AdType.HAMI_VIDEO_DISPLAY,
    AdType.HAMI_VIDEO_VIDEO,
    AdType.HAMI_VIDEO_COMBO,
    AdType.HAMI_VIDEO_CONNECTED_TV_IMAGE,
    AdType.HAMI_VIDEO_THIRD_PARTY_RECTANGLE
  ],
  [L1ObjectChannel.MESSAGE]: [AdType.MESSAGE],
  [L1ObjectChannel.POYA]: [
    AdType.POYA_SHORT,
    AdType.POYA_LONG,
    AdType.POYA_EXTENDED
  ]
};
