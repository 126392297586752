import _ from 'lodash';
import client from './RestClient';
import {
  RtbCampaign,
  CheckPoint,
  VideoProgressTrackingCode,
  RTBCAMPAIGN_DEFAULT_AGE_MIN,
  RTBCAMPAIGN_DEFAULT_AGE_MAX,
  RtbOptimize,
  RtbLegacyOptimize,
  AdditionalInfoConversionTracking,
  TrackEvent,
  VideoAdViewObjective,
  VideoAdViewObjectiveType,
  VideoAdMetricEvent,
  RtbCampaignListBasic,
  RtbCampaignBasic,
  SpaceDevice
} from 'core/rtbCampaign/RtbCampaign';
import { wrapLimitationsFromServer } from 'utils/LimitationUtil';
import { isAdTypeSupportOutdoorDevice } from 'utils/CampaignUtil';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { LegacyEstimateData } from 'core/rtbCampaign/LegacyEstimateData';
import { AxiosInstance } from 'axios';
import { Dayparts } from 'core/dayparts/Dayparts';

export type CreateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export type UpdateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export type LegacyEstimateDataPayLoad = {
  readonly dayPart?: Omit<Dayparts, 'enabled'>;
  readonly optimize: RtbLegacyOptimize;
  readonly orderId: number;
  readonly campaignId?: number;
  readonly bidPrice: number;
  readonly limitation: { op: string, limits: { value: string, label: string }[], type: string }[];
};

export interface RtbCampaignWebService {
  getCampaign (campaignId: number): Promise<RtbCampaign>;
  getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignListBasic[]>;
  createCampaign (body: any, l1ObjectId: number | string): Promise<void>;
  createCampaignWithCreative (body: any, l1ObjectId: number | string): Promise<number>;
  updateCampaign (body: any, l1ObjectId: number | string): Promise<void>;
  splitCampaign (body: any, campaignId, l1ObjectId: number | string): Promise<void>;
  updateCampaignState (campaignData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  getCampaignOptions (from?: string, to?: string): Promise<Array<SelectOptions>>;
  getOrderNumber (campaignId: string | number): Promise<string>;
  getLegacyEstimateData (payload: LegacyEstimateDataPayLoad): Promise<LegacyEstimateData>;
  updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void>;
  getRetailOptions (): Promise<SelectOptions[]>;
  getRtbCampaignsUsingDailyBudget (l1ObjectId: number | string): Promise<RtbCampaignListBasic[]>;
  getAdTypeOptions (): Promise<SelectOptions[]>;
  getOutdoorSpacesDetail (spaceIds: string[]): Promise<SpaceDevice[]>;
}

function wrapCheckPoint (json: any): CheckPoint {
  return {
    target: _.get(json, 'target'),
    time: _.get(json, 'time'),
    utime: _.get(json, 'utime')
  };
}

function wrapTags (tags?: string | string[]): string[] {
  if (!tags) {
    return [];
  }
  if (Array.isArray(tags)) {
    return tags;
  }

  return tags.split(',');
}

const optimizeMap = {
  [RtbOptimize.REACH]: L2ObjectOptimizationGoal.REACH,
  [RtbOptimize.VIDEO_VIEWS]: L2ObjectOptimizationGoal.VIDEO_VIEWS,
  [RtbOptimize.VIDEO_VIEWS_REACH]: L2ObjectOptimizationGoal.VIDEO_VIEWS_REACH,
  [RtbOptimize.IMPRESSIONS]: L2ObjectOptimizationGoal.IMPRESSIONS,
  [RtbOptimize.LINK_CLICKS]: L2ObjectOptimizationGoal.CLICKS,
  [RtbOptimize.CLICKS]: L2ObjectOptimizationGoal.CLICKS,
  [RtbOptimize.OFFSITE_CONVERSIONS]: L2ObjectOptimizationGoal.SALES,
  [RtbOptimize.SALES]: L2ObjectOptimizationGoal.SALES,
  [RtbLegacyOptimize.CPC]: L2ObjectOptimizationGoal.CLICKS,
  [RtbLegacyOptimize.CPM]: L2ObjectOptimizationGoal.IMPRESSIONS,
  [RtbLegacyOptimize.CPV]: L2ObjectOptimizationGoal.VIDEO_VIEWS,
  [RtbLegacyOptimize.VCPM]: L2ObjectOptimizationGoal.VIDEO_VIEWS
};

function wrapBasic (json: any): RtbCampaignBasic {
  const orderPrice = _.get(json, 'orderPrice');
  let pmax3OptimizationGoal = optimizeMap[json.optimize];
  if (json.optimize === RtbLegacyOptimize.CPM && !_.isEmpty(json.frequency)) {
    pmax3OptimizationGoal = L2ObjectOptimizationGoal.REACH;
  }
  return {
    id: _.get(json, 'campaignId', _.get(json, 'goganCampaignId')),
    name: _.get(json, 'name'),
    advertiserId: _.get(json, 'advertiserId'),
    orderId: _.get(json, 'orderId'),
    budget: _.get(json, 'budget', 0),
    startDate: _.get(json, 'startDate'),
    endDate: _.get(json, 'endDate'),
    createDate: _.get(json, 'createDate'),
    priceModel: _.get(json, 'priceModel'),
    orderPrice,
    optimize: pmax3OptimizationGoal ? pmax3OptimizationGoal : L2ObjectOptimizationGoal.UNSPECIFIED,
    bidPrice: _.get(json, 'bidPrice'),
    creativeDeliverType: _.get(json, 'creativeDeliverType'),
    outdoorDeliverType: _.get(json, 'outdoorDeliverType'),
    deliverType: _.get(json, 'deliverType'),
    state: _.get(json, 'state'),
    spents: _.get(json, 'spents', _.get(json, 'spent', 0)),
    currencyRate: _.get(json, 'currencyRate'),
    olapActualSpent: _.get(json, 'olapActualSpent'),
    olapExpectSpent: _.get(json, 'olapExpectSpent'),
    expectedSpent: _.get(json, 'expectedSpent', 0),
    impres: _.get(json, 'impres', 0),
    clicks: _.get(json, 'clicks', 0),
    dailyTargetBudget: _.get(json, 'dailyTargetBudget'),
    frequency: _.get(json, 'frequency'),
    dayPart: _.get(json, 'dayPart'),
    checkpoints: _.get(json, 'checkpoints', []).map(json =>
      wrapCheckPoint(json)
    ),
    enableMonitor: _.get(json, 'isMonitorEnable'),
    adType: _.get(json, 'adType'),
    videoAdViewObjective: wrapVideoAdViewObjectiveType(_.get(json, 'videoAdViewObjective')),
    conversionTracking: _.get(json, 'conversionTracking'),
    viewTrackingCodes: _.get(json, 'viewTrackingCodes'),
    videoProgressTrackingCode: wrapVideoProgressTrackginCode(json),
    tags: wrapTags(_.get(json, 'tags')),
    convTrackEvent: _.get(json, 'convTrackEvent', TrackEvent.CLICK),
    // isOutOfOutdoorBk: _.get(json, 'isOutOfOutdoorBk', false),
    orderPriceEnable: orderPrice === null || orderPrice === undefined ? false : true,
    goGanGroupId: _.get(json, 'goGanGroupId'),
    ageMin: _.get(json, 'ageMin'),
    ageMax: _.get(json, 'ageMax'),
    isDraft: _.get(json, 'draft', false),
    draftId: _.get(json, 'draftId'),
    retailType: _.get(json, 'retailType')
  };
}

function wrapCampaignListBasic (json: any): RtbCampaignListBasic {
  const normalCampaignBasic = wrapBasic(json);
  return {
    ...normalCampaignBasic,
    additionalInfo: wrapAdditionalInfo(json),
    effectiveStatus: _.get(json, 'effectiveStatus'),
    l2ObjectId: _.get(json, 'l2ObjectId'),
    magnificationRatio: _.get(json, 'magnificationRatio', 1),
    report: _.get(json, 'report'),
    convs: _.get(json, 'convs', 0),
    adView: _.get(json, 'adView', 0),
    uuCount: _.get(json, 'uuCount'),
    viewable: _.get(json, 'viewable', 0),
    bindings: _.get(json, 'bindings')
  };
}

function wrapCreativeAmount (json: any) {
  return {
    bindingCount: _.get(json, 'bindingCount', 0),
    enableCount: _.get(json, 'enableCount', 0)
  };
}

function wrapAdditionalInfo (json: any) {
  const additionalInfo = _.get(json, 'additionalInfo', null);
  return {
    creativeAmount: wrapCreativeAmount(_.get(additionalInfo, 'creativeAmount')),
    limitations: wrapLimitationsFromServer(
      _.get(additionalInfo, 'limitations', []),
      json.dealIds,
      json.ageMin,
      json.ageMax,
      isAdTypeSupportOutdoorDevice(json.adType)
    ),
    viewTrackingSize: _.get(additionalInfo, 'viewTrackingSize', 0),
    conversionTracking: wrapAdditionalConversionTracking(_.get(additionalInfo, 'conversionTracking')),
    videoProgressTrackingOffset: _.get(additionalInfo, 'videoProgressTrackingOffset'),
    pmp: _.get(additionalInfo, 'pmp')
  };
}

function wrapAdditionalConversionTracking (json: any): AdditionalInfoConversionTracking | undefined {
  if (!json) {
    return undefined;
  }

  return {
    conversionType: _.get(json, 'conversionType'),
    id: _.get(json, 'id'),
    name: _.get(json, 'name'),
    conversionId: _.get(json, 'conversionId')
  };
}

function wrapVideoProgressTrackginCode (json: any): VideoProgressTrackingCode | undefined {
  const videoProgressTrackingCodeJsonValue = _.get(json, 'videoProgressTrackingCode', null);
  if (videoProgressTrackingCodeJsonValue === null) {
    return undefined;
  }

  const videoProgressTrackingCodes: VideoProgressTrackingCode[] = videoProgressTrackingCodeJsonValue.map(json => {
    return {
      offset: _.get(json, 'offset'),
      code: _.get(json, 'code')
    };
  });

  return videoProgressTrackingCodes[0];
}

function wrapVideoAdViewObjectiveType (json: any): VideoAdViewObjectiveType {
  const event = _.get(json, 'event');
  switch (event) {
    case VideoAdViewObjective.PROGRESS:
      return {
        videoAdEvent: VideoAdViewObjective.PROGRESS,
        offset: _.get(json, 'offset')
      };
    case VideoAdMetricEvent.FIRSTQUARTILE:
    case VideoAdMetricEvent.MIDPOINT:
    case VideoAdMetricEvent.COMPLETE:
      return {
        videoAdEvent: VideoAdViewObjective.METRIC,
        videoAdMetricEvent: event
      };
    default:
      return {
        videoAdEvent: VideoAdViewObjective.DEFAULT
      };
  }
}

export function wrapCampaign (json: any): RtbCampaign {
  return {
    basic: wrapBasic(_.get(json, 'campaign')),
    limitations: wrapLimitationsFromServer(
      _.get(json, 'limitations', []),
      _.get(json, 'campaign.dealIds'),
      _.get(json, 'campaign.ageMin', RTBCAMPAIGN_DEFAULT_AGE_MIN),
      _.get(json, 'campaign.ageMax', RTBCAMPAIGN_DEFAULT_AGE_MAX),
      isAdTypeSupportOutdoorDevice(_.get(json, 'campaign.adType'))
    )
  };
}

function wrapCampaignList (json: any): RtbCampaignListBasic[] {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapCampaignListBasic(json)
  );
}

function wrapLegacyEstimateData (json: any): LegacyEstimateData {
  return {
    impression: _.get(json, 'impression', 0),
    uniqueUser: _.get(json, 'uniqueUser', 0)
  };
}

export class RestfulCampaignWebService implements RtbCampaignWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getCampaign (campaignId: number): Promise<RtbCampaign> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}`);
    return wrapCampaign(response.data);
  }

  async getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignListBasic[]> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/campaigns`,
      { params: { additional_info: 'creativeAmount,limitations,conversionTracking,viewTrackingSize,videoProgressTrackingOffset,pmp' } });
    return wrapCampaignList(response.data.records);
  }

  async createCampaign (body: any, l1ObjectId: number | string): Promise<void> {
    return this.restClient.post(`/v2/l1-objects/${l1ObjectId}/campaigns`, body);
  }

  async createCampaignWithCreative (body: any, l1ObjectId: number | string): Promise<number> {
    const response = await this.restClient.post(`/v2/l1-objects/${l1ObjectId}/campaigns-with-creatives`, body);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async updateCampaign (body: any, l1ObjectId: number | string): Promise<void> {
    let path = `/v2/l1-objects/${l1ObjectId}/campaigns/${_.get(body, 'campaign.campaignId')}`;
    return this.restClient.put(path, body);
  }

  async splitCampaign (body: any, campaignId, l1ObjectId: number | string): Promise<void> {
    let path = `/v2/l1-objects/${l1ObjectId}/campaigns/${campaignId}/split`;
    return this.restClient.post(path, body);
  }

  async deleteCampaigns (campaignIds: number[]): Promise<void> {
    await this.restClient.delete(`/v2/rtb-campaigns?campaignIds=${campaignIds.join(',')}`);
  }

  async updateCampaignState (campaignData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.restClient.put(`/v2/rtb-campaigns/${state}`, campaignData);
  }

  async getCampaignOptions (from?: string, to?: string) {
    const path = '/v2/rtb-campaigns/options';
    const params = { from, to };
    const paramsString = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    const response = await this.restClient.get(`${path}?${paramsString}`);
    return _.get(response, 'data.records', []);
  }

  async getOrderNumber (campaignId: string | number): Promise<string> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}/orderNumber`);
    return response.data;
  }

  async getLegacyEstimateData (payload: LegacyEstimateDataPayLoad): Promise<LegacyEstimateData> {
    const response = await this.restClient.post(`/v2/rtb-campaigns/discovery`, payload);
    return wrapLegacyEstimateData(response.data);
  }

  async updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void> {
    return this.restClient.put(`/v2/rtb-campaigns/${campaignId}/bid-weight`, {
      bidWeight
    });
  }

  async getRetailOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/rtb-campaigns/retail/options');
    return _.get(response, 'data.records', []);
  }

  async getRtbCampaignsUsingDailyBudget (l1ObjectId: string | number): Promise<RtbCampaignListBasic[]> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/rtb-campaigns/usingDailyBudget`);
    return wrapCampaignList(response.data);
  }

  async getAdTypeOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/rtb-campaigns/ad-type/options');
    return _.get(response, 'data.records', []);
  }

  async getOutdoorSpacesDetail (spaceIds: string[]): Promise<SpaceDevice[]> {
    const response = await this.restClient.post('/v2/rtb-campaigns/outdoor-ad/spaces-detail', spaceIds);
    return _.get(response, 'data.records', []);
  }
}
