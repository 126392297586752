import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import {
  ChannelGroupManager,
  DefaultChannelGroupManager
} from 'core/channelGroup/ChannelGroupManager';
import {
  ChannelGroupDTO,
  ChannelGroupFormData,
  ChannelGroupListDTO
} from 'core/channelGroup/ChannelGroup';
import { useCallAPI } from 'hooks/useCallAPI';
import { ROUTE_PATH } from 'enum/RoutePath';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { FormConfig } from 'components/common/form/FormConfig';
import { renderBidPriceFieldArray } from './BidPriceSettings';
import { validateEmpty } from 'utils/ValidateUtils';
import { useParams } from 'react-router-dom';
import { intersection } from 'lodash';
import { AgencyManager, DefaultAgencyManager } from 'core';

export enum ChannelGroupTabs {
  BASIC = 'basic',
  SPECIAL_SETTINGS = 'specialSettings',
  REPORT = 'report',
  BID_PRICE = 'bidPrice'
}

export type ChannelGroupFormModelData = {
  loading: boolean;
  title: string;
  tab: ChannelGroupTabs;
  redirectPath?: string;
  breadcrumbsRoutes: BreadcrumbsRoute[];
  initChannelGroup?: ChannelGroupFormData;
  basicFormConfig: FormConfig;
  reportFormConfig: FormConfig;
  bidPriceFormConfig: FormConfig;
  specialSettingsFormConfig: FormConfig;
  onTabChange: (tab: string | null) => void;
  submit: (formData: ChannelGroupFormData) => void;
  cancel: () => void;
};

const channelGroupManager: ChannelGroupManager =
  new DefaultChannelGroupManager();
const agencyManager: AgencyManager = new DefaultAgencyManager();

export const useChannelGroupFormModel = (
  channelGroups: ChannelGroupListDTO[]
): ChannelGroupFormModelData => {

  const params = useParams<{ id: string }>();
  const id = +params.id;
  const [tab, setTab] = useState(ChannelGroupTabs.BASIC);
  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string>();
  const [initChannelGroup, setInitChannelGroup] =
    useState<ChannelGroupFormData>();
  const [otherChannelsPublisherIds, setOtherChannelsPublisherIds] = useState<string[]>([]);
  const [agencyOptions, setAgencyOptions] = useState<SelectOptions[]>([]);

  useEffect(() => {
    if (isNaN(id)) {
      setRedirectPath(`/system/channel-groups/${id}/${ROUTE_PATH.ERROR404}`);
    }
    callAPIs(
      [
        () => channelGroupManager.getChannelGroup(id),
        () => agencyManager.getAgenciesOptions()
      ],
      (channelGroup: ChannelGroupDTO, agencyOptions: SelectOptions[]) => {
        setInitChannelGroup(channelGroup);
        setOtherChannelsPublisherIds(
          channelGroups.reduce(
            (acc: string[], channelGroup: ChannelGroupListDTO) => {
              if (channelGroup.id !== id) {
                acc.push(...channelGroup.publisherIds);
              }
              return acc;
            },
            []
          )
        );
        setAgencyOptions(agencyOptions);
      }
    );
  }, [id, channelGroups, callAPIs]);

  const backToDetail = useCallback(() => {
    setRedirectPath(`/system/channel-groups/${id}`);
  }, [id]);

  const submit = useCallback(
    (formData: ChannelGroupFormData) => {
      callAPIs(
        [() => channelGroupManager.editChannelGroup(id, formData)],
        () => {
          backToDetail();
        }
      );
    },
    [id, backToDetail, callAPIs]
  );

  const breadcrumbsRoutes = useMemo(
    () => [
      {
        path: '/system/channel-groups',
        breadcrumb: i18n.t<string>('channelGroups.title')
      },
      {
        path: '/system/channel-groups/:id',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: initChannelGroup ? initChannelGroup.name : '',
          matchParam: 'id'
        }
      },
      {
        path: '/system/channel-groups/:id/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: initChannelGroup ? initChannelGroup.name : '',
          matchParam: 'id'
        }
      }
    ],
    [initChannelGroup]
  );

  const onTabChange = useCallback((tab: string | null) => {
    if (!tab) {
      return;
    }
    setTab(tab as ChannelGroupTabs);
  }, []);

  const basicFormConfig = useMemo(() => {
    const validatePublisherId = (publisherIds: string[]) => {
      const error = validateEmpty(publisherIds);
      if (error) {
        return error;
      }

      const overlapIds = intersection(otherChannelsPublisherIds, publisherIds);
      if (overlapIds.length > 0) {
        return i18n.t<string>('channelGroupForm.errors.publisherIdIsUsed', { overlapIds: overlapIds.join(', ') });
      }
    };

    return new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikLabel({
              label: i18n.t<string>('channelGroupForm.labels.name'),
              name: 'name'
            })
            .addFormikTags({
              label: i18n.t<string>('channelGroupForm.labels.publisherIds'),
              name: 'publisherIds',
              validate: validatePublisherId
            })
            .build()
        )
        .withTitle(i18n.t<string>('channelGroups.titles.basicContent'))
        .build()
      )
      .build();
  }, [otherChannelsPublisherIds]);

  const specialSettingsFormConfig = useMemo(() => {
    return new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikInput({
              label: i18n.t<string>(
                'channelGroupForm.labels.outdoorAdImpresMultiply'
              ),
              name: 'property.addonProps.outdoorAdImpresMultiply',
              type: 'number',
              min: 1
            })
            .build()
        )
        .withTitle(i18n.t<string>('channelGroups.titles.specialSettingsContent'))
        .build()
      )
      .build();
  }, []);

  const reportFormConfig = useMemo(() => {
    return new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikSwitch({
              label: i18n.t<string>(
                'channelGroupForm.labels.salesChannelReportShowOverspend'
              ),
              name: 'addonFeatures.report.salesChannelReportShowOverspend'
            })
            .addFormikSelect({
              label: i18n.t<string>(
                'channelGroupForm.labels.derecognitionAgencyIds'
              ),
              name: 'property.addonProps.derecognitionAgencyIds',
              simpleValue: true,
              isMulti: true,
              options: agencyOptions
            })
            .build()
        )
        .withTitle(i18n.t<string>('channelGroups.titles.reportContent'))
        .build()
      )
      .build();
  }, [agencyOptions]);

  const bidPriceFormConfig = useMemo(() => {
    return new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikCustom({
              label: '',
              name: 'property.addonProps.campaignBidPrice',
              render: renderBidPriceFieldArray
            })
            .build()
        )
        .withTitle(i18n.t<string>('channelGroups.titles.bidPriceContent'))
        .build()
      )
      .build();
  }, []);

  return {
    loading,
    title: i18n.t<string>('channelGroups.titles.edit'),
    tab,
    redirectPath,
    initChannelGroup,
    breadcrumbsRoutes,
    basicFormConfig,
    reportFormConfig,
    bidPriceFormConfig,
    specialSettingsFormConfig,
    onTabChange,
    submit,
    cancel: backToDetail
  };
};
