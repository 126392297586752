import { getLabelValueColumn, getNameIdColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { BeaconConnection, BeaconType } from 'core/advertiser/BeaconConnection';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { useCreateBeaconConnectionFormModel, useEditBeaconConnectionFormModel } from './BeaconConnectionFormModel';
import { BeaconConnectionFormProps } from './BeaconConnectionForm';
import { LineBeaconServiceType } from 'core/advertiser/LineBeacon';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import styles from './beaconConnectionList.module.scss';

const advertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

export enum BeaconConnectionListColumn {
  ID = 'id',
  NAME = 'name',
  WEBHOOK_TYPE = 'webhookType',
  HARDWARE_ID = 'hardwareId',
  OTHER_INFO = 'otherInfo'
}

export const useBeaconConnectionListModel = (advertiserId: number) => {

  const { loading, callAPIs } = useCallAPI();
  const [beaconConnections, setBeaconConnections] = useState<BeaconConnection[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [beaconConnectionFormProps, setBeaconConnectionFormProps] = useState<BeaconConnectionFormProps>();

  const getBeaconConnections = useCallback(() => {
    callAPIs(
      [() => advertiserManager.getBeaconConnections(advertiserId)],
      (beaconConnections: BeaconConnection[]) => {
        setBeaconConnections(beaconConnections);
      }
    );
  }, [advertiserId, callAPIs]);

  useEffect(() => {
    getBeaconConnections();
  }, [getBeaconConnections]);

  const filteredBeaconConnections = useMemo(() => {
    return beaconConnections.filter(beaconConnection => {
      return beaconConnection.name.includes(searchString);
    });
  }, [beaconConnections, searchString]);

  const closeBeaconConnectionFormModal = useCallback(() => {
    setBeaconConnectionFormProps(undefined);
  }, []);

  const onSavedBeaconConnection = useCallback(() => {
    getBeaconConnections();
    closeBeaconConnectionFormModal();
  }, [getBeaconConnections, closeBeaconConnectionFormModal]);

  const showCreateBeaconConnectionFormModal = useCallback(() => {
    setBeaconConnectionFormProps({
      onCancel: closeBeaconConnectionFormModal,
      useModel: () => useCreateBeaconConnectionFormModel(advertiserId, onSavedBeaconConnection)
    });
  }, [advertiserId, onSavedBeaconConnection, closeBeaconConnectionFormModal]);

  const showEditBeaconConnectionFormModal = useCallback((beaconConnection: BeaconConnection) => {
    setBeaconConnectionFormProps({
      onCancel: closeBeaconConnectionFormModal,
      useModel: () => useEditBeaconConnectionFormModel(advertiserId, beaconConnection, onSavedBeaconConnection)
    });
  }, [advertiserId, onSavedBeaconConnection, closeBeaconConnectionFormModal]);

  const columns = useMemo(() => {
    return [
      getNameIdColumn(
        sortableColumn(
          BeaconConnectionListColumn.ID,
          i18n.t<string>('beaconConnectionList.headers.beaconType')
        ),
        undefined,
        showEditBeaconConnectionFormModal
      ),
      sortableColumn(
        BeaconConnectionListColumn.NAME,
        i18n.t<string>('beaconConnectionList.headers.name')
      ),
      {
        ...sortableColumn(
          BeaconConnectionListColumn.WEBHOOK_TYPE,
          i18n.t<string>('beaconConnectionList.headers.webhookType')
        ),
        formatter: value => i18n.t<string>(`lineFields.labels.${value}`)
      },
      sortableColumn(
        BeaconConnectionListColumn.HARDWARE_ID,
        i18n.t<string>('beaconConnectionList.headers.hardwareId')
      ),
      getLabelValueColumn(
        {
          ...sortableColumn(
            BeaconConnectionListColumn.OTHER_INFO,
            i18n.t<string>('beaconConnectionList.headers.otherInfo'),
            false
          ),
          classes: () => styles.otherInfo
        },
        (beaconConnection: BeaconConnection) => {
          if (
            !(
              beaconConnection.beaconType === BeaconType.LINE_SIMPLE_BEACON &&
              beaconConnection.webhookType === LineBeaconServiceType.TENMAX
            )
          ) {
            return undefined;
          }
          return [
            {
              label: i18n.t<string>('lineFields.labels.channelSecret'),
              value: beaconConnection.channelSecret
            },
            {
              label: i18n.t<string>('lineFields.labels.channelAccessToken'),
              value: beaconConnection.channelAccessToken
            }
          ];
        },
        styles.otherInfoValue
      )
    ];
  }, [showEditBeaconConnectionFormModal]);

  return {
    loading,
    columns,
    filteredBeaconConnections,
    beaconConnectionFormProps,
    setSearchString,
    showCreateBeaconConnectionFormModal,
    showEditBeaconConnectionFormModal
  };
};
