import {
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { AbstractPoyaFormModel } from './PoyaFormModel';

abstract class AbstractPoyaShortFormModel extends AbstractPoyaFormModel {

  get campaignAdType (): AdType {
    return AdType.POYA_SHORT;
  }
}

export class EditPoyaShortFormModel extends AbstractPoyaShortFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreatePoyaShortFormModel extends AbstractPoyaShortFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
