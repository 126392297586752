import {
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { AbstractPICFormModel } from './PICFormModel';

abstract class AbstractPICExtendedFormModel extends AbstractPICFormModel {

  get campaignAdType (): AdType {
    return AdType.PIC_EXTENDED;
  }
}

export class EditPICExtendedFormModel extends AbstractPICExtendedFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreatePICExtendedFormModel extends AbstractPICExtendedFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
