import { CreativeManagementAction, DefaultCreativeManagementStateFactory, FbAdManagementStateFactory, RetailCreativeManagementStateFactory } from 'components/CampaignCreativeManagement/CreativeManagementStateFactory';
import { FbAdSetDrafts } from 'containers/FbAdSets/FbAdSetDrafts';
import { FbAdSetList } from 'containers/FbAdSets/FbAdSetList/FbAdSetList';
import { FbAdSets } from 'containers/FbAdSets/FbAdSets';
import { MessageCampaigns } from 'containers/MessageCampaigns';
import { MessageCampaignList } from 'containers/MessageCampaigns/MessageCampaignList/MessageCampaignList';
import { DefaultMessageCampaignListModel } from 'containers/MessageCampaigns/MessageCampaignList/MessageCampaignListModel';
import { DefaultMessageCampaignsModel } from 'containers/MessageCampaigns/MessageCampaignsModel';
import { RtbCampaigns } from 'containers/RtbCampaigns';
import { RtbCampaignDrafts } from 'containers/RtbCampaigns/RtbCampaignDrafts';
import { DefaultRtbCampaignDraftsModel } from 'containers/RtbCampaigns/RtbCampaignDraftsModel';
import { CampaignList } from 'containers/RtbCampaigns/RtbCampaignList/CampaignList';
import { DefaultCampaignListModel } from 'containers/RtbCampaigns/RtbCampaignList/CampaignListModel';
import { DefaultRtbCampaignsModel } from 'containers/RtbCampaigns/RtbCampaignsModel';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { BidStrategy } from 'core/l2Object/L2Object';
import { MessageCampaign } from 'core/messageCampaign/MessageCampaign';
import { DefaultMessageCampaignManager, MessageCampaignManager } from 'core/messageCampaign/MessageCampaignManager';
import { Order } from 'core/order/Order';
import { Retail } from 'core/product/Product';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { createCreativeBindDataByCampaign, createCreativeBindDataByFbAdSet } from 'core/rtbCampaign/CreativeBindData';
import { RtbCampaignListBasic } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import i18n from 'i18n';
import _ from 'lodash';

export type L1ObjectComponentsData = {
  draftsComponent: {
    component: any,
    props: any
  };
  campaignsComponent: {
    component: any,
    props: any
  };
  listComponent: {
    component: any,
    props: any
  };
};

export interface L1ObjectChannelDetailModel {
  readonly l2ObjectList: any[];
  readonly componentsData?: L1ObjectComponentsData;
  initChannelModel: (defaultSearchString?: string | null) => Promise<void>;
  getCreativeManagementStateFactory: (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) => DefaultCreativeManagementStateFactory | undefined;
  getL1ObjectBasicViewData (): any;
  getL1ObjectChannelViewData (): any;
}

class DefaultCampaignGroupDetailModel implements L1ObjectChannelDetailModel {
  l2ObjectList: RtbCampaignListBasic[] = [];
  componentsData?: L1ObjectComponentsData;

  constructor (
    protected order: Order,
    protected l1Object: L1Object,
    protected addonFeatureManager: AddonFeatureManager,
    protected refreshGroupDetail,
    protected localeMeta?: LocaleMeta,
    protected campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager()
  ) {}

  async initChannelModel (defaultSearchString?: string | null) {
    try {
      this.l2ObjectList = await this.campaignManager.getCampaignsOfGroup(this.l1Object.l1ObjectId);
      const campaignListModel = new DefaultCampaignListModel(
        this.order,
        this.l1Object,
        this.l2ObjectList,
        this.addonFeatureManager,
        this.refreshGroupDetail,
        () => {
          // This is intentional
        },
        defaultSearchString ? defaultSearchString : '',
        this.l1Object.budgetBalance,
        this.localeMeta
      );
      const campaignsModel = new DefaultRtbCampaignsModel(
        this.order,
        this.addonFeatureManager,
        this.l1Object,
        this.l2ObjectList
      );
      const campaignDraftsModel = new DefaultRtbCampaignDraftsModel(
        this.order,
        this.addonFeatureManager,
        this.l1Object,
        this.l2ObjectList
      );
      this.componentsData = {
        draftsComponent: {
          component: RtbCampaignDrafts,
          props: {
            model: campaignDraftsModel
          }
        },
        campaignsComponent: {
          component: RtbCampaigns,
          props: {
            model: campaignsModel
          }
        },
        listComponent: {
          component: CampaignList,
          props: {
            model: campaignListModel
          }
        }
      };
    } catch (e) {
      console.error(e);
    }
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.l2ObjectList.map(createCreativeBindDataByCampaign);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds, draftIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }

    return new DefaultCreativeManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.l1Object
    );
  }

  getL1ObjectChannelViewData () {
    const rtbBidStrategyKey = Object.keys(BidStrategy).find(key => BidStrategy[key] === _.get(this.l1Object, 'rtb.bid_strategy', ''));
    const viewableImpressReport = _.get(this.l1Object, 'rtb.viewable_impress_report', false);
    return _.compact([
      {
        label: i18n.t<string>('l1Object.labels.objectiveLabel'),
        value: this.l1Object.objective ? i18n.t<string>(`l1Object.labels.objective.${this.l1Object.objective.toLowerCase()}`) : ''
      }, this.l1Object.autoOptimise ? {
        label: i18n.t<string>('l1Object.labels.bidStrategyLabel'),
        value: rtbBidStrategyKey ? i18n.t<string>(`l1Object.labels.bidStrategy.${rtbBidStrategyKey.toLowerCase()}`) : ''
      } : undefined, {
        label: i18n.t<string>('l1Object.labels.viewableImpressReport'),
        value: viewableImpressReport ? i18n.t<string>('common.labels.yes') : i18n.t<string>('common.labels.no')
      }
    ]);
  }

  getL1ObjectBasicViewData () {
    const getBudgetValue = (l1Object: L1Object, currency: string) => {
      const hasBudget = !_.isNil(l1Object.budget);
      const hasDailyBudget = !_.isNil(l1Object.dailyBudget);
      if (hasBudget) {
        return formatPriceWithCurrency(currency, +l1Object.budget!);
      }
      if (hasDailyBudget) {
        return `${formatPriceWithCurrency(currency, +l1Object.dailyBudget!)} / ${i18n.t<string>('campaign.labels.day')}`;
      }
      return i18n.t<string>('common.labels.noData');
    };

    let budgetBalanceSummary: any = {
      label: i18n.t<string>('l1Object.labels.budgetBalance'),
      value: formatPriceWithCurrency(this.order.currency, this.l1Object.budgetBalance)
    };
    let cboInfo = i18n.t<string>('common.labels.no');
    if (this.l1Object.autoOptimise) {
      budgetBalanceSummary = undefined;
      cboInfo = i18n.t<string>('common.labels.yes');
    }
    return _.compact([
      {
        label: i18n.t<string>('l1Object.labels.name'),
        value: this.l1Object.name
      },
      {
        label: i18n.t<string>('l1Object.labels.channel'),
        value: i18n.t<string>(`l1Object.labels.channel_${this.l1Object.channel.toLowerCase()}`)
      },
      {
        label: i18n.t<string>('l1Object.labels.budget'),
        value: getBudgetValue(this.l1Object, this.order.currency)
      },
      budgetBalanceSummary,
      {
        label: i18n.t<string>('l1Object.labels.autoOptimise'),
        value: cboInfo
      }
    ]);
  }
}

class RetailMediaCampaignGroupDetailModel extends DefaultCampaignGroupDetailModel {

  retailers: Retail[] = [];

  constructor (
    order: Order,
    l1Object: L1Object,
    addonFeatureManager: AddonFeatureManager,
    refreshGroupDetail,
    localeMeta?: LocaleMeta,
    campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    private rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    super(order, l1Object, addonFeatureManager, refreshGroupDetail, localeMeta, campaignManager);
  }

  async initChannelModel (defaultSearchString?: string | null) {
    try {
      this.retailers = await this.rdpManager.getRetails();
      await super.initChannelModel(defaultSearchString);
    } catch (e) {}
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.l2ObjectList.map(createCreativeBindDataByCampaign);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds, draftIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }

    return new RetailCreativeManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.l1Object,
      campaigns[0].retailer
    );
  }
}

class SupportOutdoorDeviceCampaignGroupDetailModel extends DefaultCampaignGroupDetailModel {

  constructor (
    order: Order,
    l1Object: L1Object,
    addonFeatureManager: AddonFeatureManager,
    refreshGroupDetail,
    localeMeta?: LocaleMeta,
    private adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
  ) {
    super(order, l1Object, addonFeatureManager, refreshGroupDetail, localeMeta);
  }

  async initChannelModel (defaultSearchString?: string | null): Promise<void> {
    try {
      await super.initChannelModel(defaultSearchString);
      await this.adRequestSourceManager.getSpaceAttributes('Outdoor');
    } catch (e) {}
  }
}

class MessageCampaignGroupDetailModel implements L1ObjectChannelDetailModel {

  l2ObjectList: MessageCampaign[] = [];
  componentsData?: L1ObjectComponentsData;

  constructor (
    protected order: Order,
    protected l1Object: L1Object,
    protected addonFeatureManager: AddonFeatureManager,
    protected refreshGroupDetail,
    protected localeMeta?: LocaleMeta,
    protected messageCampaignManager: MessageCampaignManager = new DefaultMessageCampaignManager()
  ) {}

  async initChannelModel (defaultSearchString?: string | null) {
    try {
      this.l2ObjectList = await this.messageCampaignManager.getCampaignsOfGroup(this.l1Object.l1ObjectId);
      const campaignListModel = new DefaultMessageCampaignListModel(
        this.order,
        this.l1Object,
        this.l2ObjectList,
        this.addonFeatureManager,
        this.refreshGroupDetail,
        () => {
          // This is intentional
        },
        defaultSearchString ? defaultSearchString : '',
        this.l1Object.budgetBalance,
        this.localeMeta
      );
      const campaignsModel = new DefaultMessageCampaignsModel(
        this.order,
        this.addonFeatureManager,
        this.l1Object,
        []
      );
      // TODO: add Message draft model
      const campaignDraftsModel = new DefaultRtbCampaignDraftsModel(
        this.order,
        this.addonFeatureManager,
        this.l1Object,
        []
      );
      this.componentsData = {
        draftsComponent: {
          component: RtbCampaignDrafts,
          props: {
            model: campaignDraftsModel
          }
        },
        campaignsComponent: {
          component: MessageCampaigns,
          props: {
            model: campaignsModel
          }
        },
        listComponent: {
          component: MessageCampaignList,
          props: {
            model: campaignListModel
          }
        }
      };
    } catch (e) {
      console.error(e);
    }
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    return undefined;
  }

  getL1ObjectChannelViewData () {
    return _.compact([
      {
        label: i18n.t<string>('l1Object.labels.objectiveLabel'),
        value: this.l1Object.objective ? i18n.t<string>(`l1Object.labels.objective.${this.l1Object.objective.toLowerCase()}`) : ''
      }
    ]);
  }

  getL1ObjectBasicViewData () {
    const getBudgetValue = (l1Object: L1Object, currency: string) => {
      return formatPriceWithCurrency(currency, +l1Object.budget!);
    };

    let budgetBalanceSummary: any = {
      label: i18n.t<string>('l1Object.labels.budgetBalance'),
      value: formatPriceWithCurrency(this.order.currency, this.l1Object.budgetBalance)
    };
    return [
      {
        label: i18n.t<string>('l1Object.labels.name'),
        value: this.l1Object.name
      },
      {
        label: i18n.t<string>('l1Object.labels.channel'),
        value: i18n.t<string>(`l1Object.labels.channel_${this.l1Object.channel.toLowerCase()}`)
      },
      {
        label: i18n.t<string>('l1Object.labels.budget'),
        value: getBudgetValue(this.l1Object, this.order.currency)
      },
      budgetBalanceSummary
    ];
  }
}

class FBCampaignDetailModel implements L1ObjectChannelDetailModel {

  l2ObjectList: FbAdSet[] = [];
  componentsData?: L1ObjectComponentsData;

  constructor (
    private order: Order,
    private l1Object: L1Object,
    private addonFeatureManager: AddonFeatureManager,
    private refreshGroupDetail,
    private fbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager()
  ) {}

  async initChannelModel () {
    try {
      this.l2ObjectList = await this.fbAdSetManager.getAdSets(this.l1Object.l1ObjectId);
      const budgetBalance = _.get(this.l1Object, 'budgetBalance', 0);
      this.componentsData = {
        draftsComponent: {
          component: FbAdSetDrafts,
          props: {
            order: this.order,
            l1Object: this.l1Object,
            fbAdSetList: this.l2ObjectList
          }
        },
        campaignsComponent: {
          component: FbAdSets,
          props: {
            order: this.order,
            l1Object: this.l1Object,
            fbAdSetList: this.l2ObjectList
          }
        },
        listComponent: {
          component: FbAdSetList,
          props: {
            order: this.order,
            l1Object: this.l1Object,
            adSets: this.l2ObjectList,
            budgetBalance,
            refreshList: this.refreshGroupDetail,
            addonFeatureManager: this.addonFeatureManager
          }
        }
      };
    } catch (e) {}
  }

  getCreativeManagementStateFactory (
    campaignIds,
    draftIds,
    actionParam,
    history
  ) {
    const creativeBindData = this.l2ObjectList.map(createCreativeBindDataByFbAdSet);
    const campaigns = getCampaignBindList(creativeBindData, campaignIds, draftIds);
    const allActions: string[] = Object.values(CreativeManagementAction);
    if (campaigns.length === 0 || !allActions.includes(actionParam)) {
      return;
    }
    return new FbAdManagementStateFactory(
      campaigns,
      this.order.advertiserId,
      actionParam,
      this.addonFeatureManager,
      this.order.orderNumber,
      history,
      this.l1Object
    );
  }

  getL1ObjectChannelViewData () {
    const fbBidStrategyKey = Object.keys(BidStrategy).find(key => BidStrategy[key] === _.get(this.l1Object, 'fb.bid_strategy', ''));
    return this.l1Object.fb ? _.compact([
      {
        label: i18n.t<string>('l1Object.labels.fb.accountId'),
        value: this.l1Object.fb.account_id
      }, {
        label: i18n.t<string>('l1Object.labels.objectiveLabel'),
        value: Object.values(L1ObjectObjective).includes(this.l1Object.fb.objective) ?
          i18n.t<string>(`l1Object.labels.objective.${this.l1Object.fb.objective.toLowerCase()}`) :
          this.l1Object.fb.objective
      }, this.l1Object.autoOptimise ? {
        label: i18n.t<string>('l1Object.labels.bidStrategyLabel'),
        value: fbBidStrategyKey ? i18n.t<string>(`l1Object.labels.bidStrategy.${fbBidStrategyKey.toLowerCase()}`) : ''
      } : undefined
    ]) : undefined;
  }

  getL1ObjectBasicViewData () {
    const getBudgetValue = (l1Object: L1Object, currency: string) => {
      const hasBudget = !_.isNil(l1Object.budget);
      const hasDailyBudget = !_.isNil(l1Object.dailyBudget);
      if (hasBudget) {
        return formatPriceWithCurrency(currency, +l1Object.budget!);
      }
      if (hasDailyBudget) {
        return `${formatPriceWithCurrency(currency, +l1Object.dailyBudget!)} / ${i18n.t<string>('campaign.labels.day')}`;
      }
      return i18n.t<string>('common.labels.noData');
    };

    let budgetBalanceSummary: any = {
      label: i18n.t<string>('l1Object.labels.budgetBalance'),
      value: formatPriceWithCurrency(this.order.currency, this.l1Object.budgetBalance)
    };
    let cboInfo = i18n.t<string>('common.labels.no');
    if (this.l1Object.autoOptimise) {
      budgetBalanceSummary = undefined;
      cboInfo = i18n.t<string>('common.labels.yes');
    }
    return _.compact([
      {
        label: i18n.t<string>('l1Object.labels.name'),
        value: this.l1Object.name
      },
      {
        label: i18n.t<string>('l1Object.labels.channel'),
        value: i18n.t<string>(`l1Object.labels.channel_${this.l1Object.channel.toLowerCase()}`)
      },
      {
        label: i18n.t<string>('l1Object.labels.budget'),
        value: getBudgetValue(this.l1Object, this.order.currency)
      },
      budgetBalanceSummary,
      {
        label: i18n.t<string>('l1Object.labels.autoOptimise'),
        value: cboInfo
      }
    ]);
  }
}

export function getL1ObjectChannelDetailModel (
  order: Order,
  l1Object: L1Object,
  addonFeatureManager: AddonFeatureManager,
  refreshGroupDetail,
  localeMeta?: LocaleMeta,
  l1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager()
): L1ObjectChannelDetailModel | undefined {

  const notNeedLocaleArgs: [Order, L1Object, AddonFeatureManager, boolean] =
    [order, l1Object, addonFeatureManager, refreshGroupDetail];
  const needLocaleMetaArgs: [Order, L1Object, AddonFeatureManager, boolean, LocaleMeta | undefined] =
    [order, l1Object, addonFeatureManager, refreshGroupDetail, localeMeta];

  if (l1ObjectManager.isChannelSupportOutdoorDeviceTA(l1Object.channel)) {
    return new SupportOutdoorDeviceCampaignGroupDetailModel(...needLocaleMetaArgs);
  }

  switch (l1Object.channel) {
    case L1ObjectChannel.FB:
      return new FBCampaignDetailModel(...notNeedLocaleArgs);
    case L1ObjectChannel.RETAIL_MEDIA:
      return new RetailMediaCampaignGroupDetailModel(...needLocaleMetaArgs);
    case L1ObjectChannel.MESSAGE:
      return new MessageCampaignGroupDetailModel(...needLocaleMetaArgs);
    default:
      return new DefaultCampaignGroupDetailModel(...needLocaleMetaArgs);
  }
}

function getCampaignBindList (creativeBindData, campaignIds, draftIds) {
  return creativeBindData ? creativeBindData.filter(campaign =>
    campaign.draftId ?
      draftIds.includes(campaign.draftId.toString()) :
      (campaign.id && campaignIds.includes(campaign.id.toString()))
  ) : [];
}
