import { useMemo } from 'react';
import { FormConfig, FormField } from 'components/common/form/FormConfig';
import i18n from 'i18n';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import config from 'config';
import { useOutdoorDurationFields } from './useOutdoorDurationFields';
import { useFormikContext } from 'formik';
import { OutdoorType } from 'core/creative/Creative';
import styles from './outdoorFields.module.scss';
import { useOutdoorProperties } from './useOutdoorProperties';
import { HtmlOutdoorCreativeFields } from 'core/creative/OutdoorCreative';
import { OutdoorFormModel } from '../OutdoorFormModel';

export const useHtmlOutdoorFields = <T extends OutdoorFormModel<T>>(
  model: T
): FormField[] => {
  const { values } = useFormikContext<HtmlOutdoorCreativeFields>();
  const {
    outdoorType
  } = useOutdoorProperties(values);
  const durationFields = useOutdoorDurationFields('input', model.validateDuration);

  const fields = useMemo(() => {
    if (outdoorType !== OutdoorType.HTML5) {
      return [];
    }

    const builder = new FormConfig.FieldsBuilder();
    builder.addFormikFileInput({
      type: 'other',
      label: i18n.t<string>('creativeSetupFlow.labels.outdoorHtml'),
      name: 'medias.html',
      className: styles.fileInput,
      icon: faFileArchive,
      hints: [
        i18n.t<string>('html5Form.labels.typeHint'),
        i18n.t<string>('creativeSetupFlow.labels.storageHint', {
          storage: '2MB'
        })
      ],
      permanentHint: (
        <span className={styles.hint}>
          <Trans i18nKey='html5Form.labels.fileHint'>
            ...
            <a
              href={`https://storage.googleapis.com/${config.cloudStorageConfig.bucket}/index.html.zip`}
              download
            >
              Click here
            </a>
            ...
          </Trans>
        </span>
      ),
      validate: model.validateZip
    });

    return [...builder.build(), ...durationFields];
  }, [model, outdoorType, durationFields]);

  return fields;
};
