import { AgencyDetail, Agency, Currency, AgencyWithPagination } from './Agency';
import {
  AgencyWebService,
  RestfulAgencyWebService
} from 'ws/AgencyWebService';
import { CompanyMember } from 'core/companyMember/CompanyMember';
import { AdLogo } from 'core/adLogo/AdLogo';
import { Pageable } from 'ws/Pageable';
import { generateI18nOfSelectOptions } from 'utils/I18nUtils';
import { ChannelGroupWebService, RestfulChannelGroupWebService } from 'ws/ChannelGroupWebService';
import { CampaignBidPriceSetting } from 'core/channelGroup/ChannelGroup';
import { defaultTo, keys } from 'lodash';
import { defaultCampaignBidPrice } from 'containers/Agencies/AgencyForm/defaultCampaignBidPrice';
import { AdType } from 'core/rtbCampaign/RtbCampaign';

export interface AgencyManager {
  getRTBDefaultMinBudgetPerDay (currency: string): number;
  getRTBDefaultMinBudget (currency: string): number;
  getAgencies (): Promise<Array<Agency>>;
  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination>;
  getCreditLog (agencyId: number): Promise<any>;
  getBill (agencyId: number): Promise<any>;
  fetchAgency (agencyId: number, needChannelGroupsData?: boolean): Promise<Agency>;
  updateAgency (request: AgencyDetail): Promise<number>;
  updateAgencySelf (agencyId: number, request: {
    agcPercent: number,
    adLogo: AdLogo,
    logoData: {
      url?: string;
      file?: any;
    }
  }): Promise<number>;
  updateAgencyMargin (agencyId: number, agcPercent: number): Promise<void>;
  createAgency (request: AgencyDetail): Promise<number>;
  getMembers (agencyId: number): Promise<Array<CompanyMember>>;
  getAgenciesOptions (addonFeature?: string, extraFields?: string[], allEnable?: boolean): Promise<Array<SelectOptions>>;
  getOemOptions (): Promise<Array<SelectOptions>>;
  payBill (billId: number): Promise<void>;
  updateRebate (agencyId: number, rebateData: {
    id: string | number;
    effectDate: string;
    rate: string | number;
  }): Promise<void>;
  getAdLogo (agencyId: number): Promise<AdLogo>;
  getAgencyName (agencyId: number): Promise<string | null>;
  mergeChannelGroupBidPriceSetting (agencyDetail: AgencyDetail): Promise<void>;
}

const minBudgetPerDayMap = {
  [Currency.USD]: 10,
  [Currency.IDR]: 150000,
  [Currency.NTD]: 300
};

const minBudgetMap = {
  [Currency.USD]: 3,
  [Currency.IDR]: 50000,
  [Currency.NTD]: 100
};

export class DefaultAgencyManager implements AgencyManager {

  constructor (
    private webService: AgencyWebService = new RestfulAgencyWebService(),
    private channelGroupWebService: ChannelGroupWebService = new RestfulChannelGroupWebService()
  ) {}

  getRTBDefaultMinBudgetPerDay (currency: string): number {
    return minBudgetPerDayMap[currency];
  }

  getRTBDefaultMinBudget (currency: string): number {
    return minBudgetMap[currency];
  }

  getAgencies (addonFeature?: string): Promise<Array<Agency>> {
    return this.webService.getAgencies(addonFeature);
  }

  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination> {
    return this.webService.getAgenciesWithPagination(pageable, searchString);
  }

  async getCreditLog (agencyId: number): Promise<any> {
    return this.webService.getCreditLog(agencyId);
  }

  async getBill (agencyId: number): Promise<any> {
    return this.webService.getBill(agencyId);
  }

  async fetchAgency (agencyId: number, needChannelGroupsData: boolean = false): Promise<Agency> {
    const agency = await this.webService.getAgency(agencyId);

    if (!needChannelGroupsData) {
      return agency;
    }

    await this.mergeChannelGroupBidPriceSetting(agency.detail);
    return agency;
  }

  async updateAgency (request: AgencyDetail): Promise<number> {
    return this.webService.updateAgency(request);
  }

  async updateAgencySelf (agencyId: number, request: {
    agcPercent: number,
    adLogo: AdLogo,
    logoData: {
      url?: string;
      file?: any;
    }
  }): Promise<number> {
    return this.webService.updateAgencySelf(agencyId, request);
  }

  async updateAgencyMargin (agencyId: number, agcPercent: number): Promise<void> {
    return this.webService.updateAgencyMargin(agencyId, agcPercent);
  }

  async createAgency (request: AgencyDetail): Promise<number> {
    return this.webService.createAgency(request);
  }

  async getMembers (agencyId: number): Promise<Array<CompanyMember>> {
    return this.webService.getAgencyMember(agencyId);
  }

  async getAgenciesOptions (addonFeature?: undefined, extraFields?: string[], allAddonEnable?: undefined): Promise<Array<SelectOptions>>;
  async getAgenciesOptions (addonFeature: string, extraFields?: string[], allAddonEnable?: boolean): Promise<Array<SelectOptions>>;
  async getAgenciesOptions (addonFeature?: string, extraFields?: string[], allAddonEnable: boolean = true): Promise<Array<SelectOptions>> {
    const options = await this.webService.getAgenciesOptions(addonFeature, extraFields, allAddonEnable);
    options.forEach(option => generateI18nOfSelectOptions(option, 'agency.name'));
    return options;
  }
  async getOemOptions (): Promise<Array<SelectOptions>> {
    return this.webService.getOemOptions();
  }

  async updateRebate (agencyId: number, rebateData: {
    id: string | number;
    effectDate: string;
    rate: string | number;
  }): Promise<void> {
    return this.webService.updateRebate(agencyId, rebateData);
  }

  async payBill (billId: number): Promise<void> {
    return this.webService.payBill(billId);
  }

  async getAdLogo (agencyId: number): Promise<AdLogo> {
    return this.webService.getAdLogo(agencyId);
  }

  async getAgencyName (agencyId: number): Promise<string | null> {
    const name = await this.webService.getAgencyName(agencyId);
    if (name) {
      generateI18nOfSelectOptions({ label: name, value: agencyId }, 'agency.name');
    }
    return name;
  }

  async mergeChannelGroupBidPriceSetting (agencyDetail: AgencyDetail): Promise<void> {
    const adTypeOrder: string[] = keys(AdType).map(key => AdType[key]);
    const channelGroups = await this.channelGroupWebService.getChannelGroups();
    const channelGroupBidPriceSettings = channelGroups.reduce((acc, channelGroup) => {
      return [...acc, ...channelGroup.property.addonProps.campaignBidPrice];
    }, [] as CampaignBidPriceSetting[]);
    const agencyCampaignBidPriceSettings = defaultTo(agencyDetail.addonProps.campaignBidPrice, []);
    // merge channel group bid price setting to agency bid price setting
    channelGroupBidPriceSettings.forEach(channelGroupBidPriceSetting => {
      if (!agencyCampaignBidPriceSettings.find(agencyCampaignBidPriceSetting => agencyCampaignBidPriceSetting.type === channelGroupBidPriceSetting.type)) {
        agencyCampaignBidPriceSettings.push(channelGroupBidPriceSetting);
      }
    });
    // remove invalid bid price setting
    const validBidPriceType = [
      ...defaultCampaignBidPrice.map(bidPrice => bidPrice.type),
      ...channelGroupBidPriceSettings.map(bidPrice => bidPrice.type)
    ];
    agencyDetail.addonProps.campaignBidPrice = agencyCampaignBidPriceSettings
      .filter(bidPriceSetting => validBidPriceType.includes(bidPriceSetting.type))
      .sort((a, b) => adTypeOrder.indexOf(a.type) - adTypeOrder.indexOf(b.type));
  }
}
