import { ModalState } from 'containers/Common/ModalStateFactory';
import { L1Object } from 'core/l1Object/L1Object';
import i18n from 'i18n';
import { DraftSetupStateContent } from './DraftSetupStateContent';
import { DefaultDraftSetupStateContentModel } from './DraftSetupStateContentModel';

export class DraftSetupState implements ModalState {

  back?;
  next?;
  close?;
  contentModel: DefaultDraftSetupStateContentModel;

  constructor (
    protected orderOptions: SelectOptions[],
    protected l1Object: L1Object,
    protected l2ObjectsToCreateDraft: {
      id: number | string,
      isDraft: boolean
    } []
  ) {
    this.contentModel = this.getContentModel();
  }

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${this.l1Object.channel.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  titleFormatter = (title) => {
    if (i18n.language === 'en' && this.l2ObjectsToCreateDraft.length > 1) {
      return title = title + 's';
    }
    return title;
  }

  getContentModel = (): DefaultDraftSetupStateContentModel => {
    return new DefaultDraftSetupStateContentModel(this.orderOptions, this.l1Object.channel);
  }

  get content () {
    return DraftSetupStateContent;
  }

  get btnData (): any {
    return {
      primaryButton: this.next && {
        title: i18n.t<string>('draftCreateModal.buttons.goSummary'),
        callback: this.next
      },
      secondaryButton: this.close && {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: this.close
      }
    };
  }

  canGoNext = () => {
    return this.contentModel.canGoNext;
  }
}
