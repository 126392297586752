import styles from './channelGroupMembers.module.scss';
import i18n from 'i18n';
import { ChannelGroupMember, ChannelGroupMemberListColumn, ModalData, useChannelGroupMembersModel } from './ChannelGroupMembersModel';
import { ActorPermissionForm } from 'components/ActorPermissionForm';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { SortDescriptor } from 'components/TableColumn/TableColumn';
import { useCallback, useMemo } from 'react';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'components/common/Modal/Modal';

export const ChannelGroupMembers: React.FC<{
  channelGroupId: number;
}> = ({
  channelGroupId
}) => {

  const {
    loading,
    members,
    columns,
    modalData,
    permissionFormModel,
    hideModal,
    onAddRoleBtnClick,
    onDeleteBtnClick
  } = useChannelGroupMembersModel(channelGroupId);

  const defaultSorts: SortDescriptor = useMemo(() => {
    return [{
      dataField: 'index',
      order: 'asc'
    }];
  }, []);

  const renderModal = useCallback((modalData: ModalData) => {
    return (
      <Modal
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: hideModal
        }}
        dismiss={hideModal}
        animation={false}
      >
        {modalData.message}
      </Modal>
    );
  }, [hideModal]);

  const renderRowBtns = useCallback((member: ChannelGroupMember) => {
    const onClick = () => onDeleteBtnClick(member);
    return [
      <IconWithTooltip
        key={member.id}
        icon={faTrashAlt}
        onClick={onClick}
        tooltipProps={{
          id: `channel-group-member-delete-tip-${member.id}`,
          tooltip: i18n.t<string>('channelGroupMembers.hints.delete')
        }}
      />
    ];
  }, [onDeleteBtnClick]);

  return (
    <div className={styles.channelGroupMembers}>
      {loading && <LoadingIndicator />}
      {modalData && renderModal(modalData)}
      <div className={styles.operationPanel}>
        <button className={'btn btn-tertiary btn-sm'} onClick={onAddRoleBtnClick}>
          {i18n.t<string>('channelGroupMembers.buttons.addMember')}
        </button>
      </div>
      <div className={styles.listContainer}>
        <StickableBootstrapTable
          keyField={ChannelGroupMemberListColumn.ID}
          customPadding={false}
          data={members}
          columns={columns}
          stickFirstColumn
          stickLastColumn
          defaultSorted={defaultSorts}
          hidePagination={true}
          noDataIndication={i18n.t<string>('channelGroupMembers.labels.noDataAvailable')}
          renderRowBtns={renderRowBtns}
        />
      </div>
      {permissionFormModel && <ActorPermissionForm model={permissionFormModel} />}
    </div>
  );
};
