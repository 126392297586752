import React, { Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import classNames from 'classnames/bind';
import i18n from 'i18next';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { CampaignListState, CampaignListProps, CampaignListType } from './CampaignListModel';
import { CampaignListColumns } from './CampaignListColumnSetting';
import { faPencilAlt, faChartArea, faEye, faCut, faBalanceScaleLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './campaignList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import Progress from 'components/common/Progress/Progress';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Modal } from 'components/common/Modal/Modal';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Permission } from 'core/auth/Permission';
import { addOnEnabled, hasFuncs, hasRoles, inCondition, isPmax3Order, notSettleOrder } from 'core/permission/PermissionDSL';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { ObjectFitContainer } from 'components/common/ObjectFitContainer/ObjectFitContainer';
import _ from 'lodash';
import { Status } from 'components/Status/Status';
import { CampaignState, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { PermissionItem, RoleNames } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { formatPrice, formatPriceAny } from 'helper/CurrencyHelper';
import Select from 'components/common/Select/Select';
import { Trans } from 'react-i18next';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { FilterMenuTab } from 'components/common/FilterMenuTab/FilterMenuTab';
import { DraftCreateModal } from 'containers/Draft/DraftCreateModal';
import { BidStrategy } from 'core/l2Object/L2Object';
import { L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { LimitationTableCell } from 'components/LimitationTableCell/LimitationTableCell';
import { AuditLog } from 'containers/AuditLog/AuditLog';
import { StateTableCell } from 'components/StateTableCell/StateTableCell';

export class CampaignList extends React.Component<RouteComponentProps<{}> & CampaignListProps, CampaignListState> {
  cssClassNames: any;
  handler?: number;
  columnFormatterMap: any;
  campaignWritePermission: PermissionItem;

  constructor (props) {
    super(props);
    this.state = props.model.state;
    this.cssClassNames = classNames.bind(styles);
    this.campaignWritePermission = hasFuncs(Permission.CAMPAIGN_WRITE)
      .and(addOnEnabled(ADDONFEATURE.CHANNEL[_.get(props.model.l1Object, 'channel', L1ObjectChannel.RTB)]))
      .and(isPmax3Order(props.model.order.id))
      .and(notSettleOrder(props.model.order));
    this.columnFormatterMap = {
      [CampaignListColumns.NAME]: this.nameFormatter,
      [CampaignListColumns.RETAIL]: value => i18n.t<string>(`retailers.${value}`),
      [CampaignListColumns.STATE]: this.stateFormatter,
      [CampaignListColumns.DELIVERY]: this.deliveryFormatter,
      [CampaignListColumns.CREATIVE]: this.creativeFormatter,
      [CampaignListColumns.SCHEDULE]: this.scheduleFormatter,
      [CampaignListColumns.PROGRESS]: this.progressFormatter,
      [CampaignListColumns.BUDGET]: this.budgetFormatter,
      [CampaignListColumns.PRICE]: this.priceFormatter,
      [CampaignListColumns.OPTIMIZE]: this.optimizationFormatter,
      [CampaignListColumns.BUDGET_GROUP]: this.budgetGroupFormatter,
      [CampaignListColumns.PRICE_GROUP]: this.priceGroupFormatter,
      [CampaignListColumns.LIMITATION]: this.limitationFormatter,
      [CampaignListColumns.TRACKING]: this.trackingFormatter,
      [CampaignListColumns.RESULTS]: this.resultsFormatter,
      [CampaignListColumns.IMPRES]: this.descFormatter,
      [CampaignListColumns.VIEWABLE]: this.descFormatter,
      [CampaignListColumns.CLICKS]: this.descFormatter,
      [CampaignListColumns.CPC]: this.performanceDataFormatter.bind(this, 2),
      [CampaignListColumns.CTR]: this.dataPercentFormatter,
      [CampaignListColumns.VCTR]: this.dataPercentFormatter,
      [CampaignListColumns.CONVERT]: this.descFormatter,
      [CampaignListColumns.CPA]: this.performanceDataFormatter.bind(this, 2),
      [CampaignListColumns.CVR]: this.dataPercentFormatter,
      [CampaignListColumns.VIEW]: this.descFormatter,
      [CampaignListColumns.VIEWRATE]: this.dataPercentFormatter,
      [CampaignListColumns.VIEWABLE_VIEWRATE]: this.dataPercentFormatter,
      [CampaignListColumns.UUCOUNT]: this.performanceDataFormatter.bind(this, 0),
      [CampaignListColumns.SPENT]: _.partial(formatPrice, props.model.order.currency),
      [CampaignListColumns.TAGS]: this.tagsFormatter,
      [CampaignListColumns.EDITBTNS]: this.floatingEditBtnsFormatter
    };
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    if (this.props.model.searchString !== '') {
      this.props.model.onSearchChange(this.props.model.searchString);
    }
    if (this.props.model.state.needUpdateViewModelData) {
      this.props.model.updateViewModelData();
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
    if (this.props.model.state.needUpdateViewModelData) {
      this.props.model.updateViewModelData();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  getCampaignEditPermission (campaign) {
    const {
      l1Object,
      isPmp
    } = this.props.model;
    return _.clone(this.campaignWritePermission)
      .and(
        inCondition(
          () =>
            campaign.state !== CampaignState.DELETE &&
            !isPmp(campaign) &&
            l1Object.objective !== L1ObjectObjective.UNSPECIFIED
        )
      );
  }

  nameHeaderFormatter = (column: any, columnIndex: number, { sortElement }) => {
    const model = this.props.model;
    const allSelectableCampaigns = model.state.viewModelData
      ? model.state.viewModelData.filter(viewModelData => !model.isPmp(viewModelData))
      : model.campaignList.filter(campaign => !model.isPmp(campaign));
    const allCampaignLength = allSelectableCampaigns.length;
    const selectedObjectsLength = model.state.selectedCampaign.length + model.state.selectedDraft.length;
    const isDeletedList = model.state.selectedStateFilter.includes(i18n.t<string>('campaignList.labels.deleteState'));
    return (
      <div className={styles.nameHeader}>
        <PermissionChecker permissionAware={_.clone(this.campaignWritePermission).and(inCondition(() => !isDeletedList))}>
          <input
            type='checkbox'
            checked={allCampaignLength === selectedObjectsLength && selectedObjectsLength > 0}
            ref={el => el && (el.indeterminate = allCampaignLength !== selectedObjectsLength && selectedObjectsLength > 0)}
            onChange={model.handleOnSelectAll}
            id={'input0'}
          />
          <label htmlFor='input0' />
        </PermissionChecker>
        {i18n.t<string>(column.text)}
        {sortElement}
      </div>
    );
  }

  nameFormatter = (columnData: any, campaign: any): any => {
    if (columnData.summary) {
      return (
        <div>{columnData.summary}</div>
      );
    }

    const handleOnSelect = (e) => {
      this.props.model.handleOnSelect(campaign);
    };

    const renderLabel = () => <span className={styles.campaignName}>{columnData.name}</span>;
    const isDraft: boolean = _.get(campaign, 'isDraft', false);
    const campaignId: number = campaign.id;
    const draftId: number = _.get(campaign, 'draftId', 0);
    const objectIsChecked = !isDraft ? this.props.model.state.selectedCampaign.indexOf(campaignId) > -1 : this.props.model.state.selectedDraft.indexOf(draftId) > -1;
    const editPath = campaign.isDraft ?
      `${this.props.match.url}/drafts/${draftId}/${this.props.model.editCampaignPath}` :
      `${this.props.match.url}/campaigns/${campaignId}/${this.props.model.editCampaignPath}`;
    return (
      <div className={styles.campaignCell}>
        <div className={styles.selectCheckBox}>
          <PermissionChecker
            permissionAware={this.getCampaignEditPermission(campaign)}
          >
            <input
              type='checkbox'
              checked={objectIsChecked}
              onChange={handleOnSelect}
              id={`input${columnData.listId}`}
            />
            <label htmlFor={`input${columnData.listId}`} />
          </PermissionChecker>
        </div>
        <div className={styles.info}>
          <PermissionChecker
            permissionAware={this.getCampaignEditPermission(campaign)}
            renderOtherwise={renderLabel}
          >
            <Link
              to={editPath}
              className={`${styles.campaignName} ${styles.link}`}
            >
              {columnData.name}
            </Link>
          </PermissionChecker>
          <div className={styles.campaignID}>
            <div>{`ID: ${columnData.listId}`}</div>
            <div>
              <ObjectFitContainer
                key={i18n.language}
                containerHeight={16}
                onlyScaleDown={true}
                alignItems='auto'
                justifyContent='auto'
                margin={0}
              >
                {columnData.typeDes}
              </ObjectFitContainer>
            </div>
            {isDraft && (
              <div className={styles.draftIcon}>
                {i18n.t<string>('l2ObjectList.labels.draft')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  creativeFormatter = (columnData: any, campaignData: any): any => {
    const clickFunc = () => {
      if (campaignData.draftId) {
        this.props.history.replace(`${this.props.location.pathname}?draftIds=${campaignData.draftId}&action=manage`);
      } else {
        this.props.history.replace(`${this.props.location.pathname}?campaignIds=${campaignData.id}&action=manage`);
      }
    };
    const renderCheckButton = () => (
      <IconWithTooltip
        className={styles.detailBtn}
        onClick={clickFunc}
        icon={faEye}
        tooltipProps={{
          id: `campaignListCreativeViewHint-${campaignData.id}`,
          tooltip: i18n.t<string>('campaignList.labels.creativeViewHint')
        }}
      />
    );
    return (
      columnData !== undefined ?
        <div className={styles.campaignCell}>
          {columnData ? columnData : i18n.t<string>('creativeList.labels.none')}
          <PermissionChecker
            permissionAware={this.getCampaignEditPermission(campaignData)}
            renderOtherwise={renderCheckButton}
          >
            <IconWithTooltip
              placement='right'
              className={styles.campaignEditBtn}
              onClick={clickFunc}
              icon={faPencilAlt}
              tooltipProps={{
                id: `campaignListCreativeEditHint-${campaignData.id}`,
                tooltip: i18n.t<string>('campaignList.labels.creativeEditHint')
              }}
            />
          </PermissionChecker>
        </div> :
        <div />
    );
  }

  scheduleFormatter = (columnData: any, campaign: any): any => {
    return (
      columnData ?
        <div className={styles.campaignCell}>
          <div className={styles.scheduleContent}>
            <Trans i18nKey='campaignList.labels.schedule'>
              {{ start: columnData.start }}<br/>to<br/>{{ end: columnData.end }}
            </Trans>
          </div>
        </div> :
        <div />
    );
  }

  progressFormatter = (columnData: any): any => {
    return (
      columnData ?
        <div className={styles.campaignCell}>
          <div className={styles.progressContent}>
            <div className={styles.progress}>
              <Progress
                progress={columnData.executeRate}
                danger={columnData.danger}
                deepWarning={columnData.deepWarning}
                warning={columnData.warning}
              />
            </div>
            {`${columnData.spents} / ${columnData.budget}`}
            <br />
            {`${(columnData.executeRate * 100).toFixed(2)}% / ${(columnData.predictRate * 100).toFixed(2)}%`}
          </div>
        </div> :
        <div />
    );
  }

  budgetFormatter = (columnData: any, campaign: any): any => {
    if (columnData.summary) {
      return (
        <div>{columnData.summary}</div>
      );
    }
    const budgetLabel = columnData.budgetDes ? (
      <>
        {i18n.t<string>('campaignList.labels.budget')}
        <br />
      </>
    ) : undefined;
    const budgetValue = columnData.budgetDes ? (
      <>
        : {columnData.budgetDes}
        <br />
      </>
    ) : undefined;
    return (
      <div className={styles.campaignCell}>
        <div className={styles.budgetContent}>
          <div>
            {budgetLabel}
            {i18n.t<string>('campaignList.labels.distribute')}
            <br />
            {columnData.deliverTypeDes && i18n.t<string>('campaignList.labels.deliverType')}
          </div>
          <div>
            {budgetValue}
            : {columnData.distributeDes}
            <br />
            {columnData.deliverTypeDes && `: ${columnData.deliverTypeDes}`}
          </div>
        </div>
      </div>
    );
  }

  priceFormatter = (columnData: any): any => {
    return columnData.priceModel;
  }

  budgetGroupFormatter = (columnData: any, campaign: any): any => {
    if (columnData.summary) {
      return (
        <div>{columnData.summary}</div>
      );
    }
    return (
      <div className={styles.campaignCell}>
        <div className={styles.budgetContent}>
          <div>
            {i18n.t<string>('campaignList.labels.fbBudget')}
            <br />
            {i18n.t<string>('campaignList.labels.rtbBudget')}
            <br />
            {i18n.t<string>('campaignList.labels.distribute')}
            <br />
            {i18n.t<string>('campaignList.labels.autoOptimization')}
            <br />
            {columnData.deliverTypeDes && i18n.t<string>('campaignList.labels.deliverType')}
          </div>
          <div>
            : {columnData.fbBudgetDes}
            <br />
            : {columnData.rtbBudgetDes}
            <br />
            : {columnData.distributeDes}
            <br />
            : {columnData.autoOptimizationDes}
            <br />
            {columnData.deliverTypeDes && `: ${columnData.deliverTypeDes}`}
          </div>
        </div>
      </div>
    );
  }

  priceGroupFormatter = (columnData: any): any => {
    return (
      columnData ?
        <div className={styles.campaignCell}>
          <div className={styles.priceGroupContent}>
            <div className={styles.priceModelTitle}>
              {i18n.t<string>('campaignList.labels.priceModel')}
            </div>
            <div className={styles.priceModelValue}>
              {`: ${columnData.priceModel}`}
            </div>
            <div className={styles.fbPriceCapTitle}>
              {i18n.t<string>('campaignList.labels.fbPriceCap')}
            </div>
            <div className={styles.fbPriceCapValue}>
              {`: ${columnData.fbOrderPrice}`}
            </div>
            <div className={styles.rtbPriceCapTitle}>
              {i18n.t<string>('campaignList.labels.rtbPriceCap')}
            </div>
            <div className={styles.rtbPriceCapValue}>
              {`: ${columnData.rtbOrderPrice}`}
            </div>
          </div>
        </div> :
        <div />
    );
  }

  limitationFormatter = (columnData: any, campaignData: any): any => {

    return (
      columnData ?
        <LimitationTableCell summaryData={columnData} l2ChannelId={campaignData.id}/> :
        <div />
    );
  }

  trackingFormatter = (columnData: any): any => {
    const renderContent = () => {
      return Object.keys(columnData).map(key => {
        return (
          <div key={key}>{columnData[key]}</div>
        );
      });
    };
    return (
      columnData ?
        <div className={styles.campaignCell}>
          {columnData.conversionTracking || columnData.viewTrackingCodes || columnData.videoProgressTrackingCode ?
            (
              <Fragment>
                <div className={styles.trackingContent}>
                  {renderContent()}
                </div>
              </Fragment>
            ) :
            i18n.t<string>('campaignList.labels.emptyContent')
          }
        </div> :
        <div />
    );
  }

  resultsFormatter = (results, _1, _2, extraData) => {
    if (!results) {
      return <div />;
    }
    return (
      <div className={styles.resultCell}>
        <div className={styles.value}>
          {results.desc}
        </div>
        <div className={styles.objective}>
          {extraData.objective}
        </div>
      </div>
    );
  }

  performanceDataFormatter = (placement, columnData: any): any => {
    if (!columnData) {
      return <div />;
    }
    if (columnData.summary !== undefined) {
      return <div>{columnData.summary}</div>;
    }
    const value = columnData.value ? columnData.value : 0;
    return (
      <div className={styles.campaignCell}>
        <div className={styles.performanceDataContent}>
          {value.toFixed(placement)}
        </div>
      </div>
    );
  }

  dataPercentFormatter = (columnData: any): any => {
    if (columnData.summary !== undefined) {
      return <div>{`${columnData.summary}%`}</div>;
    }

    return (
      <div className={styles.campaignCell}>
        <div className={styles.performanceDataContent}>
          {`${columnData.value ? columnData.value.toFixed(2) : '0.00'}%`}
        </div>
      </div>
    );
  }

  descFormatter = (columnData: any): any => {
    if (columnData.summary !== undefined) {
      return <div>{`${columnData.summary}`}</div>;
    }

    return (
      <div className={styles.campaignCell}>
        <div className={styles.performanceDataContent}>
          {columnData.desc}
        </div>
      </div>
    );
  }

  tagsFormatter = (columnData: any): any => {
    const renderTags = () => {
      return columnData.map(tag => {
        return (
          <div key={tag}>
            {tag}
          </div>
        );
      });
    };
    return (
      columnData ?
        <div className={styles.campaignCell}>
          {columnData.length > 0 ?
            <div className={styles.tagsContent}>
              {renderTags()}
            </div> :
            i18n.t<string>('campaignList.labels.emptyContent')
          }
        </div> :
        <div />
    );
  }

  optimizationFormatter = (optimize: string, campaign: any): any => {
    const optimization = i18n.t<string>(`optimizationGoal.${optimize.toLowerCase()}`).toLowerCase();
    const bidStrategy = _.isNumber(campaign.bidPrice) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP;
    const currency = this.props.model.order.currency;
    return (
      <div className={styles.optimizationCell}>
        <div className={styles.title}>
          {i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
        </div>
        <div>:</div>
        <div>
          {optimization}
        </div>
        <div className={styles.title}>
          {i18n.t<string>('adSetSetupFlow.mainStep.field.bidStrategy')}
        </div>
        <div>:</div>
        <div>
          {i18n.t<string>(`l1Object.labels.bidStrategy.${bidStrategy.toLowerCase()}`)}
        </div>
        <div className={styles.title}>
          {i18n.t<string>('adSetSetupFlow.mainStep.field.bidControl')}
        </div>
        <div>:</div>
        <div>
          {campaign.bidPrice ?
          `${currency} ${formatPriceAny(+(campaign.bidPrice))}` :
           i18n.t<string>('common.labels.noData')
          }
        </div>
        <div className={styles.title}>
          {i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent')}
        </div>
        <div>:</div>
        <div>
          {
            campaign.priceModel === RtbCampaignPlanType.RS || campaign.priceModel === 'rb' ?
              i18n.t<string>(`rtbCampaign.billingEvent.${campaign.priceModel}.${campaign.optimize.toLowerCase()}`) :
              i18n.t<string>(`rtbCampaign.billingEvent.${campaign.priceModel}`)
          }
        </div>
      </div>
    );
  }

  floatingEditBtnsFormatter = (cell: any, campaign: any): any => {
    const campaignId: number = campaign.id;
    const draftId: number = _.get(campaign, 'draftId', 0);
    const editPath = campaign.isDraft ?
      `${this.props.match.url}/drafts/${draftId}/${this.props.model.editCampaignPath}` :
      `${this.props.match.url}/campaigns/${campaignId}/${this.props.model.editCampaignPath}`;

    const handleShowDeleteConfirmModal = campaign.isDraft ? this.showDeleteConfirmModal([], [draftId]) : this.showDeleteConfirmModal([campaignId], []);
    const handleShowManageBidWeightModal = this.showManageBidWeightModal(campaignId);
    const canDelete = campaign.isDraft ? true : this.props.model.canDeleteSelectedCampaigns([campaignId]);
    // remove dailyTargetBudget check
    const readonly = this.props.model.l1Object.objective === L1ObjectObjective.UNSPECIFIED;
    const canCopy = campaign.canNotCopyMessage === '' && !campaign.isDraft;
    const copyMessage = canCopy ? i18n.t<string>('campaignList.labels.cloneHint') : campaign.canNotCopyMessage;
    const canSplit = campaign.canNotSlitMessage === '' && !campaign.isDraft;
    const splitMessage = canSplit ? i18n.t<string>('campaignList.labels.splitHint') : campaign.canNotSlitMessage;
    const editMessage = campaign.isDraft ?
      i18n.t<string>('l2ObjectList.labels.editDraft') :
      i18n.t<string>('campaignList.labels.editHint');

    return (
      <TableRowToolBar className={styles.campaignFloatingEditArea}>
        {!readonly &&
          <PermissionChecker
            permissionAware={this.getCampaignEditPermission(campaign)}
          >
            <IconWithTooltip
              icon={faPencilAlt}
              tooltipProps={{
                id: `campaignListEditTip-${campaignId}-${draftId}`,
                tooltip: editMessage,
                link: editPath
              }}
            />
            <IconWithTooltip
              disabled={!canCopy}
              icon={faCopy}
              tooltipProps={{
                id: `campaignListCloneTip-${campaignId}-${draftId}`,
                tooltip: copyMessage,
                link: `${this.props.match.url}/campaigns/${campaignId}/${this.props.model.copyCampaignPath}`
              }}
            />
            {this.props.model.showSplitBtn &&
              <IconWithTooltip
                disabled={!canSplit}
                icon={faCut}
                tooltipProps={{
                  id: `campaignListSplitTip-${campaignId}-${draftId}`,
                  tooltip: splitMessage,
                  link: `${this.props.match.url}/campaigns/${campaignId}/split`
                }}
              />
            }
          </PermissionChecker>
        }
        <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `campaignListReportTip-${campaignId}-${draftId}`,
              link: this.props.model.getReportLink(campaign),
              tooltip: i18n.t<string>('campaignList.labels.reportHint')
            }}
          />
        </PermissionChecker>
        <PermissionChecker
          permissionAware={this.getCampaignEditPermission(campaign)}
        >
          <IconWithTooltip
            disabled={!canDelete}
            onClick={handleShowDeleteConfirmModal}
            icon={faTrashAlt}
            tooltipProps={{
              id: `campaignListCloneTip-${campaignId}`,
              tooltip: canDelete ? i18n.t<string>('campaignList.labels.deleteCampaign') : i18n.t<string>('campaignList.labels.cannotDelete')
            }}
          />
        </PermissionChecker>
        <PermissionChecker
          permissionAware={hasRoles(RoleNames.agencyAdmin)
            .and(addOnEnabled(ADDONFEATURE.CAMPAIGN.BID_WEIGHT_SETTING))
            .and(this.getCampaignEditPermission(campaign))}
        >
          <IconWithTooltip
            onClick={handleShowManageBidWeightModal}
            icon={faBalanceScaleLeft}
            tooltipProps={{
              id: `campaignListBidWeightTip-${campaignId}-${draftId}`,
              tooltip: i18n.t<string>('campaignList.labels.manageBidWeightModalTitle')
            }}
          />
        </PermissionChecker>
      </TableRowToolBar>
    );
  }

  stateFormatter = (stateData: any): any => {
    return (
      <Status
        color={stateData.color}
        label={stateData.des}
      />
    );
  }

  deliveryFormatter = (stateData: any, campaign: any): any => {
    const des = stateData.des;
    const isDraft: boolean = _.get(campaign, 'isDraft', false);

    if (isDraft) {
      return (
        <StateTableCell
          stateData={{
            des: i18n.t<string>('campaignList.labels.deactivateState'),
            color: 'black',
            extraInfo: i18n.t<string>('campaignList.labels.draftStateHint')
          }}
        />
      );
    }
    const renderHintButton = (handleHintDetail: () => void) => {
      const descriptionToolTipMap = {
        [i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendState')]: {
          id: `campaignListPmpSpacePartialSuspendHint-${campaign.id}`,
          tooltip: i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendHint')
        },
        [i18n.t<string>('campaignList.labels.pmpExpiredState')]: {
          id: `campaignListPmpExpiredHint-${campaign.id}`,
          tooltip: i18n.t<string>('campaignList.labels.pmpExpiredHint')
        }
      };
      if (descriptionToolTipMap[des]) {
        return (
          <div className={styles.campaignCell}>
            <IconWithTooltip
              className={styles.detailBtn}
              onClick={handleHintDetail}
              icon={faEye}
              tooltipProps={descriptionToolTipMap[des]}
            />
          </div>
        );
      } else {
        return <div />;
      }
    };
    const renderHintData = des === i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendState')
      ? () => {
        if (des === i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendState')) {
          const matchedSpaces = _.get(stateData, 'hintData', []);
          return (
            <>
              {i18n.t<string>('campaignList.labels.pmpSpacePartialSuspendHintTitle')}
              {_.map(matchedSpaces, (space) => (
                <div>
                  {space.toString()}
                </div>
              ))}
            </>
          );
        }
      }
      : undefined;
    return stateData ? (
      <StateTableCell
        stateData={stateData}
        renderHintButton={renderHintButton}
        renderHintData={renderHintData}
      />
    ) : <div />;
  }

  handleNavEvent = (event) => {
    event && event.stopPropagation();
  }

  renderAuditLog = () => {
    const auditLogModel = this.props.model.getAuditLogModel();
    if (!auditLogModel) {
      return <div />;
    }
    return (<AuditLog model={auditLogModel} />);
  }

  renderCampaignList = (viewModelData) => {
    const model = this.props.model;
    const rowClasses = (campaign, rowIndex) => {
      let classes: any = null;
      if (rowIndex === 0) {
        classes = styles.summaryRow;
      }

      if (model.isCampaignBudgetRemain(campaign)) {
        classes = [classes, styles.lightDanger].join(' ');
      }
      if (campaign.isDraft) {
        classes = [classes, styles.draftBg].join(' ');
      }

      return classes;
    };

    const columns = model.state.columnsToShow.map(columnToRender => {
      let column = renderColumn(model.getColumnDefinition(columnToRender), this.columnFormatterMap[columnToRender]);
      if (columnToRender === CampaignListColumns.NAME) {
        column.headerFormatter = this.nameHeaderFormatter;
      }
      return column;
    });
    return (
      <StickableBootstrapTable
        stickFirstColumn
        stickLastColumn
        keyField='listId'
        data={viewModelData}
        summaryData={model.state.summaryData}
        columns={columns}
        defaultSorted={[{
          dataField: CampaignListColumns.NAME,
          order: 'desc'
        }]}
        rowClasses={rowClasses}
        noDataIndication={i18n.t<string>('campaignList.labels.noData')}
        hidePagination={true}
      />
    );
  }

  renderCreateAndFilter = () => {
    const model = this.props.model;
    const canCreate = model.canCreateCampaign;
    const createContainerClassName = this.cssClassNames('createCampaign', 'tipContainer', {
      block: !canCreate
    });
    return (
      <Fragment>
        {model.l1Object.objective !== L1ObjectObjective.UNSPECIFIED &&
          <PermissionChecker permissionAware={this.campaignWritePermission}>
            <div className={createContainerClassName}>
              {!canCreate && model.canNotCreateMessage && <span className={styles.tooltip}>{model.canNotCreateMessage}</span>}
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={`${this.props.location.pathname}/campaigns/${model.newCampaignPath}`}
                  disabled={!canCreate}
                  className={`btn btn-tertiary btn-sm ${styles.createCampaignBtn}`}
                >
                  {i18n.t<string>('campaignList.labels.createCampaignBtn')}
                </Nav.Link>
              </Nav.Item>
            </div>
          </PermissionChecker>
        }
        {model.state.seletedTab !== CampaignListType.AUDIT_LOG &&
          <>
            <FilterMenuTab
              filterMenuTabConfigs={model.filterMenuTabConfigs}
            />
            <div className={styles.campaignListTitleFilterArea}>
              <SearchBar
                model={{
                  placeholder: i18n.t<string>('campaignList.placeholders.searchbar'),
                  search: model.handleOnSearch,
                  defaultValue: model.searchString
                }}
              />
            </div>
          </>
        }
      </Fragment>
    );
  }

  getManageCreativesPath = () => {
    const campaignIds = this.props.model.state.selectedCampaign.join(',');
    const draftIds = this.props.model.state.selectedDraft.join(',');
    let path = `${this.props.location.pathname}?`;
    if (campaignIds) {
      path = path + `campaignIds=${campaignIds}`;
    }
    if (draftIds) {
      path = campaignIds
        ? `${path}&draftIds=${draftIds}`
        : `${path}draftIds=${draftIds}`;
    }
    return path;
  }

  bindCreatives = event => {
    event && event.stopPropagation();
    this.props.history.push(`${this.getManageCreativesPath()}&action=bind`);
  }

  activateCreatives = event => {
    event && event.stopPropagation();
    this.props.history.push(`${this.getManageCreativesPath()}&action=activate`);
  }

  deactivateCreatives = event => {
    event && event.stopPropagation();
    this.props.history.push(
      `${this.getManageCreativesPath()}&action=deactivate`
    );
  }

  renderEditBar = () => {
    const model = this.props.model;
    const showMultiTypeError = !model.allSelectedCampaignSameType();
    const showUnknowTypeError = model.selectUnknowAdTypeCampaign();
    const showMultiRetailerError = !model.allSelectedCampaignSameRetail();
    const canDeleteCampaigns = model.canDeleteSelectedCampaigns(model.state.selectedCampaign);
    const selectedCount = model.state.selectedCampaign.length + model.state.selectedDraft.length;
    const canDelete = canDeleteCampaigns && selectedCount > 0;
    const dropdownContainerClassName = this.cssClassNames('tipContainer', {
      block: showMultiTypeError || showUnknowTypeError
    });
    const deleteButtonContainerClassName = this.cssClassNames('tipContainer', {
      block: !canDelete
    });
    const creativeErrorMessage = showUnknowTypeError ?
      i18n.t<string>('campaignList.labels.unknowTypeBatchEditError') :
      i18n.t<string>('campaignList.labels.batchEditMultiTypeError');
    const creativeCannotDeleteMessage = i18n.t<string>('campaignList.labels.cannotDelete');
    const handleShowDeleteConfirmModal = this.showDeleteConfirmModal(model.state.selectedCampaign, model.state.selectedDraft);
    const selectedObjects = [...model.state.selectedCampaign, ...model.state.selectedDraft];
    const requiredMinimumBudgetsHint = model.getRequiredMinimumBudgetsHint();
    return (
      <ListEditBar selectedItems={selectedObjects} handleRemoveSelect={model.handleRemoveSelect}>
        <div className={dropdownContainerClassName}>
          <div className={styles.tooltip}>
            {creativeErrorMessage}
          </div>
          <ListEditBar.Dropdown
            title={i18n.t<string>('campaignList.labels.creative')}
            id='creativeAction'
            disabled={showMultiTypeError || showUnknowTypeError}
            onClick={this.handleNavEvent}
          >
            {
              showMultiRetailerError ?
              <OverlayTrigger
                placement={'right'}
                trigger={['hover', 'focus']}
                overlay={
                  <Tooltip id={'bind-error'}>
                    {i18n.t<string>('campaignList.labels.batchBindMultiRetailerError')}
                  </Tooltip>
                }
              >
                <div>
                  <ListEditBar.Dropdown.Item disabled={true}>
                    {i18n.t<string>('campaignList.labels.bindCreative')}
                  </ListEditBar.Dropdown.Item>
                </div>
              </OverlayTrigger> :
              <ListEditBar.Dropdown.Item onClick={this.bindCreatives}>
                {i18n.t<string>('campaignList.labels.bindCreative')}
              </ListEditBar.Dropdown.Item>
            }
            <ListEditBar.Dropdown.Item onClick={this.deactivateCreatives}>{i18n.t<string>('campaignList.labels.deactivateCreative')}</ListEditBar.Dropdown.Item>
            <ListEditBar.Dropdown.Item onClick={this.activateCreatives}>{i18n.t<string>('campaignList.labels.activeCreative')}</ListEditBar.Dropdown.Item>
          </ListEditBar.Dropdown>
        </div>
        {
          !_.isEmpty(requiredMinimumBudgetsHint) ?
          <OverlayTrigger
            placement={'right'}
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={'bind-error'}>
                {requiredMinimumBudgetsHint}
              </Tooltip>
            }
          >
            <ListEditBar.Item>
              <ListEditBar.Link disabled={true}>
                {i18n.t<string>('campaignList.labels.activeCampaign')}
              </ListEditBar.Link>
            </ListEditBar.Item>
          </OverlayTrigger> :
          <ListEditBar.Item>
            <ListEditBar.Link onClick={model.activeCampaign}>
              {i18n.t<string>('campaignList.labels.activeCampaign')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        }
        <ListEditBar.Item >
          <ListEditBar.Link onClick={model.deactiveCampaign}>
            {i18n.t<string>('campaignList.labels.deactivateCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item >
          <ListEditBar.Link onClick={this.showDraftCreateModal}>
            {i18n.t<string>('campaignList.labels.duplicate')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <div className={deleteButtonContainerClassName}>
          <div className={styles.tooltip}>
            {creativeCannotDeleteMessage}
          </div>
          <ListEditBar.Item >
            <ListEditBar.Link disabled={!canDelete} onClick={handleShowDeleteConfirmModal}>
              {i18n.t<string>('campaignList.labels.deleteCampaign')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
      </ListEditBar>
    );
  }

  showDeleteConfirmModal = (campaignIds: number[], draftIds: number[]) => (event?: any) => {
    event && event.stopPropagation();
    this.props.model.setCampaignsToDelete(campaignIds);
    this.props.model.setDraftsToDelete(draftIds);
  }

  showManageBidWeightModal = (campaignId: number) => (event?: any) => {
    event && event.stopPropagation();
    this.props.model.setCampaignToChangeBidWeight(campaignId);
  }

  showDraftCreateModal = (event?: any) => {
    event && event.stopPropagation();
    this.props.model.setVisibilityOfDraftCreateModal(true);
  }

  exitDeleteProgress = () => {
    this.props.model.setCampaignsToDelete([]);
    this.props.model.setDraftsToDelete([]);
  }

  hideManageBidWeightModal = () => {
    this.props.model.setCampaignToChangeBidWeight(undefined);
  }

  hideDraftCreateModal = (dirty: boolean) => {
    this.props.model.setVisibilityOfDraftCreateModal(false);
    dirty && this.props.model.onCampaignChange();
  }

  renderDeleteConfirmModal = () => {
    const {
      deleteCampaignIds,
      deleteDraftIds
    } = this.props.model.state;

    const isCampaign = deleteCampaignIds.length > 0;
    const isDraft = deleteDraftIds.length > 0;
    const isMix = isCampaign && isDraft;
    let modalTitle = '';
    let modelContent = '';
    if (isMix) {
      modalTitle = i18n.t<string>('campaignList.labels.mixDeleteModalTitle');
      modelContent = i18n.t<string>('campaignList.labels.mixDeleteModalContent');
    } else if (isCampaign) {
      modalTitle = i18n.t<string>('campaignList.labels.deleteCampaignModalTitle');
      modelContent = i18n.t<string>('campaignList.labels.deleteCampaignModalContent');
    } else {
      modalTitle = i18n.t<string>('campaignList.labels.deleteDraftModalTitle');
      modelContent = i18n.t<string>('campaignList.labels.deleteDraftModalContent');
    }
    return (
      <Modal
        title={modalTitle}
        secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: this.exitDeleteProgress }}
        primaryButton={{ title: i18n.t<string>('campaignList.labels.deleteCampaign'), callback: this.props.model.delete }}
        dismiss={this.exitDeleteProgress}
      >
        {_.map(modelContent.split(/\r?\n/), (hint, index) => (
          <div key={index}>{hint}</div>
        ))}
      </Modal>
    );
  }

  renderManageBidWeightModal = () => {
    const campaign = this.props.model.state.viewModelData.find(campaign => campaign.id === this.props.model.state.campaignToChangeBidWeight);
    const inputRef = React.createRef<any>();
    const updateBidWeight = () => {
      if (!inputRef.current) {
        return;
      }
      this.props.model.updateBidWeight(inputRef.current.value);
    };
    return (
      <Modal
        title={i18n.t<string>('campaignList.labels.manageBidWeightModalTitle')}
        primaryButton={{ title: i18n.t<string>('common.buttons.confirm'), callback: updateBidWeight }}
        secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: this.hideManageBidWeightModal }}
        dismiss={this.hideManageBidWeightModal}
      >
        <input
          ref={inputRef}
          defaultValue={campaign.magnificationRatio}
          type='number'
        />
      </Modal>
    );
  }

  renderDraftCreateModal = () => {
    const model = this.props.model;
    if (!model.l1Object) {
      return <div />;
    }
    const campaignsToCreateDraft = model.campaignList
      .filter(campaign =>
        (!campaign.isDraft && campaign.id && model.state.selectedCampaign.includes(campaign.id)) ||
        (campaign.draftId && model.state.selectedDraft.includes(campaign.draftId)))
      .map(campaign => {
        return campaign.isDraft ? {
          id: campaign.draftId ? campaign.draftId : 0,
          isDraft: true
        } : {
          id: campaign.id ? campaign.id : 0,
          isDraft: false
        };
      });
    return (
      <DraftCreateModal
        order={model.order}
        l1Object={model.l1Object}
        l2ObjectsToCreateDraft={campaignsToCreateDraft}
        onClose={this.hideDraftCreateModal}
      />
    );
  }

  render = () => {
    const model = this.props.model;
    const viewModelData = model.state.viewModelData;
    const selectedObjects = [...model.state.selectedCampaign, ...model.state.selectedDraft];
    const hasSelectCampaign = selectedObjects.length > 0;
    return (
      <div className={styles.campaignList}>
        {model.state.loading && <LoadingIndicator />}
        <div className={styles.campaignListTitleBar}>
          {hasSelectCampaign ? this.renderEditBar() : this.renderCreateAndFilter()}
        </div>
        <div className={styles.contentArea}>
          <div className={styles.metricsOptionsContainer}>
            <Select
              className={styles.metricsOptions}
              options={model.getTabs()}
              name='metricsGroupOptions'
              simpleValue
              value={model.state.seletedTab}
              onChange={model.showTable}
            />
          </div>
          <div className={styles.listContainer}>
            {model.state.seletedTab === CampaignListType.AUDIT_LOG ?
              this.renderAuditLog() :
              this.renderCampaignList(viewModelData)
            }
          </div>
        </div>
        {model.state.showDraftCreateModal &&
          this.renderDraftCreateModal()
        }
        {(model.state.deleteCampaignIds.length > 0 || model.state.deleteDraftIds.length > 0) &&
          this.renderDeleteConfirmModal()
        }
        {model.state.campaignToChangeBidWeight &&
          this.renderManageBidWeightModal()
        }
      </div>
    );
  }
}
