import { FormConfig } from 'components/common/form/FormConfig';
import { useFormikContext } from 'formik';
import { BasicOutdoorCreativeProperties } from 'core/creative/OutdoorCreative';
import { validateEmpty } from 'utils/ValidateUtils';
import { useCallback, useMemo, useState } from 'react';
import { BeaconType } from 'core/advertiser/BeaconConnection';
import styles from './outdoorFields.module.scss';
import i18n from 'i18n';
import cx from 'classnames/bind';
import { FormikCustomRenderProps } from 'components/common/form/field/CustomField';
import { get } from 'lodash';
import { LineMessageType } from 'core/advertiser/LineBeacon';
import { renderOutdoorLineMessageFields } from './OutdoorLineMessageFields';

const classNames = cx.bind(styles);

export const useOutdoorLineFields = (beaconConnectionOptions?: SelectOptions[]) => {

  const { values, setFieldValue } = useFormikContext<{
    typeProperties: BasicOutdoorCreativeProperties & {
      intervalHours: number;
      maxFrequency: number;
      beaconConnection?: SelectOptions;
    };
  }>();

  const {
    intervalHours,
    maxFrequency,
    beaconConnection: selectBeaconConnection
  } = values.typeProperties;

  const beaconConnection = useMemo(
    () =>
      selectBeaconConnection && beaconConnectionOptions ? beaconConnectionOptions.find(
        beaconConnection =>
          beaconConnection.value.toString() === selectBeaconConnection.value.toString()
      ) : undefined,
    [beaconConnectionOptions, selectBeaconConnection]
  );

  const [showLineConfig, setShowLineConfig] = useState<boolean>(
    beaconConnection !== undefined && beaconConnection.extra === BeaconType.LINE_SIMPLE_BEACON
  );
  const [showFrequencyCapSetting, setShowFrequencyCapSetting] =
    useState<boolean>(intervalHours > 0 && maxFrequency > 0);

  const validate = useCallback((value: string) => {
    if (!showLineConfig) {
      return;
    }
    return validateEmpty(value);
  }, [showLineConfig]);

  const onLineBeaconConfigSwitchChange = useCallback((event) => {
    const value = event.target.checked;
    setShowLineConfig(value);
    if (!value) {
      setFieldValue('typeProperties.beaconConnection', undefined);
      setFieldValue('typeProperties.intervalHours', undefined);
      setFieldValue('typeProperties.maxFrequency', undefined);
      setFieldValue('lineMessage', undefined);
    } else {
      setFieldValue('typeProperties.intervalHours', 0);
      setFieldValue('typeProperties.maxFrequency', 0);
      setFieldValue('lineMessage.0.type', LineMessageType.TEXT);
    }
  }, [setFieldValue]);

  const onFrequencyCapSwitchChange = useCallback((event) => {
    const value = event.target.checked;
    setShowFrequencyCapSetting(value);
    if (!value) {
      setFieldValue('typeProperties.intervalHours', 0);
      setFieldValue('typeProperties.maxFrequency', 0);
    } else {
      setFieldValue('typeProperties.intervalHours', 5);
      setFieldValue('typeProperties.maxFrequency', 1);
    }
  }, [setFieldValue]);

  const renderFrequencyControlInput = useCallback(
    (
      props: FormikCustomRenderProps
    ) => {
      const onMaxFrequencyChange = e => {
        props.setFieldValue(
          'typeProperties.maxFrequency',
          e.target.value < 0 ? 0 : e.target.value
        );
      };
      const onIntervalHoursChange = e => {
        props.setFieldValue(
          'typeProperties.intervalHours',
          e.target.value < 0 ? 0 : e.target.value
        );
      };
      const errorMaxFrequency = get(
        props.errors,
        'typeProperties.maxFrequency'
      );
      const errorintervalHours = get(
        props.errors,
        'typeProperties.intervalHours'
      );
      const maxFrequencyClass = classNames('maxFrequency', {
        error: errorMaxFrequency
      });
      const intervalHoursClass = classNames('intervalHours', {
        error: errorintervalHours
      });
      const timeUnitKey = +intervalHours > 1 ? 'hours' : 'hour';
      const cappingCountKey = +maxFrequency > 1 ? 'cappingCounts' : 'cappingCount';
      return (
        <div className={styles.frequencyControl}>
          <div className={styles.intervalHoursContainer}>
            {i18n.t<string>('lineFields.labels.frequencyCap.every')}
            <input
              className={intervalHoursClass}
              name='frequency.intervalHours'
              value={intervalHours}
              onChange={onIntervalHoursChange}
              min={1}
              type='number'
            />
            {i18n.t<string>(`common.units.${timeUnitKey}`)}
          </div>
          <div className={styles.maxFrequencyClassContainer}>
            {i18n.t<string>('lineFields.labels.frequencyCap.send')}
            <input
              className={maxFrequencyClass}
              value={maxFrequency}
              name='typeProperties.maxFrequency'
              onChange={onMaxFrequencyChange}
              min={1}
              type='number'
            />
            {i18n.t<string>(`lineFields.labels.frequencyCap.${cappingCountKey}`)}
          </div>
          {errorMaxFrequency && (
            <div className='errorTip'>{errorMaxFrequency}</div>
          )}
          {errorintervalHours && (
            <div className='errorTip'>{errorintervalHours}</div>
          )}
        </div>
      );
    },
    [intervalHours, maxFrequency]
  );

  const fields = useMemo(() => {
    const builder = new FormConfig.FieldsBuilder().addSwitch({
      label: i18n.t<string>('lineFields.labels.lineBeaconConfigSwitch'),
      name: 'lineConfigSwith',
      value: showLineConfig,
      onChange: onLineBeaconConfigSwitchChange
    });

    if (!showLineConfig) {
      return builder.build();
    }

    builder
      .addFormikSelect({
        label: i18n.t<string>('lineFields.labels.beaconConnection'),
        name: 'typeProperties.beaconConnection',
        options: beaconConnectionOptions || [],
        validate
      })
      .addSwitch({
        label: i18n.t<string>('lineFields.labels.frequencyCapSwitch'),
        name: 'frequencyCapSwitch',
        value: showFrequencyCapSetting,
        onChange: onFrequencyCapSwitchChange
      });

    if (showFrequencyCapSetting) {
      builder.addFormikCustom({
        label: '',
        name: 'frequencyControl',
        render: renderFrequencyControlInput
      });
    }

    builder.addFormikCustom({
      withFieldLayout: false,
      name: 'lineMessageContainer',
      render: renderOutdoorLineMessageFields
    });

    return builder.build();
  }, [
    showLineConfig,
    showFrequencyCapSetting,
    beaconConnectionOptions,
    validate,
    onLineBeaconConfigSwitchChange,
    onFrequencyCapSwitchChange,
    renderFrequencyControlInput
  ]);

  return fields;
};
