import React, { useCallback, useMemo, useRef } from 'react';
import { Formik, Form } from 'formik';
import { ErrorHandler } from 'components/common/form/FormErrorHandler/FormErrorHandler';
import { CreativeBasicForm } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/CreativeBasicForm';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { DefaultCreativeBasicFormModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/CreativeBasicFormModel';
import { DefaultCreativeManager } from 'core/creative/CreativeManager';
import styles from './creativeSetupStep.module.scss';
import { CreateCreativeInL2ObjectSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CreativeType } from 'core/creative/Creative';

export const CreativeSetupStep: React.FC<{
  forPmp: boolean;
  model: CreateCreativeInL2ObjectSetupFlowPageModel;
  goLast: () => void;
  goNext: (cb?: () => void) => void;
}> = ({
  forPmp,
  model,
  goNext,
  goLast
}) => {

  const mainDomRef = useRef(null);

  const handleSubmit = useCallback((values) => {
    goNext(() => {
      model.setCreative({
        basic: values
      });
    });
  }, [model, goNext]);

  const basicFormModel = useMemo(
    () =>
      model.creative
        ? new DefaultCreativeBasicFormModel(
            'create',
            model.creative.basic,
            model.tenmaxCategories,
            new DefaultCreativeManager(),
            model.supportedCreativeType,
            () => {},
            (creativeType: CreativeType) =>
              model.getFormContentModelOfType(
                creativeType,
                model.creative!.basic.advertiserId!
              ),
            () => {},
            forPmp
          )
        : undefined,
    [forPmp, model]
  );

  if (!basicFormModel) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.setupStep} ref={mainDomRef}>
      <Formik
        initialValues={basicFormModel.initCreative}
        enableReinitialize
        onSubmit={handleSubmit}
        onValidate={basicFormModel.validate}
        validateOnBlur={false}
      >
        {formikProps => {
          basicFormModel.setFormikProps(formikProps);
          return (
            <Form>
              <ErrorHandler
                parentRef={mainDomRef}
                isSubmitting={formikProps.isSubmitting}
                submitCount={formikProps.submitCount}
              />
              <CreativeBasicForm
                model={basicFormModel}
              />
              <div className={styles.buttonArea}>
                <Button
                  variant='primary'
                  size='sm'
                  type='submit'
                >
                  {i18n.t<string>('campaign.buttons.completeAndCheck')}
                </Button>
                <Button variant='secondary' size='sm' onClick={goLast}>
                  {i18n.t<string>('campaign.buttons.back')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
