import { Modal } from 'components/common/Modal/Modal';
import i18n from 'i18n';
import { BeaconConnectionFormModel } from './BeaconConnectionFormModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Form } from 'components/common/form/Form';
import { Formik, FormikProps } from 'formik';
import { useCallback } from 'react';
import { BeaconConnectionFormData } from 'core/advertiser/BeaconConnection';
import { BeaconConnectionFormContent } from './BeaconConnectionFormContent';

export type BeaconConnectionFormProps = {
  useModel: () => BeaconConnectionFormModel;
  onCancel: () => void;
};

export const BeaconConnectionForm: React.FC<BeaconConnectionFormProps> = ({
  useModel,
  onCancel
}) => {

  const { title, loading, initBeaconConnection, onSave } = useModel();

  const renderForm = useCallback(
    (formikProps: FormikProps<BeaconConnectionFormData>) => {
      const submitForm = () => formikProps.submitForm();
      return (
        <Modal
          fullScreen={true}
          title={title}
          primaryButton={{
            title: i18n.t<string>('common.buttons.submit'),
            callback: submitForm
          }}
          secondaryButton={{
            title: i18n.t<string>('common.buttons.close'),
            callback: onCancel
          }}
          dismiss={onCancel}
          animation={false}
        >
          <Form
            formikProps={formikProps}
            withNavigationPrompt
          >
            <BeaconConnectionFormContent />
          </Form>
        </Modal>
      );
    },
    [title, onCancel]
  );

  return (
    <>
      {loading && <LoadingIndicator />}
      <Formik
        initialValues={initBeaconConnection}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        {renderForm}
      </Formik>
    </>
  );
};
