import { Link } from 'react-router-dom';
import styles from './nameWithIdColumn.module.scss';
import classNames from 'classnames/bind';
import { useCallback } from 'react';

export type NameWithIdColumnProps = {
  id: string | number;
  name: string;
} & (
  | {
    clickable: true;
    clickUrl: string;
    onClick?: undefined;
  }
  | {
    clickable: true;
    clickUrl?: undefined;
    onClick: () => void;
  }
  | {
    clickable: false;
    clickUrl?: undefined;
    onClick?: undefined;
  }
);

const cx = classNames.bind(styles);

export const NameWithIdColumn = ({
  id,
  name,
  clickUrl,
  clickable,
  onClick
}: NameWithIdColumnProps) => {

  const renderName = useCallback(() => {
    const nameClass = cx('name', {
      active: clickable,
      link: !!clickUrl
    });

    if (!clickable) {
      return <span className={nameClass}>{name}</span>;
    }
    if (onClick) {
      return (
        <div
          className={nameClass}
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        >
          {name}
        </div>
      );
    }
    if (clickUrl) {
      return (
        <Link to={clickUrl} className={nameClass}>
          {name}
        </Link>
      );
    }
  }, [clickUrl, clickable, onClick, name]);

  return (
    <div className={styles.info}>
      {renderName()}
      <div className={styles.id}>{'ID: ' + id}</div>
    </div>
  );
};
