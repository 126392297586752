import React from 'react';
import styles from './auditLogFormatters.module.scss';
import _ from 'lodash';
import i18n from 'i18n';
import { State } from 'core/order/Order';
import { AUDITLOG_SRC } from 'core/auditLog/AuditLog';
import { DeliverType, CampaignState, CreativeDeliverType } from 'core/rtbCampaign/RtbCampaign';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import moment from 'moment';
import { CreativeType } from 'core/creative/Creative';
import { AgencyRole } from 'core/companyMember/CompanyRole';

const srcInfoFormatter = (srcInfo: {
  id: number,
  type: string,
  name?: string
}) => {
  return (
    <div className={styles.srcInfo}>
      {srcInfo.name ?
        <div>{i18n.t<string>(`auditLog.labels.src${srcInfo.type}`) + ':' + srcInfo.name}</div> :
        <div>{i18n.t<string>(`auditLog.labels.src${srcInfo.type}`)}</div>
      }
      <div className={styles.bottomInfo}>
        {`ID: ${srcInfo.id}`}
      </div>
    </div>
  );
};

const i18nFormatter = (i18nKey) => {
  return (
    <>
      {i18n.t<string>(i18nKey)}
    </>
  );
};

function getAdTypeValue (adType) {
  const key = `campaignSummary.labels.adType${_.upperFirst(_.camelCase(adType))}`;
  if (!i18n.exists(key)) {
    return adType;
  }

  return i18n.t<string>(key);
}

function getDeliverTypeValue (deliverType) {
  switch (deliverType) {
    case DeliverType.ACCELERATED:
      return i18n.t<string>('campaignSummary.labels.accelerated');
    default:
      return i18n.t<string>('campaignSummary.labels.standard');
  }
}

function getCreativeDeliverTypeValue (creativeDeliverType: number) {
  return i18n.t<string>(`campaign.labels.creativeDeliverType.${CreativeDeliverType[creativeDeliverType].toLowerCase()}`);
}

function getDayPartValue (orgValue: string) {
  try {
    const dayPart = JSON.parse(orgValue);
    let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
    return (
      <div style={{ whiteSpace: 'pre' }}>
        {
          Object.keys(dayPartValue).map(day => {
            return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
          }).join('\r\n')
        }
      </div>
    );
  } catch (e) {
    return orgValue.toString();
  }
}

function getFrequencyValue (orgValue) {
  try {
    const frequencyControl = JSON.parse(orgValue);
    if (!frequencyControl.intervalDays && !frequencyControl.maxFrequency) {
      return i18n.t<string>('auditLog.labels.frequencyNoEnable');
    }
    return (
      i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyControl', {
        event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
        eventPlural: +(frequencyControl.maxFrequency) > 1 ? 's' : '',
        interval_days: frequencyControl.intervalDays,
        max_frequency: frequencyControl.maxFrequency,
        unit: i18n.t<string>('common.units.day'),
        unitPlural: +(frequencyControl.intervalDays) > 1 ? 's' : ''
      })
    );
  } catch (e) {
    return orgValue.toString();
  }
}

function getAdLogoValue (value) {
  try {
    const adLogo = JSON.parse(value);
    if (adLogo.type === 'NULL') {
      return i18n.t<string>('common.labels.noData');
    }
    if (adLogo.type === 'DEFAULT') {
      return i18n.t<string>('common.labels.default');
    }
    if (adLogo.type === 'CUSTOM') {
      return adLogo.imgUrl;
    }
    return value.toString();
  } catch (e) {
    return value.toString();
  }
}

function getCampaignBidPriceValue (value) {
  const renderBidPriceSetting = (bidPriceSetting: any) => {
    return (
      <div className={styles.bidPriceContainer}>
        {Object.keys(bidPriceSetting).map((optimize, index) => {
          return (
            <div key={index} className={styles.bidPrice}>
              <span>{i18n.t<string>(`bidPriceSetting.optimize.${optimize.toLowerCase()}`)}</span>
              <span>{bidPriceSetting[optimize]}</span>
            </div>
          );
        })}
      </div>
    );
  };
  try {
    const campaignBidPrice = JSON.parse(value);
    return campaignBidPrice.map((bidPrice, index) => {
      const autoBidCap = _.get(bidPrice, 'autoBidCap', {});
      const bidFloor = _.get(bidPrice, 'bidFloor', {});
      const type = _.get(bidPrice, 'type', '');
      return (
        <div key={index} className={styles.campaignBidPrice}>
          <div>{i18n.t<string>(`bidPriceSetting.labels.${_.camelCase(type)}`)}</div>
          <div className={styles.settings}>
            <span>{i18n.t<string>('bidPriceSetting.labels.autoBidCapSetting')}</span>
            {renderBidPriceSetting(autoBidCap)}
          </div>
          <div className={styles.settings}>
            <span>{i18n.t<string>('bidPriceSetting.labels.bidFloorSetting')}</span>
            {renderBidPriceSetting(bidFloor)}
          </div>
        </div>
      );
    });
  } catch (e) {
    return value.toString();
  }
}

function getAgencyRoleValue (value) {
  try {
    return i18n.t<string>(`agency.labels.${AgencyRole[value].toLowerCase()}`);
  } catch (e) {
    return value.toString();
  }
}

function getViewTrackingCodesValue (value) {
  try {
    const trackingCodes = JSON.parse(value);
    return trackingCodes.map((code, index) => {
      return <div key={index}>{code.trackingCode}</div>;
    });
  } catch (e) {
    return value.toString();
  }
}

function isValueEmpty (value) {
  if (_.isNil(value) || value === '' || value === '{}' || value === 'null') {
    return true;
  }

  return false;
}

function getVideoAdViewObjectiveValue (value) {
  try {
    const videoAdViewObjective = JSON.parse(value);
    if (videoAdViewObjective.event) {
      return i18n.t<string>('campaignSummary.labels.videoAdViewObjectiveMetric', { metric: i18n.t<string>(`campaign.labels.${videoAdViewObjective.event.toLowerCase()}`) });
    }
  } catch (e) {
    return value.toString();
  }
}

function getVideoProgressTrackingCodeValue (value) {
  try {
    const videoProgressTrackingCode = JSON.parse(value)[0];
    return (
      <div>
        <div className={styles.videoProgressTrackingCode}>
          <div>{i18n.t<string>('campaignSummary.labels.videoProgressTrackingCode')}: </div>
          <div>{videoProgressTrackingCode.code}</div>
        </div>
        <div>{i18n.t<string>('campaignSummary.labels.videoProgressTrackingOffset')}: {`${videoProgressTrackingCode.offset} ${i18n.t<string>('campaign.labels.sec')}`}</div>
      </div>
    );
  } catch (e) {
    return value.toString();
  }
}

export const getAddonPropsLabel = (addonItem: string): any => {
  if (addonItem === 'targetBudgetMinimum') {
    return i18n.t<string>('agency.targetBudgetMinimum');
  }
  if (addonItem === 'campaignBudgetMinimum') {
    return i18n.t<string>('agency.campaignBudgetMinimum');
  }
  if (addonItem === 'orderBudgetMaximum') {
    return `${i18n.t<string>('agency.addonGroup.company')}` +
    `[${i18n.t<string>('agency.addonItem.company.singleOrderBudgetConstraint')}${i18n.t<string>('agency.addonItem.company.orderBudgetMaximum')}]`;
  }
  if (addonItem === 'creditLimit') {
    return `${i18n.t<string>('agency.addonGroup.company')}` +
    `[${i18n.t<string>('agency.addonItem.company.creditLimitMaximum')}]`;
  }
  if (addonItem === 'sysProfitMonitorPercent') {
    return `${i18n.t<string>('agency.addonGroup.monitor')}` +
    `[${i18n.t<string>('agency.addonItem.monitor.percentLabel')}]`;
  }
  if (addonItem === 'maxOrderProfit') {
    return i18n.t<string>('agency.addonProps.maxOrderProfit');
  }
  if (addonItem === 'fixedExchangeRate') {
    return i18n.t<string>('agency.addonGroup.company') +
    `[${i18n.t<string>('agency.addonItem.company.fixedExchangeRate')}]`;
  }
  if (addonItem === 'campaignBidPrice') {
    return i18n.t<string>('agency.addonGroup.campaign') +
    `[${i18n.t<string>('agency.addonItem.campaign.bidPriceConstraint')}]`;
  }
  if (addonItem === 'agencySegmentLimitationName') {
    return i18n.t<string>('agency.addonGroup.company') +
    `[${i18n.t<string>('agency.addonItem.company.agencySegmentLimitationName')}]`;
  }
  if (addonItem === 'picMargin') {
    return i18n.t<string>('agency.addonGroup.channel') +
    `[${i18n.t<string>('agency.addonItem.channel.pic')}-${i18n.t<string>('agency.addonItem.channel.salesChannelMargin')}]`;
  }
  if (addonItem === 'edimaxMargin') {
    return i18n.t<string>('agency.addonGroup.channel') +
    `[${i18n.t<string>('agency.addonItem.channel.ediMax')}-${i18n.t<string>('agency.addonItem.channel.salesChannelMargin')}]`;
  }
  if (addonItem === 'poyaMargin') {
    return i18n.t<string>('agency.addonGroup.channel') +
    `[${i18n.t<string>('agency.addonItem.channel.poya')}-${i18n.t<string>('agency.addonItem.channel.salesChannelMargin')}]`;
  }
};

export const getAgencyLogLabel = (field: string): string => {
  let label = '';
  const i18nKeys = field.split('.');
  if (i18nKeys.length === 1) {
    label = i18n.t<string>(`auditLog.labels.${field}`);
  } else {
    if (field.indexOf('addonFeatures.') >= 0) {
      // pattern: `addonFeatures.${addonGroup}.${addonName}`
      const addonGroup = i18nKeys[1];
      const addonItem = i18nKeys[2];
      const addonName = addonGroup === 'creatives' && addonItem.indexOf('option_') >= 0
       ? `option_${_.camelCase(CreativeType[addonItem.split('_')[1]])}`
       : i18nKeys[2];
      label = i18n.t<string>(`agency.addonGroup.${addonGroup}`) + `[${i18n.t<string>(`agency.addonItem.${addonGroup}.${addonName}`)}]`;
    } else if (field.indexOf('property.') >= 0) {
      // pattern: `property.${propertyName}` || `property.addonProps.${propertyName}`
      const addonName = i18nKeys[i18nKeys.length - 1];
      label = getAddonPropsLabel(addonName);
    }
  }
  return label;
};

function getValue (value, type?: any, key?: any) {
  if (isValueEmpty(value)) {
    return i18n.t<string>('common.labels.noData');
  }

  if (type === AUDITLOG_SRC.ORDER && key === 'state') {
    if (State[value] && typeof value === 'number') {
      return i18n.t<string>(`order.status.${State[value].toLowerCase().replace(/_/g, '')}`);
    }
    return value;
  }

  if (type === AUDITLOG_SRC.CAMPAIGN && key === 'state') {
    if (CampaignState[value] && typeof value === 'number') {
      return i18n.t<string>(`campaignList.labels.${CampaignState[value].toLowerCase()}State`);
    }
    return value;
  }

  if (type === AUDITLOG_SRC.CAMPAIGN) {
    switch (key) {
      case 'adLogo':
        return getAdLogoValue(value);
      case 'adType':
        return getAdTypeValue(value);
      case 'deliverType':
        return getDeliverTypeValue(value);
      case 'creativeDeliverType':
        return getCreativeDeliverTypeValue(value);
      case 'dayPart':
        return getDayPartValue(value);
      case 'frequency':
        return getFrequencyValue(value);
      case 'startDate':
      case 'endDate':
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      case 'priceModel':
        return i18n.t<string>(`campaign.labels.${value}`);
      case 'viewTrackingCodes':
        return getViewTrackingCodesValue(value);
      case 'videoProgressTrackingCode':
        return getVideoProgressTrackingCodeValue(value);
      case 'videoAdViewObjective':
        return getVideoAdViewObjectiveValue(value);
    }
  }

  if (type === AUDITLOG_SRC.AGENCY) {
    switch (key) {
      case 'adLogo':
        return getAdLogoValue(value);
      case 'property.addonProps.campaignBidPrice':
        return getCampaignBidPriceValue(value);
    }
  }

  if (type === AUDITLOG_SRC.COMPANY_ROLE_MAP) {
    switch (key) {
      case 'roleName':
        return getAgencyRoleValue(value);
    }
  }

  if (Array.isArray(value)) {
    return value.join(',');
  }

  return value.toString();
}

const detailExpandRenderer = (audiLogRow) => {
  if (!audiLogRow.msg) {
    return <div/>;
  }
  let detailDataArray = audiLogRow.msg;
  if (!Array.isArray(audiLogRow.msg) && typeof audiLogRow.msg === 'object') {
    detailDataArray = Object.keys(audiLogRow.msg).map(key => {
      const value = audiLogRow.msg[key];
      const values = value.split('=>');
      return {
        [key]: {
          org: values[0].trim(),
          to: values[1].trim()
        }
      };
    });
  }
  const detail = detailDataArray.map(data => {
    const keys = Object.keys(data);
    const key = keys[0];
    const value = data[keys[0]];
    if (isValueEmpty(value.org) && isValueEmpty(value.to)) {
      return <div key={key}/>;
    }
    let label = i18n.t<string>(`auditLog.labels.${key}`);
    if (audiLogRow.funcType === 'auditLog.limitation.create') {
      const i18nKeys = key.split('.');
      label = i18n.t<string>(`limitation.labels.${i18nKeys[0]}`) + `(${i18n.t<string>(`limitation.${i18nKeys[1]}`)})`;
    } else if (key.indexOf('_value') >= 0) {
      const i18nKeys = key.split('_');
      label = i18n.t<string>(`limitation.labels.${i18nKeys[0]}`);
    }
    if (audiLogRow.srcID.type === AUDITLOG_SRC.AGENCY) {
      label = getAgencyLogLabel(key);
    }
    return (
      <div className={styles.field} key={key}>
        <div className={styles.fieldLabel}>{label}</div>
        <div className={styles.from}>
          <div>{i18n.t<string>('auditLog.labels.from')}</div>
          <div>:</div>
          <div>{getValue(value.org, audiLogRow.srcID.type, key)}</div>
        </div>
        <div className={styles.to}>
          <div>{i18n.t<string>('auditLog.labels.to')}</div>
          <div>:</div>
          <div>{getValue(value.to, audiLogRow.srcID.type, key)}</div>
        </div>
      </div>
    );
  });
  return (
    <div className={styles.detailRenderer}>
      {detail}
    </div>
  );
};

const formatters = {
  srcInfoFormatter,
  detailExpandRenderer,
  i18nFormatter
};

export default formatters;
