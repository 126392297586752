import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './customOutdoorDevice.module.scss';
import { SelectComponent, SelectComponentModel } from '../SelectComponent';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { MapContainer } from './MapContainer';
import classnames from 'classnames/bind';
import { LimitationValue } from 'containers/Limitations/LimitationSetting/LimitationSetting';
import { MachineCustomInputComponentModel } from '../CustomInputComponent/CustomInputComponentModel';
import _, { LoDashStatic } from 'lodash';
import { Device, DeviceDataContext, DeviceMap, SpaceAttribute, SpaceAttributeMap, SpaceAttributes, SpaceDetailMap } from './DeviceDataContext';
import { FilterItemComponent } from './FilterItemComponent';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { OutdoorSpaceChannel } from 'core/limitation/ProductGroup';

const cx = classnames.bind(styles);

export type CustomOutdoorDeviceProps = {
  model: SelectComponentModel;
  limitationValuesObj: { [type: string]: LimitationValue };
  onChange: (type: string, value: SelectOptions[] | LoDashStatic) => void;
};

export const CustomOutdoorDevice: React.FC<CustomOutdoorDeviceProps> = ({
  model,
  limitationValuesObj,
  onChange
}) => {

  const customInputModel = model.customInputModel as MachineCustomInputComponentModel;
  const {
    spaceAttributes,
    spaceAttributeMap,
    deviceMap,
    spaceDetailMap,
    spaceChannel,
    fetchMaps
  }: {
    spaceAttributes: SpaceAttributes;
    spaceAttributeMap: SpaceAttributeMap;
    deviceMap: DeviceMap;
    spaceDetailMap: SpaceDetailMap;
    spaceChannel: keyof OutdoorSpaceChannel;
    fetchMaps: () => Promise<void>;
  } = customInputModel;
  const [showMap, setShowMap] = useState<boolean>(false);
  const [filterMap, setFilterMap] = useState<{
    [attributeType: string]: {
      [spaceAttribute: string]: SpaceAttribute
    }
  }>({});
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const triggerMapVisibility = useCallback(() => {
    setShowMap(prev => !prev);
  }, []);

  const selectedDevices: Device[] = useMemo(() => {
    let selectedValue: SelectOptions[] = _.defaultTo(model.state.selectedValue, []); // 已選擇的 自訂機台 devices
    let newDevices: Device[] = []; // 不同的 attributeType 下的 devices 是用 intersection 處理
    for (const attributeType of _.keys(spaceAttributeMap)) {
      if (!limitationValuesObj[attributeType]) continue;
      let attributeDevices: Device[] = []; // 同一個 attributeType 下的 devices 是用 union 處理
      const limitationSpaceAttributes: SelectOptions[] = limitationValuesObj[attributeType].value;
      for (const limitationSpaceAttribute of limitationSpaceAttributes) {
        const devices = spaceAttributeMap[attributeType][limitationSpaceAttribute.value.toString()];
        attributeDevices = _.unionBy(attributeDevices, devices, 'value');
      }
      newDevices = _.isEmpty(newDevices)
      ? [...attributeDevices]
      : spaceAttributes[attributeType] // 只有 ssp 來的 attributeType 會需要用 intersection 處理
        ? _.intersectionBy(newDevices, attributeDevices, 'value')
        : _.unionBy(newDevices, attributeDevices, 'value');
    }
    return _.unionBy(newDevices, selectedValue, 'value');
  }, [spaceAttributes, spaceAttributeMap, limitationValuesObj, model.state.selectedValue]);

  const filteredDevices: Device[] = useMemo(() => {
    let newDevices: Device[] = [];
    _.forEach(_.keys(filterMap), (attributeType: string) => {
      const filteredSpaceAttributes = _.keys(filterMap[attributeType]);
      _.forEach(filteredSpaceAttributes, (spaceAttribute: string) => {
        const devices: Device[] = spaceAttributeMap[attributeType][spaceAttribute];
        newDevices = _.unionBy(newDevices, devices, 'value');
      });
    });
    return newDevices;
  }, [filterMap, spaceAttributeMap]);

  const handleRemoveDevice = useCallback((device: Device) => {
    const relatedAttributes = _.defaultTo(deviceMap[device.value], {});
    const uncategorizedDevices: Device[] = _.filter(selectedDevices, (selectedDevice: Device) => selectedDevice.value !== device.value);
    for (const [attributeType, { options: spaceAttributes }] of _.entries(relatedAttributes)) {
      if (!limitationValuesObj[attributeType]) continue;
      const limitationSpaceAttributes = limitationValuesObj[attributeType].value;
      const remainedSpaceAttributes = _.differenceBy(limitationSpaceAttributes, spaceAttributes, 'value');
      const remainedDevices = _.reduce(remainedSpaceAttributes, (acc: Device[], spaceAttribute: SpaceAttribute) =>
        _.unionBy(acc, spaceAttributeMap[attributeType][spaceAttribute.value], 'value'), [] as Device[]);
      _.pullAllBy(uncategorizedDevices, remainedDevices, 'value');
      onChange(attributeType, remainedSpaceAttributes);
    }
    for (const device of uncategorizedDevices) {
      model.onItemChange(device, device);
    }
  }, [model, deviceMap, limitationValuesObj, onChange, selectedDevices, spaceAttributeMap]);

  const handleFilterCallback = useCallback((attributeType: string, spaceAttribute: SpaceAttribute) => {
    setFilterMap(prev => {
      const prevSpaceAttributeMap = _.defaultTo(prev[attributeType], {});
      let newSpaceAttributeMap = prevSpaceAttributeMap[spaceAttribute.value] ? _.omit(prevSpaceAttributeMap, spaceAttribute.value) : { ...prevSpaceAttributeMap, [spaceAttribute.value]: spaceAttribute };
      if (!_.isEmpty(spaceAttribute.options)) {
        _.each(spaceAttribute.options, (option: SelectOptions) => {
          newSpaceAttributeMap[spaceAttribute.value] ? newSpaceAttributeMap[option.value] = option : newSpaceAttributeMap = _.omit(newSpaceAttributeMap, option.value);
        });
      }
      _.values(spaceAttributes[attributeType].options).forEach(attribute => {
        if (!_.isEmpty(attribute.options)) {
          const allSubOptionsSelected = _.every(attribute.options, (option: SelectOptions) => newSpaceAttributeMap[option.value]);
          allSubOptionsSelected ? newSpaceAttributeMap[attribute.value] = attribute : newSpaceAttributeMap = _.omit(newSpaceAttributeMap, attribute.value);
        }
      });
      return {
        ...prev,
        [attributeType]: newSpaceAttributeMap
      };
    });
  }, [spaceAttributes]);

  const renderFilter = useCallback(() => (
    <div className={styles.filterContainer}>
      {_.keys(spaceAttributes).map(attributeType => (
        <FilterItemComponent
          key={attributeType}
          attributeType={attributeType}
          title={_.defaultTo(spaceAttributes[attributeType].title, '')}
          options={_.defaultTo(spaceAttributes[attributeType].options, [])}
          filterSpaceAttributeMap={_.defaultTo(filterMap[attributeType], {})}
          onFilterCallback={handleFilterCallback}
        />
      ))}
    </div>
  ), [spaceAttributes, filterMap, handleFilterCallback]);

  const renderMapContainer = useCallback(() => (
    <MapContainer
      model={model}
      selectedDevices={selectedDevices}
      handleRemoveDevice={handleRemoveDevice}
    />
  ), [model, selectedDevices, handleRemoveDevice]);

  useEffect(() => {
    if (!showMap) return;
    callAPIs([fetchMaps]);
  }, [showMap, fetchMaps, callAPIs]);

  const containerClass = cx('container', {
    isCustom: showMap
  });

  return (
    <DeviceDataContext.Provider
      value={{
        filter: {
          device: filteredDevices
        },
        deviceMap,
        spaceDetailMap,
        spaceChannel
      }}
    >
      {showMap && loading && <LoadingIndicator />}
      <div className={containerClass}>
        <Button
          className={styles.switchButton}
          variant='secondary'
          size='sm'
          onClick={triggerMapVisibility}
        >
          {showMap
            ? i18n.t<string>('customOutdoorDevice.buttons.switchToList')
            : i18n.t<string>('customOutdoorDevice.buttons.switchToMap')}
        </Button>
        {!showMap && (
          <div className={styles.componentArea}>
            <SelectComponent model={model} />
          </div>
        )}
        {showMap && (
          <div className={styles.expandComponentArea}>
            {renderFilter()}
            {renderMapContainer()}
          </div>
        )}
      </div>
    </DeviceDataContext.Provider>
  );
};
