import React, { Fragment } from 'react';
import styles from './creativeReviews.module.scss';
import { ADX, CreativeApprovalStatus, CreativeType, isHamiVideo, isNative, isVideo } from 'core/creative/Creative';
import i18n from 'i18next';
import { CreativeReviewItem } from './CreativeReviewItem';
import _ from 'lodash';

type ApprovalData = {
  creativeId: number;
  reviewData: CreativeApprovalStatus;
  isNativeToBanner: boolean;
};

type CreativeReviewData = {
  creativeId: number;
  creativeType: CreativeType;
  tenmaxCategory: string;
  origTenmaxCategory: string | null;
  approvalStatus: CreativeApprovalStatus;
  bannerExtra?: { [key: string]: string | number | boolean | object },
  nativeBanner?: {
    [key: string]: {
      creativeId: number;
      creativeReview: CreativeApprovalStatus;
    };
  };
  videoChildren?: {
    [key: string]: {
      creativeId: number;
      approvalStatus: CreativeApprovalStatus;
    };
  };
};

export class CreativeReviews extends React.PureComponent<{
  creativeData: CreativeReviewData;
  reviewCreative: (adx: string, id: number) => void;
}> {
  renderTitleRow = (sortedAdx: string[], showCreativeType: boolean) => {
    const creativeType = this.props.creativeData.creativeType;
    const isHamiVideoCreative = isHamiVideo(creativeType);

    return (
      <>
        {showCreativeType && <span />}
        {sortedAdx.map(adx => {
          const adxName =
            isHamiVideoCreative && adx === 'asiamax' ? 'Hami Video' : ADX[adx];
          return <span key={adx}>{adxName}</span>;
        })}
      </>
    );
  }

  checkReviewRequiredData (creative: CreativeReviewData): boolean {
    const reviewRequiredDataMap = {
      [CreativeType.PIC_SHORT]: [
        'bannerExtra.orderName',
        'bannerExtra.orderBudget',
        'bannerExtra.startDate',
        'bannerExtra.endDate',
        'bannerExtra.selfCheckList'
      ],
      [CreativeType.PIC_LONG]: [
        'bannerExtra.orderName',
        'bannerExtra.orderBudget',
        'bannerExtra.startDate',
        'bannerExtra.endDate',
        'bannerExtra.selfCheckList'
      ],
      [CreativeType.PIC_EXTENDED]: [
        'bannerExtra.orderName',
        'bannerExtra.orderBudget',
        'bannerExtra.startDate',
        'bannerExtra.endDate',
        'bannerExtra.selfCheckList'
      ]
    };
    if (!(creative.creativeType in reviewRequiredDataMap)) {
      return true;
    }
    return reviewRequiredDataMap[creative.creativeType].every(key => {
      return !_.isNil(_.get(creative, key));
    });
  }

  renderApprovalData = (
    key: string,
    data: ApprovalData,
    sortedAdx: string[],
    showCreativeType: boolean
  ) => {
    const creativeData = this.props.creativeData;
    const creativeId = data.creativeId;
    const reviewData = data.reviewData;
    const hasAllReviewRequiredData = this.checkReviewRequiredData(creativeData);
    return (
      <Fragment key={key}>
        {showCreativeType && <span className={styles.title}>{key}</span>}
        {sortedAdx.map(adx => {
          const reviewCreative = id => {
            this.props.reviewCreative(adx, id);
          };
          const state = _.get(reviewData[adx], 'state');
          if (_.isNil(state)) {
            return (
              <div key={adx} style={{ textAlign: 'center' }}>
                {i18n.t<string>('common.labels.noData')}
              </div>
            );
          }
          return (
            <CreativeReviewItem
              key={adx}
              adx={adx}
              tenmaxCategory={creativeData.tenmaxCategory}
              origTenmaxCategory={creativeData.origTenmaxCategory}
              approvalData={reviewData[adx]}
              creativeId={creativeId}
              hasAllReviewRequiredData={hasAllReviewRequiredData}
              reviewCreative={
                data.isNativeToBanner && adx === 'asiamax'
                  ? undefined
                  : reviewCreative
              }
            />
          );
        })}
      </Fragment>
    );
  }

  setupNativAdditionalApprovalData (approvalData: {
    [key: string]: ApprovalData;
  }) {
    const creativeData = this.props.creativeData;
    if (!isNative(creativeData.creativeType)) {
      return;
    }
    const natvieBannerData = creativeData.nativeBanner;
    if (!natvieBannerData) {
      return;
    }
    Object.keys(natvieBannerData).forEach(key => {
      approvalData[key] = {
        creativeId: natvieBannerData[key].creativeId,
        reviewData: natvieBannerData[key].creativeReview,
        isNativeToBanner: true
      };
    });
  }

  setupVideoAdditionlApprovalData (approvalData: {
    [key: string]: ApprovalData;
  }) {
    const creativeData = this.props.creativeData;
    if (!isVideo(creativeData.creativeType)) {
      return;
    }

    const videoChildren = creativeData.videoChildren;
    if (!videoChildren) {
      return;
    }

    // Hide parent video approval status
    delete approvalData[
      i18n.t<string>(
        `creativeList.labels.approvalDefault${creativeData.creativeType}`
      )
    ];

    Object.keys(videoChildren)
      .sort()
      .forEach(key => {
        approvalData[key] = {
          creativeId: videoChildren[key].creativeId,
          reviewData: videoChildren[key].approvalStatus,
          isNativeToBanner: false
        };
      });
  }

  render () {
    const creativeData = this.props.creativeData;
    const nativeStatus = creativeData.approvalStatus;
    const hasChildren =
      !!creativeData.nativeBanner || !!creativeData.videoChildren;
    if (
      !hasChildren &&
      (!nativeStatus || Object.keys(nativeStatus).length === 0)
    ) {
      return <div />;
    }

    let approvalData = {
      [i18n.t<string>(
        `creativeList.labels.approvalDefault${creativeData.creativeType}`
      )]: {
        creativeId: creativeData.creativeId,
        reviewData: nativeStatus,
        isNativeToBanner: false
      }
    };

    this.setupNativAdditionalApprovalData(approvalData);
    this.setupVideoAdditionlApprovalData(approvalData);

    const adxs: string[] = [];
    Object.values(approvalData).forEach(data => {
      Object.keys(data.reviewData).forEach(key => {
        if (!adxs.includes(key)) {
          adxs.push(key);
        }
      });
    });

    const adxValueMap = {
      asiamax: 0,
      doubleclick: 1
    };
    const sortedAdx = adxs.sort(
      (key1, key2) => adxValueMap[key1] - adxValueMap[key2]
    );
    const typeClass = CreativeType[creativeData.creativeType]
      ? styles[CreativeType[creativeData.creativeType].toLowerCase()]
      : '';

    const showCreativeType = hasChildren;
    return (
      <div
        className={`${styles.approvalData} ${typeClass} ${
          styles[`count${sortedAdx.length + (showCreativeType ? 1 : 0)}`]
        }`}
      >
        {this.renderTitleRow(sortedAdx, showCreativeType)}
        {Object.keys(approvalData).map(key => {
          return this.renderApprovalData(
            key,
            approvalData[key],
            sortedAdx,
            showCreativeType
          );
        })}
      </div>
    );
  }
}
