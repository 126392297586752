import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChannelGroupTabs } from '../ChannelGroupForm/ChannelGroupFormModel';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import i18n from 'i18n';
import { ChannelGroupDTO } from 'core/channelGroup/ChannelGroup';
import { useCallAPI } from 'hooks/useCallAPI';
import {
  ChannelGroupManager,
  DefaultChannelGroupManager
} from 'core/channelGroup/ChannelGroupManager';
import { useParams } from 'react-router-dom';
import { AgencyManager, DefaultAgencyManager } from 'core';

export type ChannelGroupDetailModelData = {
  loading: boolean;
  tab: ChannelGroupTabs;
  channelGroup?: ChannelGroupDTO;
  agencyOptions: SelectOptions[];
  breadcrumbsRoutes: BreadcrumbsRoute[];
  onTabChange: (tab: string | null) => void;
};

const channelGroupManager: ChannelGroupManager =
  new DefaultChannelGroupManager();
const agencyManager: AgencyManager = new DefaultAgencyManager();

export const useChannelGroupDetailModel = (): ChannelGroupDetailModelData => {
  const params = useParams<{ id: string }>();
  const id = +params.id;
  const [tab, setTab] = useState(ChannelGroupTabs.BASIC);
  const [channelGroup, setChannelGroup] = useState<ChannelGroupDTO>();
  const [agencyOptions, setAgencyOptions] = useState<SelectOptions[]>([]);

  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    callAPIs(
      [
        () => channelGroupManager.getChannelGroup(id),
        () => agencyManager.getAgenciesOptions()
      ],
      (channelGroup: ChannelGroupDTO, agencyOptions: SelectOptions[]) => {
        setChannelGroup(channelGroup);
        setAgencyOptions(agencyOptions);
      }
    );
  }, [id, callAPIs]);

  const onTabChange = useCallback((tab: string | null) => {
    if (!tab) {
      return;
    }
    setTab(tab as ChannelGroupTabs);
  }, []);

  const breadcrumbsRoutes = useMemo(
    () => [
      {
        path: '/system/channel-groups',
        breadcrumb: i18n.t<string>('channelGroups.title')
      },
      {
        path: '/system/channel-groups/:id',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: channelGroup ? channelGroup.name : '',
          matchParam: 'id'
        }
      }
    ],
    [channelGroup]
  );

  return {
    loading,
    tab,
    channelGroup,
    agencyOptions,
    breadcrumbsRoutes,
    onTabChange
  };
};
