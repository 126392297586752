import React, { ReactElement } from 'react';
import i18n from 'i18n';

import { Modal } from 'components/common/Modal/Modal';

export interface DismissFunction {
  (): void;
}

export interface AlertDialogProps {

  readonly title: string;
  readonly message: string | ReactElement | ReactElement[];
  readonly buttonLabel: string;
  readonly dismissable: boolean;

  readonly dismiss: DismissFunction;
}

export class AlertDialog extends React.Component<AlertDialogProps> {
  static defaultProps = {
    dismissable: true,
    title: 'common.titles.error',
    buttonLabel: 'common.buttons.ok'
  };

  hide = () => {
    if (this.props.dismissable) {
      this.props.dismiss();
    }
  }

  close = (e: any) => {
    this.props.dismiss();
  }

  render () {
    return (
      <Modal
        title={i18n.t<string>(this.props.title)}
        dismiss={this.hide}
        enableCloseBtn
        secondaryButton={{
          title: i18n.t<string>(this.props.buttonLabel),
          callback: this.close
        }}
      >
        {this.props.message}
      </Modal>
    );
  }
}

export function alertError (error: Error, dismiss: DismissFunction, dismissable: boolean = true) {
  return (
    <AlertDialog message={error.message} dismiss={dismiss} dismissable={dismissable} />
  );
}

export function alertMessage (title: string, message: string, dismiss: DismissFunction, dismissable: boolean = true) {
  return (
    <AlertDialog title={title} message={message} dismiss={dismiss} dismissable={dismissable} />
  );
}
