import { ActorPermissionFormModel, addChannelGroupPermissionForm } from 'components/ActorPermissionForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import { useCallAPI } from 'hooks/useCallAPI';
import { ChannelGroupManager, DefaultChannelGroupManager } from 'core/channelGroup/ChannelGroupManager';
import { Account, CompanyMember } from 'core';
import { getDefaultColumnDescription, getNameIdColumn } from 'components/TableColumn/TableColumn';
import { get } from 'lodash';

const defaultChannelGroupManager: ChannelGroupManager = new DefaultChannelGroupManager();

export enum ChannelGroupMemberListColumn {
  ID = 'id',
  EMAIL = 'email',
  STATUS = 'status',
  PERMISSION = 'permission'
}

export type ChannelGroupMember = {
  id: number;
  name: string;
  email: string;
  status: string;
  permission: string;
  account: Account;
  roleName: string;
};

export type ModalData = {
  title: string;
  fullScreen: boolean;
  message: string;
  confirmBtnData: {
    title: string;
    callback: () => void;
  };
};

export const useChannelGroupMembersModel = (channelGroupId: number) => {

  const [permissionFormModel, setPermissionFormModel] = useState<ActorPermissionFormModel>();
  const { loading, callAPIs } = useCallAPI();
  const [members, setMembers] = useState<ChannelGroupMember[]>([]);
  const [modelLoading, setModelLoading] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>();

  const fetchMembers = useCallback(() => {
    callAPIs(
      [() => defaultChannelGroupManager.getChannelGroupMembers(channelGroupId)],
      (members: CompanyMember[]) => {
        setMembers(
          members.map(member => ({
            id: member.id,
            name: member.account.name,
            email: member.account.email,
            status: member.account.activated
              ? i18n.t<string>('channelGroupMembers.active')
              : i18n.t<string>('channelGroupMembers.inactive'),
            permission: i18n.t<string>(
              `channelGroupMembers.permissions.${member.roleName}`
            ),
            account: member.account,
            roleName: member.roleName
          }))
        );
      }
    );
  }, [channelGroupId, callAPIs]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const callback = useCallback((cancelled: boolean, error: Error | null = null, showLoading: boolean = false) => {
    if (showLoading) {
      setPermissionFormModel(undefined);
      setModelLoading(true);
      return;
    }
    setPermissionFormModel(undefined);
    if (!cancelled && !error) {
      toast.success(i18n.t<string>('common.messages.succeeded'));
      fetchMembers();
    }
    setModelLoading(false);
  }, [fetchMembers]);

  const onAddRoleBtnClick = useCallback(() => {
    setPermissionFormModel(addChannelGroupPermissionForm(channelGroupId, callback));
  }, [channelGroupId, callback]);

  const deleteRole = useCallback(
    (member: ChannelGroupMember) => {
      callAPIs(
        [
          () =>
            defaultChannelGroupManager.deleteChannelGroupMember(
              channelGroupId,
              member.email,
              member.roleName
            )
        ],
        () => {
          toast.success(i18n.t<string>('common.messages.succeeded'));
          fetchMembers();
        }
      );
      setModalData(undefined);
    },
    [channelGroupId, callAPIs, fetchMembers]
  );

  const onDeleteBtnClick = useCallback(
    (member: ChannelGroupMember) => {
      setModalData({
        title: i18n.t<string>('accountActorList.labels.actionHint.delete'),
        fullScreen: false,
        message: i18n.t<string>('accountActorList.labels.deletePermissionContent'),
        confirmBtnData: {
          callback: () => deleteRole(member),
          title: i18n.t<string>('common.buttons.delete')
        }
      });
    },
    [deleteRole]
  );

  const columns = useMemo(() => [
    getNameIdColumn(
      getDefaultColumnDescription(
        ChannelGroupMemberListColumn.ID,
        i18n.t<string>('channelGroupMembers.headers.name')
      ),
      member => `/accounts/${get(member, 'account.id')}`
    ),
    getDefaultColumnDescription(
      ChannelGroupMemberListColumn.EMAIL,
      i18n.t<string>('channelGroupMembers.headers.email')
    ),
    getDefaultColumnDescription(
      ChannelGroupMemberListColumn.STATUS,
      i18n.t<string>('channelGroupMembers.headers.status')
    ),
    getDefaultColumnDescription(
      ChannelGroupMemberListColumn.PERMISSION,
      i18n.t<string>('channelGroupMembers.headers.permission')
    )
  ], []);

  const hideModal = useCallback(() => {
    setModalData(undefined);
  }
  , []);

  return {
    loading: loading || modelLoading,
    members,
    columns,
    modalData,
    permissionFormModel,
    hideModal,
    onAddRoleBtnClick,
    onDeleteBtnClick
  };
};
