import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import i18n from 'i18n';
import { useCallback } from 'react';
import { useBeaconConnectionListModel } from './BeaconConnectionListModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { BeaconConnectionForm } from './BeaconConnectionForm';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { BeaconConnection } from 'core/advertiser/BeaconConnection';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export const BeaconConnectionList: React.FC<{
  advertiserId: number;
}> = ({
  advertiserId
}) => {

  const {
    loading,
    columns,
    filteredBeaconConnections,
    beaconConnectionFormProps,
    setSearchString,
    showCreateBeaconConnectionFormModal,
    showEditBeaconConnectionFormModal
  } = useBeaconConnectionListModel(advertiserId);

  const renderListOperations = useCallback(() => {
    return [
      <button
        key={'new-beacon-connection'}
        className={'btn btn-tertiary btn-sm'}
        onClick={showCreateBeaconConnectionFormModal}
      >
        {i18n.t<string>(
          'beaconConnectionList.labels.newBeaconConnection'
        )}
      </button>
    ];
  }, [showCreateBeaconConnectionFormModal]);

  const renderRowBtns = useCallback((beaconConnection: BeaconConnection) => {
    const onClick = () => showEditBeaconConnectionFormModal(beaconConnection);
    return [
      <IconWithTooltip
        key={'edit-beacon-connection'}
        icon={faPencilAlt}
        tooltipProps={{
          id: `beacon-connection-edit-tip-${beaconConnection.id}`,
          tooltip: i18n.t<string>('beaconConnectionList.hints.edit')
        }}
        onClick={onClick}
      />
    ];
  }, [showEditBeaconConnectionFormModal]);

  return (
    <>
      {loading && <LoadingIndicator />}
      <StickableBootstrapTable
        customPadding={false}
        keyField={'id'}
        data={filteredBeaconConnections}
        columns={columns}
        noDataIndication={i18n.t<string>(
          'beaconConnectionList.placeholders.noData'
        )}
        defaultSorted={[
          {
            dataField: 'id',
            order: 'desc'
          }
        ]}
        renderListOperations={renderListOperations}
        renderRowBtns={renderRowBtns}
        onHandleSearch={setSearchString}
        searchbarPlaceholder={i18n.t<string>(
          'beaconConnectionList.placeholders.searchbar'
        )}
      />
      {beaconConnectionFormProps && (
        <BeaconConnectionForm
          {...beaconConnectionFormProps}
        />
      )}
    </>
  );
};
