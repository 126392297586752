import * as ValidateUtils from 'utils/ValidateUtils';
import { Account, RoleNames, RoleManager, DefaultRoleManager, Actor } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { ChannelGroupManager, DefaultChannelGroupManager } from 'core/channelGroup/ChannelGroupManager';

export type PermissionFormState = {

  readonly validationError?: string;
  readonly email: string | null;
};

export interface FormCallback {
  (cancelled: boolean, error: Error | null, showLoading: boolean): void;
}

export interface ActorPermissionFormModel {

  readonly email: string;
  readonly selected: string;
  readonly options: Array<string>;
  readonly emailEditable: boolean;
  readonly validationError?: string;
  readonly event: UpdateEventListener<ActorPermissionFormModel>;
  readonly state: PermissionFormState;

  cancel (): void;
  submit (): Promise<void>;
  select (roleName: string): void;
  setEmail (email: string): void;
}

export type ActorPermissionFormProps = {
  readonly model: ActorPermissionFormModel;
};

abstract class AbstractPermissionForm implements ActorPermissionFormModel {
  emailEditable: boolean;
  selected: string;
  event: FireableUpdateEventListener<ActorPermissionFormModel>;
  validationError?: string;
  email: string;

  constructor (
    protected roleName: string,
    public options: string[],
    protected callback: FormCallback
  ) {
    this.callback = callback;
    this.options = options;
    this.selected = roleName;
    this.event = new FireableUpdateEventListener<ActorPermissionFormModel>();
    this.email = '';
    this.emailEditable = false;
  }

  cancel () {
    this.callback(true, null, false);
  }

  select (roleName: string) {
    if (this.options.includes(roleName)) {
      this.selected = roleName;
      this.event.fireEvent(this);
    }
  }

  setEmail (email: string) {
    this.email = email;
    this.event.fireEvent(this);
  }

  abstract submit ();

  get state (): PermissionFormState {
    return {
      validationError: this.validationError,
      email: this.email
    };
  }
}

abstract class CompanyMemberPermissionForm extends AbstractPermissionForm {
  constructor (
    protected scope: string,
    protected scopeId: number,
    roleName: string,
    options: Array<string>,
    callback: FormCallback,
    protected manager: RoleManager = new DefaultRoleManager()
  ) {
    super(roleName, options, callback);
  }

  abstract submit ();
}

export class AddPermissionFormModel extends CompanyMemberPermissionForm {

  emailEditable = true;

  async submit () {
    this.validationError = ValidateUtils.validateEmail(this.email);
    if (this.validationError) {
      this.event.fireEvent(this);
    } else {
      try {
        this.callback(false, null, true);
        await this.manager.addRole(
          this.scope,
          this.scopeId,
          this.email,
          this.selected
        );
        this.callback(false, null, false);
      } catch (error) {
        this.callback(false, new Error('failed to add role'), false);
      }
    }
  }
}

export class EditPermissionFormModel extends CompanyMemberPermissionForm {
  account: Account;
  emailEditable = false;

  constructor (scope: string, scopeId: number, account: Account, roleName: string, options: Array<string>, callback: FormCallback) {
    super(scope, scopeId, roleName, options, callback);
    this.account = account;
    this.email = account.email;
  }

  async submit () {
    try {
      this.callback(false, null, true);
      await this.manager.changeRole(this.scope, this.scopeId, this.account.email, this.selected);
      this.callback(false, null, false);
    } catch (error) {
      this.callback(false, error as Error, false);
    }
  }
}

abstract class ChannelGroupMemberPermissionForm extends AbstractPermissionForm {
  constructor (
    protected channelGroupId: number,
    roleName: string,
    options: string[],
    callback: FormCallback,
    protected manager: ChannelGroupManager = new DefaultChannelGroupManager()
  ) {
    super(roleName, options, callback);
  }

  abstract submit ();
}

export class AddChannelGroupPermissionFormModel extends ChannelGroupMemberPermissionForm {

  emailEditable = true;

  async submit () {
    this.validationError = ValidateUtils.validateEmail(this.email);
    if (this.validationError) {
      this.event.fireEvent(this);
    } else {
      try {
        this.callback(false, null, true);
        await this.manager.addChannelGroupMember(
          this.channelGroupId,
          this.email,
          this.selected
        );
        this.callback(false, null, false);
      } catch (error) {
        this.callback(false, new Error('failed to add role'), false);
      }
    }
  }
}

export function addAgencyPermissionForm (agencyId: number, operator: Actor | null, callback: FormCallback): ActorPermissionFormModel {
  const { agencyAdmin, agencyManager, agencySales, agencyReport, fbAgencyManager } = RoleNames;
  const options = [agencyAdmin, agencyManager, agencySales, agencyReport];
  const permittedOptions = (operator && operator.roleName === RoleNames.sysAdmin) ? [...options, fbAgencyManager] : options;
  return new AddPermissionFormModel('agencies', agencyId, agencyAdmin, permittedOptions, callback);
}

export function editAgencyPermissionForm (agencyId: number, operator: Actor | null, account: Account, roleName: string, callback: FormCallback): ActorPermissionFormModel {
  const { agencyAdmin, agencyManager, agencySales, agencyReport, fbAgencyManager } = RoleNames;
  const options = [agencyAdmin, agencyManager, agencySales, agencyReport, fbAgencyManager];
  return new EditPermissionFormModel('agencies', agencyId, account, roleName, options, callback);
}

export function addAdvertiserPermissionForm (advertiserId: number, callback: FormCallback): ActorPermissionFormModel {
  const { adsAdmin, adsSales, adsReport } = RoleNames;
  const options = [adsAdmin, adsSales, adsReport];
  return new AddPermissionFormModel('advertisers', advertiserId, adsAdmin, options, callback);
}

export function editAdvertiserPermissionForm (advertiserId: number, account: Account, roleName: string, callback: FormCallback): ActorPermissionFormModel {
  const { adsAdmin, adsSales, adsReport } = RoleNames;
  const options = [adsAdmin, adsSales, adsReport];
  return new EditPermissionFormModel('advertisers', advertiserId, account, roleName, options, callback);
}

export function addChannelGroupPermissionForm (channelGroupId: number, callback: FormCallback): ActorPermissionFormModel {
  const { reconciliationOfficer } = RoleNames;
  const options = [reconciliationOfficer];
  return new AddChannelGroupPermissionFormModel(channelGroupId, reconciliationOfficer, options, callback);
}
