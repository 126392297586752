import React from 'react';
import { AdvertiserDetailState, AdvertiserDetailProps, ListType } from './AdvertiserDetailModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

import styles from './advertiserDetail.module.scss';
import i18n from 'i18n';
import { AdvertiserMemberList } from 'components/AdvertiserMemberList/AdvertiserMemberList';
import { Link } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { Nav } from 'react-bootstrap';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import _ from 'lodash';
import { BeaconConnectionList } from 'containers/BeaconConnection/BeaconConnectionList';

export class AdvertiserDetail extends React.Component<AdvertiserDetailProps, AdvertiserDetailState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.onUnmount(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderSection = (sectionData) => {
    return Object.keys(sectionData).map(key => {
      const i18nKey = `advertiserDetail.labels.${key}`;
      const title = i18n.exists(i18nKey) ? i18n.t<string>(`advertiserDetail.labels.${key}`) : key;
      let value = sectionData[key];
      if (value === undefined) {
        return <div key={key}/>;
      }
      return (
        <div className={styles.field} key={key}>
          <div className={styles.fieldLabel}>
            {title}
          </div>
          <div className={styles.fieldValue}>
            {value}
          </div>
        </div>
      );
    });
  }

  renderAdvertiserData = (advertiserViewData) => {
    if (!advertiserViewData) {
      return <></>;
    }
    return Object.keys(advertiserViewData).map(key => (
      <section key={key}>
        {this.renderSection(advertiserViewData[key])}
      </section>
    ));
  }

  render () {
    const model = this.props.model;
    const advertiser = model.advertiser;
    if (!advertiser) {
      return <LoadingIndicator />;
    }

    return (
      <div className={styles.advertiserDetailContainer}>
        {model.state.loading && <LoadingIndicator />}
        <div className={styles.title}>
          <TitleWithBreadcrumbs
            title={i18n.t<string>('advertiserDetail.labels.title')}
            routes={[
              { path: '/advertisers', breadcrumb: i18n.t<string>('appMenus.comapnyManagement.items.advertisers') },
              { path: '/advertisers/:advertiserId', breadcrumb: DynamicBreadcrumb, props: { label: _.get(model.advertiser, 'advertiserName'), matchParam: 'advertiserId' } }
            ]}
          />
        </div>
        <div className={styles.titleBottomLine} />
        <div className={styles.advertiserDetail}>
          <div className={styles.topArea}>
            <div className={styles.advertiserName}>
              {i18n.t<string>('advertiserDetail.labels.name', { name: advertiser.advertiserName })}
            </div>
            <div className={styles.buttonArea}>
              <PermissionChecker permissionAware={hasFuncs(Permission.ADVERTISER_WRITE)}>
                <Link to={`${advertiser.id}/edit`}>{i18n.t<string>('advertiserDetail.labels.editAdvertiser')}</Link>
              </PermissionChecker>
            </div>
          </div>
          <div className={styles.detail}>
            {this.renderAdvertiserData(model.state.advertiserViewData)}
          </div>
        </div>
        <div className={styles.other}>
          <Nav
            activeKey={this.props.model.state.listType}
            onSelect={this.props.model.switchList}
          >
            <Nav.Item>
              <Nav.Link eventKey={ListType.MEMBER}>{i18n.t<string>('advertiserDetail.tabs.advertiserMemberList')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={ListType.BEACON}>{i18n.t<string>('advertiserDetail.tabs.beaconConnectionList')}</Nav.Link>
            </Nav.Item>
          </Nav>
          {this.props.model.state.listType === ListType.MEMBER && <AdvertiserMemberList model={model.getAdvertiserMemberListModel()}/>}
          {this.props.model.state.listType === ListType.BEACON && <BeaconConnectionList advertiserId={advertiser.id}/>}
        </div>
      </div>
    );
  }
}
